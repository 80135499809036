const permissions = {
  PartnerDashboard: {
    READ: 'PartnerDashboard.DB.Read',
    '1P': 'PartnerDashboard.InsightsManager1P.Read',
    '3PPRO': 'PartnerDashboard.InsightsManager3PPRO.Read',
    DTaaS: 'PartnerDashboard.InsightsManagerDTAAS.Read',
    GLOBAL: 'PartnerDashboard.InsightsGlobalManagerViewer.Read',
    SALESMANAGER: 'PartnerDashboard.SalesManagerViewer.Read',
  },
};
export default permissions;
