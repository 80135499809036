import { styled } from '@hexa-ui/theme';

export const VendorManagementHeaderContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '$6 0',
  width: '100%',
  margin: '0 auto',
});

export const VendorManagementContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  minWidth: '100%',
});
