import APIEndpoint from 'Api/APIEndpoint';
import Api from 'Api/Api';
import getAPIBaseURL from 'Api/getAPIBaseURL';
import { ReportProps } from 'Pages/ReportsDetailsPage/components/ReportRules/Table/ReportRulesTable.types';
import { GetReportPermissionsServiceParams } from './getReportPermissionsService.types';

export const getReportPermissionsService = async ({
  id,
  isManufacturer,
  country,
  cluster,
}: GetReportPermissionsServiceParams): Promise<ReportProps> => {
  const baseURLForReports = getAPIBaseURL(APIEndpoint.ADD_REPORT_GROUP, country, cluster);

  const baseURLForVendors = getAPIBaseURL(APIEndpoint.GET_VENDORS, country, cluster);

  try {
    const allVendorsResponse = await Api.get({
      url: '/activation/vendors',
      baseURL: baseURLForVendors,

      params: { isManufacturer },
    });

    const allReportsResponse = await Api.get({
      url: `/activation/reports`,
      baseURL: baseURLForReports,

      params: { isManufacturer },
    });

    const allVendors = Array.isArray(allVendorsResponse.data) ? allVendorsResponse.data : [];

    const reportData: ReportProps =
      allReportsResponse.data.reports.find((report: ReportProps) => report.id === id) ||
      allReportsResponse.data.reports[0];

    const updatedPermissions = reportData.permissions?.map((permission) => ({
      ...permission,
      vendor: allVendors?.map((allVendor: { id: string; name: string }) => {
        const vendorInReport = permission.vendor?.find((reportVendor) => {
          return reportVendor.id === allVendor.id;
        });
        const isInReport = !!vendorInReport;
        return {
          id: allVendor.id,
          name: allVendor.name,
          userType: permission.userType,
          businessModel: permission.businessModel,
          enabled: isInReport,
        };
      }),
    }));

    const finalData = {
      ...reportData,
      permissions: updatedPermissions,
      vendor: allVendors,
    };

    return finalData;
  } catch (error) {
    console.error('Failed to fetch report permissions', error);
    throw new Error('Failed to fetch report permissions');
  }
};
