import { Error500, Heading, LoadingDots, Table } from '@hexa-ui/components';
import { TransformedReport } from 'Services/GetReportsByVendors/getReportsByVendorsService.types';
import React from 'react';
import { useIntl } from 'react-intl';
import { TableContainer, TextWrapper, TitleContainer } from './CustomTable.style';

type ReportsColumns = {
  Header: string;
  accessor: string;
  disableSortBy: boolean;
  style: { width: string };
  onRow?: any;
};

function CustomTable({
  titleId,
  columns,
  data,
  noDataMessageId,
  tableType,
  onRow,
  loading,
}: {
  titleId: string;
  columns: ReportsColumns[];
  data: TransformedReport[];
  noDataMessageId: string;
  tableType: 'partnerAndManager' | 'salesManager';
  onRow?: any;
  loading?: boolean;
}): JSX.Element {
  const { formatMessage } = useIntl();
  const loadingDots = () => loading && <LoadingDots />;
  return (
    <TableContainer data-testid={`table-${tableType}`}>
      <TitleContainer>
        <TextWrapper>
          <Heading size="H4">{formatMessage({ id: titleId })}</Heading>
        </TextWrapper>
      </TitleContainer>
      <Table
        columns={columns}
        data={data}
        onRow={onRow}
        emptyMessage={
          <div style={{ width: '420px' }}>
            <Error500 headerText={formatMessage({ id: noDataMessageId })} />
          </div>
        }
        search
        toolbarExtra={loadingDots}
      />
    </TableContainer>
  );
}

export default CustomTable;
