import { Paragraph } from '@hexa-ui/components';
import AppModal from 'Components/AppModal/AppModal';
import { useClusterContext } from 'Context/ClusterContext/ClusterContext';
import { useCountry } from 'Hooks/useCountry/useCountry';
import { deleteVendorsActivationService } from 'Services/DeleteVendorsActivation/DeleteVendorsActivationService';
import { dispatchCustomEvent } from 'Utils/customEvents/customEventWatchDispatch';
import { customEventsCommonConsts } from 'Utils/customEvents/customEventsCommonConsts';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import React from 'react';
import { useIntl } from 'react-intl';
import { ContainerItemNewVendor } from './DeleteVendorModal.style';
import { DeleteVendorModalProps } from './DeleteVendorModal.types';

export default function DeleteVendorModal({
  isOpen,
  onClose,
  isLoading,
  selectedVendorId,
}: DeleteVendorModalProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const { cluster } = useClusterContext();
  const country = useCountry();

  const addTitle = formatMessage({ id: 'VENDOR_REGISTRATION_PAGE.MODAL.TITLE_DELETE_VENDOR' });
  const confirmButton = formatMessage({ id: 'VENDOR_REGISTRATION_PAGE.MODAL.CONFIRM_BUTTON' });
  const cancelButton = formatMessage({ id: 'VENDOR_REGISTRATION_PAGE.MODAL.CANCEL_BUTTON' });

  const toastService = useToast();

  const onCloseModal = () => {
    onClose();
  };

  /* istanbul ignore next */
  const handleErrors = () => {
    return toastService.notify({
      type: TypeToast.ERROR,
      message: formatMessage({ id: 'TOAST.ERROR' }),
    });
  };

  const handleDeleteNewVendor = async () => {
    try {
      await deleteVendorsActivationService({ country, cluster, selectedVendorId });
      toastService.notify({
        type: TypeToast.SUCCESS,
        message: formatMessage({ id: 'TOAST.SUCCESS' }),
      });
      dispatchCustomEvent(customEventsCommonConsts.REFETCH_GET_ALL_VENDORS);
      onClose();
    } catch (error: any) {
      handleErrors();
    }
  };

  const onSubmit = () => {
    handleDeleteNewVendor();
  };

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onCloseModal}
      buttonConfirmLabel={confirmButton}
      buttonCancelLabel={cancelButton}
      isLoading={isLoading}
      css={{ '@tablet': { minWidth: '500px' } }}
      dataTestId="delete-vendor-modal"
      title={addTitle}
      onCancelButton={onCloseModal}
      onConfirmButton={() => {
        onSubmit();
      }}
    >
      <ContainerItemNewVendor>
        <Paragraph>
          {formatMessage({ id: 'VENDOR_REGISTRATION_PAGE.MODAL.CONFIRMATION_TEXT' })}
        </Paragraph>
      </ContainerItemNewVendor>
    </AppModal>
  );
}
