import { Breadcrumb } from '@hexa-ui/components';
import MFERoutes from 'Config/MFERoutes';
import { useClusterContext } from 'Context/ClusterContext/ClusterContext';
import { useCountry } from 'Hooks/useCountry/useCountry';
import { useReportGroupById as ReportGroupById } from 'Services/GetReportGroupById/useReportGroupById';
import { useReportsById } from 'Services/GetReportsById/useReportsById';
// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';

export function BreadCrumb({
  breadcrumbInitialItem,
  isReportsAdminPage = true,
  isWorkSpaces,
}: {
  breadcrumbInitialItem?: string;
  isReportsAdminPage?: boolean;
  isWorkSpaces?: boolean;
}): React.JSX.Element {
  const urlParams = useParams();
  const location = useLocation();
  const params = useMemo(() => Object.values(urlParams), [urlParams]);
  const { vendorName, isManufacturer } = location.state || {};
  const country = useCountry();
  const { cluster } = useClusterContext();
  const { formatMessage } = useIntl();

  const reportGroup = isReportsAdminPage
    ? ReportGroupById(params[0] as string, country, cluster)
    : null;
  const report = useReportsById(params[1] as string, country, cluster, isManufacturer);
  const reportGroupTitle = reportGroup?.data?.title['en-US'];
  const reportTitle = report?.data?.title['en-US'];
  const titles = [reportGroupTitle, reportTitle];

  const pageRoute = isReportsAdminPage
    ? MFERoutes.REPORTS_MANAGEMENT
    : MFERoutes.REPORTS_VENDOR_MANAGEMENT;

  const reportsBreadCrumb = !isReportsAdminPage ? (
    <Breadcrumb.Item isCurrentPage={!params[0]} href={pageRoute}>
      {breadcrumbInitialItem ?? formatMessage({ id: 'SIDEBAR.FEATURE_ACTIVATION' })}
    </Breadcrumb.Item>
  ) : (
    <Breadcrumb.Item isCurrentPage={!params[0]} href={pageRoute}>
      {breadcrumbInitialItem ?? formatMessage({ id: 'SIDEBAR.FEATURE_SETUP' })}
    </Breadcrumb.Item>
  );
  return (
    <Breadcrumb.Root>
      <Breadcrumb.HomeItem href={pageRoute} />
      {isWorkSpaces ? (
        <Breadcrumb.Item isCurrentPage={!params[0]} href={pageRoute}>
          {breadcrumbInitialItem ?? formatMessage({ id: 'SIDEBAR.WORKSPACES' })}
        </Breadcrumb.Item>
      ) : (
        reportsBreadCrumb
      )}
      {!isReportsAdminPage &&
        params.map((param, index) => {
          const path = params.slice(0, index + 1).join('/');

          return (
            <Breadcrumb.Item
              key={`${param}`}
              isCurrentPage={index === params.length - 1}
              href={`${pageRoute}/${path}`}
            >
              {vendorName}
            </Breadcrumb.Item>
          );
        })}
      {isReportsAdminPage &&
        titles.map((title, index) => {
          if (!title) {
            return null;
          }

          const path = params.slice(0, index + 1).join('/');
          const paramTitle = title;

          return (
            <Breadcrumb.Item
              key={`${title}`}
              isCurrentPage={index === params.length - 1}
              href={`${pageRoute}/details/${path}`}
            >
              {paramTitle}
            </Breadcrumb.Item>
          );
        })}
    </Breadcrumb.Root>
  );
}
