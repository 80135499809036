/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
import { Toggle } from '@hexa-ui/components';
import { useIsFetching } from '@tanstack/react-query';
import { useClusterContext } from 'Context/ClusterContext/ClusterContext';
import { useCountry } from 'Hooks/useCountry/useCountry';
import {
  Report,
  ToggleCellProps,
  ToggleStates,
} from 'Pages/VendorManagementDetailsPage/VendorManagementDetails.types';
import { updateVendorsReportById } from 'Services/UpdateVendorsReportById/UpdateVendorsReportById';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';

const initializeToggleStates = (reports: Report[]): ToggleStates =>
  reports.reduce((acc: ToggleStates, report: Report) => {
    acc[`${report.reportGroup}-partner`] = report.enabledInUserType.partner ?? false;
    acc[`${report.reportGroup}-manager`] = report.enabledInUserType.manager ?? false;
    acc[`${report.reportGroup}-sales_manager`] = report.enabledInUserType.sales_manager ?? false;
    return acc;
  }, {});

export const useToggleStates = (
  data: { reports: Report[] } | undefined,
  refetch?: () => void
): {
  toggleStates: ToggleStates;
  toggleCell: (props: ToggleCellProps) => JSX.Element;
  updatedData: any;
  enabled?: boolean;
  isFetching: boolean;
} => {
  const [toggleStates, setToggleStates] = useState<ToggleStates>({});
  const [updatedData, setUpdatedData] = useState<any>(data);

  const { cluster } = useClusterContext();

  const country = useCountry();

  const { vendorId: vendorIdFromURL } = useParams<{
    vendorId: string;
  }>();

  const location = useLocation();

  const { vendorName } = location.state;
  const toastService = useToast();
  const { formatMessage } = useIntl();

  const isFetching = !!useIsFetching();

  const [isDisabled, setIsDisabled] = React.useState<boolean>(false);

  useEffect(() => {
    if (data?.reports) {
      setToggleStates(initializeToggleStates(data.reports));
    }
  }, [data]);

  const toggleCell = ({ value, row, column }: ToggleCellProps) => {
    const enabledInUserType = column.id.split('.')[1];
    const toggleIdentifier = `${row.original.reportGroup}-${row.original.powerBIReportId}-${row.original.powerBIWorkspaceId}-${row.original.title}-${enabledInUserType}`;

    if (
      (row.original.enabledInUserType as unknown as { [key: string]: boolean })[
        enabledInUserType
      ] === undefined
    ) {
      return <Toggle disabled checked={false} />;
    }

    const toggleState = toggleStates[toggleIdentifier] ?? value;

    const updateActivation = (isChecked: boolean) => {
      const originalPermission = row.original.permission?.map(
        (permissionOriginal: {
          userType: string;
          vendor: string;
          businessModel: string;
          countries: string[];
          enabled: boolean;
          permissionId: string;
          tier: string | null;
        }) => ({
          userType: permissionOriginal.userType,
          vendors: permissionOriginal.vendor,
          businessModel: permissionOriginal.businessModel,
          countries: permissionOriginal.countries,
          enabled: permissionOriginal.enabled,
          permissionId: permissionOriginal.permissionId,
          tier: null,
        })
      );
      const permissionsArray = originalPermission
        .filter(
          (perm: { userType: string; vendor: any }) =>
            perm.userType === enabledInUserType.toUpperCase()
        )
        .map((perm: { userType: string; vendors: any; tier: any }) => {
          const insertVendor = () => {
            return [...perm?.vendors, { id: vendorIdFromURL, name: vendorName }];
          };

          const removeVendor = () =>
            perm?.vendors?.filter((v: { id: string }) => v.id !== vendorIdFromURL);

          return {
            ...perm,
            vendors: isChecked ? insertVendor() : removeVendor(),
            enabled: true,
          };
        });

      return {
        rule: permissionsArray[0],
      };
    };

    const onChange = async (isChecked: boolean) => {
      setIsDisabled(true);
      setToggleStates((prevState) => ({
        ...prevState,
        [toggleIdentifier]: isChecked,
      }));

      const pathPayload = updateActivation(isChecked);

      const { permissionId } = pathPayload.rule;

      setUpdatedData(pathPayload);

      updateVendorsReportById(pathPayload as any, permissionId, country, cluster)
        .then(() => {
          refetch?.();
          toastService.notify({
            type: TypeToast.SUCCESS,
            message: formatMessage({ id: 'VENDORS_UPDATE.SUCCESS' }),
          });
          setTimeout(() => {
            setIsDisabled(false);
          }, 1500);
        })
        .catch(() => {
          toastService.notify({
            type: TypeToast.ERROR,
            message: formatMessage({ id: 'VENDORS_UPDATE.FAIL' }),
          });
          setTimeout(() => {
            setIsDisabled(false);
          }, 1500);
        });
    };
    return <Toggle disabled={isDisabled} checked={toggleState} onCheckedChange={onChange} />;
  };

  return { toggleStates, toggleCell, updatedData, isFetching };
};
