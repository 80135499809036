import { EmbedReport } from 'Components/EmbedReport/EmbedReport';
import GenericErrorView from 'Components/GenericError/GenericErrorView';
import Loading from 'Components/Loading/Loading';
import PageContainer from 'Components/PageContainer/PageContainer';
import { generateReportsPath } from 'Config/MFERoutes';
import { ReportsPageContext } from 'Context/ReportsPageContext';
import { TrackingContext } from 'Context/TrackingContext';
import MenuItem from 'Domain/MenuItem';
import ReportTab from 'Domain/ReportTab';
import { isGlobalPartner, isMultiVendor } from 'Domain/User';
import useAppEvents from 'Hooks/segment/useAppEvents/useAppEvents';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import useReportsMFEConfig from 'Services/GetReportsMFEConfig/useReportsMFEConfig';
import { setSelectedVendor } from 'Store/LoggedUser/LoggedUserEvents';
import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, useParams } from 'react-router-dom';

export function ExternalReportPage(): React.ReactElement | null {
  const { triggerPageViewed } = useAppEvents();
  const { vendorId, selectedCountry } = useSelectedVendor();
  const { locale: lang } = useIntl();
  const {
    country: countryFromUrl,
    vendorId: vendorIdFromURL,
    externalReportSlug: slugFromURL,
  } = useParams<{
    vendorId?: string;
    country?: string;
    externalReportSlug: string;
  }>();

  const isMultiVendorOrGlobalPartner = isMultiVendor() || isGlobalPartner();

  const vendorIdRequest = isMultiVendorOrGlobalPartner ? vendorIdFromURL : vendorId;
  const countryRequest = isMultiVendorOrGlobalPartner ? countryFromUrl : selectedCountry;

  const reportsMFEConfig = useReportsMFEConfig({
    vendorId: vendorIdRequest,
    country: countryRequest,
  });

  const getExternalReportBySlug = (slug: string) =>
    reportsMFEConfig.data?.externalReports.find((report) => report.slug === slug);

  const pageViewedTriggered = useRef<boolean>(false);

  useEffect(() => {
    if (isMultiVendorOrGlobalPartner) {
      setSelectedVendor({
        id: vendorIdFromURL || '',
        country: countryFromUrl || '',
      });
    }
  }, [countryFromUrl, vendorIdFromURL]);

  useEffect(() => {
    if (reportsMFEConfig?.data && !pageViewedTriggered.current) {
      const externalReport = getExternalReportBySlug(slugFromURL);

      if (externalReport) {
        triggerPageViewed({
          URL: window.location.href,
          path: window.location.pathname,
          category: externalReport.trackingId,
        });

        pageViewedTriggered.current = true;
      }
    }
  }, [triggerPageViewed, reportsMFEConfig]);

  if (!vendorId) {
    return <Redirect to={generateReportsPath({ country: selectedCountry, vendorId })} />;
  }

  if (reportsMFEConfig.isError) {
    return (
      <PageContainer sidebar={false} maxWidth="xl">
        <GenericErrorView />
      </PageContainer>
    );
  }

  if (reportsMFEConfig.isLoading) {
    return (
      <PageContainer sidebar={false} maxWidth="xl">
        <Loading container />
      </PageContainer>
    );
  }

  const externalReport = getExternalReportBySlug(slugFromURL);

  if (!externalReport) {
    return <Redirect to={generateReportsPath({ country: selectedCountry, vendorId })} />;
  }

  return (
    <ReportsPageContext.Provider
      value={{
        menuItems: [],
        selectedMenuItem: {} as MenuItem,
        defaultTab: {} as ReportTab,
        selectedTab: { reportCode: externalReport.reportCode } as ReportTab,
      }}
    >
      <TrackingContext.Provider
        value={{
          reportType: 'ADDITIONAL_REPORTS',
          reportSubType: externalReport.trackingId,
          tabName: externalReport.reportCode,
        }}
      >
        <PageContainer sidebar={false} maxWidth="xl" dataTestId="external-report-page">
          <EmbedReport
            reportCode={externalReport.reportCode}
            vendorId={vendorIdRequest || vendorId}
            country={countryRequest}
            lang={lang}
            css={{ marginTop: '2rem' }}
          />
        </PageContainer>
      </TrackingContext.Provider>
    </ReportsPageContext.Provider>
  );
}
