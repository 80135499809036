import { styled } from '@hexa-ui/theme';

export const CardHeaderContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const CardHeaderWrapper = styled('div', {
  display: 'flex',
  gap: '$4',
  alignItems: 'center',

  '& p': {
    paddingTop: '$2',
  },
});
