import { styled } from '@hexa-ui/theme';

export const FormContainerItemAddNewTheme = styled('form', {
  display: 'grid',
  gridGap: '16px',
});

export const ItemsContainer = styled('div', {
  display: 'grid',
  gridGap: '14px',
  gridTemplateColumns: '1fr 1fr',
});
