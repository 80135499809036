import { styled } from '@hexa-ui/theme';

export const SelectIconContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

export const SelectIconDropdown = styled('div', {
  width: '100%',
});

export const SelectIconLabelDropdown = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
  svg: {
    marginRight: '8px',
  },
});
