import { styled } from '@hexa-ui/theme';

export const ReportRulesHeaderContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '$6 0',
  width: '100%',
  margin: '0 auto',
});

export const ReportRulesContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const StyledErrorWrapper = styled('div', {
  paddingTop: '180px',
});
