/* eslint-disable @typescript-eslint/no-empty-function */
const dispatchCustomEvent = (eventName: string): void => {
  const customEvent = new CustomEvent(eventName.toLowerCase());
  window.dispatchEvent(customEvent);
};

const watchCustomEvent = (eventName: string, action: VoidFunction): void => {
  window.addEventListener(eventName.toLowerCase(), action);
};

const removeCustomEvent = (eventName: string, action: VoidFunction): void => {
  window.removeEventListener(eventName.toLowerCase(), action);
};

export { dispatchCustomEvent, removeCustomEvent, watchCustomEvent };
