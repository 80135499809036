import Api from 'Api/Api';
import APIEndpoint from 'Api/APIEndpoint';
import getAPIBaseURL from 'Api/getAPIBaseURL';
import Vendor from 'Domain/Vendor';

const getVendorByIdService = async (id: string, country: string): Promise<Vendor> => {
  const baseURL = getAPIBaseURL(APIEndpoint.GET_VENDOR_BY_ID, country);

  const response = await Api.get({
    url: `/vendors/${id}`,
    headers: {
      country,
    },
    baseURL,
  });

  return response.data;
};

export default getVendorByIdService;
