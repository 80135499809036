import AppModal from 'Components/AppModal/AppModal';
import { useClusterContext } from 'Context/ClusterContext/ClusterContext';
import { useCountry } from 'Hooks/useCountry/useCountry';
import { updateRules } from 'Services/UpdateRules/UpdateRules';
import { dispatchCustomEvent } from 'Utils/customEvents/customEventWatchDispatch';
import { customEventsCommonConsts } from 'Utils/customEvents/customEventsCommonConsts';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import AddNewRuleForm from '../AddNewRuleForm/AddNewRuleForm';
import { ContainerItemAddNewRule } from './AddNewRuleModal.style';
import { AddNewRuleModalProps } from './AddNewRuleModal.types';

export default function AddNewRuleModal({
  isOpen,
  onClose,
  isLoading,
  data,
  refetch,
}: AddNewRuleModalProps): React.ReactElement {
  const methods = useForm({
    mode: 'onChange',
  });
  const { cluster } = useClusterContext();
  const country = useCountry();
  const toastService = useToast();
  const { formatMessage } = useIntl();

  /* istanbul ignore next */
  const onSubmit = async () => {
    try {
      const userType = methods.getValues('userType') as string;
      const businessModel = methods.getValues('businessModel');

      const allData = {
        userType,
        businessModel,
        reportId: data.id,
      };

      await updateRules(allData, country, cluster);
      toastService.notify({
        type: TypeToast.SUCCESS,
        message: formatMessage({ id: 'TOAST.SUCCESS' }),
      });
      refetch();
      onClose();
      dispatchCustomEvent(customEventsCommonConsts.REFETCH_REPORT_DETAILS);
      dispatchCustomEvent(customEventsCommonConsts.REFETCH_GET_REPORTS);
      methods.reset();
    } catch (error) {
      toastService.notify({
        type: TypeToast.ERROR,
        message: formatMessage({ id: 'TOAST.ERROR' }),
      });
    }
  };

  const handleCancel = () => {
    onClose();
    methods.reset();
  };

  return (
    <AppModal
      isOpen={isOpen}
      onClose={handleCancel}
      buttonConfirmLabel="REPORT_NEW_RULE_MODAL.ADD"
      buttonCancelLabel="REPORT_NEW_RULE_MODAL.CANCEL"
      isLoading={isLoading}
      css={{ '@tablet': { minWidth: '600px' } }}
      dataTestId="add-new-rule-modal"
      title="REPORT_NEW_RULE_MODAL.ADD_NEW_RULE"
      onCancelButton={handleCancel}
      onConfirmButton={methods.handleSubmit(onSubmit)}
      disableConfirmButton={!methods.formState.isValid}
    >
      <ContainerItemAddNewRule>
        <FormProvider {...methods}>
          <AddNewRuleForm />
        </FormProvider>
      </ContainerItemAddNewRule>
    </AppModal>
  );
}
