import { useClusterContext } from 'Context/ClusterContext/ClusterContext';
import { useCountry } from 'Hooks/useCountry/useCountry';
import useReportsByVendors, {
  ReportsQueryData,
} from 'Services/GetReportsByVendors/useReportsByVendors';
import { useLocation, useParams } from 'react-router-dom';

const useReports = (): {
  data: ReportsQueryData | undefined;
  refetch: () => void;
} => {
  const defaultCountry = useCountry();
  const { cluster } = useClusterContext();
  const { vendorId: vendorIdFromURL } = useParams<{ vendorId?: string }>();
  const location = useLocation();

  const { isManufacturer } = location.state;

  const { data, refetch } = useReportsByVendors({
    cluster,
    isManufacturer,
    vendorId: vendorIdFromURL,
    country: defaultCountry,
  });

  return { data, refetch };
};

export default useReports;
