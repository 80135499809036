import APIEndpoint from 'Api/APIEndpoint';
import Api from 'Api/Api';
import getAPIBaseURL from 'Api/getAPIBaseURL';
import UpdateReportGroups from 'Domain/UpdateReportGroup';

export const updateReportGroupService = async (
  id: string,
  data: Partial<UpdateReportGroups>,
  cluster: string,
  country: string
  // eslint-disable-next-line max-params
): Promise<UpdateReportGroups> => {
  const baseURL = getAPIBaseURL(APIEndpoint.UPDATE_REPORT_GROUP_BY_ID, country, cluster);

  const response = await Api.put({
    url: `/activation/report-groups/${id}`,
    body: data,
    baseURL,
  });

  return response.data;
};
