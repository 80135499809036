import APIEndpoint from 'Api/APIEndpoint';
import Api from 'Api/Api';
import getAPIBaseURL from 'Api/getAPIBaseURL';
import ExternalReport, { ExternalReportResponse } from 'Domain/ExternalReport';
import MenuItem, { MenuItemResponse } from 'Domain/MenuItem';
import ReportsMFEConfig from 'Domain/ReportsMFEConfig';

interface GetReportsMFEConfigServiceParams {
  vendorId: string;
  country: string;
}

const getReportsMFEConfigService = async ({
  vendorId,
  country,
}: GetReportsMFEConfigServiceParams): Promise<ReportsMFEConfig> => {
  const baseURL = getAPIBaseURL(APIEndpoint.GET_REPORTS_MFE_CONFIG, country);

  const response = await Api.get({
    url: `/reports-mfe-config`,
    params: {
      vendorId,
    },
    headers: {
      country,
    },
    baseURL,
  });

  return {
    ...response.data,
    menuItems: response.data.menuItems.map((menuItem: MenuItemResponse) => new MenuItem(menuItem)),
    externalReports: response.data.externalReports.map(
      (externalReport: ExternalReportResponse) => new ExternalReport(externalReport)
    ),
  } as ReportsMFEConfig;
};

export default getReportsMFEConfigService;
