import { styled } from '@hexa-ui/theme';
import { Link } from 'react-router-dom';

export const Card = styled<any, any>(Link, {
  all: 'unset',
  boxSizing: 'border-box',
  boxShadow: '$025',
  width: '100%',
  textDecoration: 'inherit',
  cursor: 'pointer',
  borderRadius: '$4',
  padding: '$4',
  paddingBottom: '$6',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  background: '#fff',
  transition: 'box-shadow 200ms ease-in',

  '&:hover': {
    boxShadow: '$4',
  },
  '&:focus': {
    outline: '2px solid black',
  },
  '&:active': {
    boxShadow: '$2',
  },
});

export const IconContainer = styled('div', {
  padding: '$2',
  borderRadius: '50%',
  display: 'flex',
  marginBottom: '$2',

  svg: {
    padding: '5px',
  },
});
