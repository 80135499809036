import { Tabs } from '@hexa-ui/components';
import { getKPIDocumentationRoute } from 'Config/MFERoutes';
import MenuItem from 'Domain/MenuItem';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';

import { TabsContainer } from 'Components/KPIDocumentationTabs/KPIDocumentationTabs.style';
import useReportsEvents from 'Hooks/segment/useReportsEvents/useReportsEvents';
import kpiMap from 'Pages/KPIDocumentationPage/kpiMap/kpiMap';

export default function KPIDocumentationTabs({
  menuItems,
  selectedMenuItem,
}: {
  menuItems: MenuItem[];
  selectedMenuItem: MenuItem;
}): React.ReactElement {
  const history = useHistory();
  const { locale, defaultLocale } = useIntl();
  const { vendorId, selectedCountry } = useSelectedVendor();
  const { triggerTabInteraction, triggerPartnerReportsViewed } = useReportsEvents();
  const [pageTabInteraction, setPageTabInteraction] = useState<boolean>(false);

  const handleTabClick = (menuItem: MenuItem) => () => {
    history.push({
      pathname: generatePath(getKPIDocumentationRoute(), {
        menuItem: menuItem.slug,
        country: selectedCountry,
        vendorId,
      }),
    });
    setPageTabInteraction(true);
  };

  useEffect(() => {
    const hasTabInteraction = pageTabInteraction;
    if (hasTabInteraction) triggerTabInteraction();
    setPageTabInteraction(false);
  }, [history, triggerTabInteraction]);

  useEffect(() => {
    triggerPartnerReportsViewed();
  }, []);

  return (
    <TabsContainer>
      <Tabs.Root
        id="tabs"
        data-testid="kpi-documentation-tabs"
        defaultValue={menuItems[0]?.slug}
        value={selectedMenuItem.slug}
      >
        <Tabs.List>
          {menuItems.map((menuItem) =>
            !!kpiMap[menuItem.slug] ? (
              <Tabs.Trigger
                key={menuItem.slug}
                value={menuItem.slug}
                onClick={handleTabClick(menuItem)}
              >
                {menuItem.getTitle(locale, defaultLocale)}
              </Tabs.Trigger>
            ) : (
              <></>
            )
          )}
        </Tabs.List>
      </Tabs.Root>
    </TabsContainer>
  );
}
