import { Paragraph } from '@hexa-ui/components';
import ModalImage from 'Assets/images/modal_image_csv.png';
import AppModal from 'Components/AppModal/AppModal';
import React from 'react';
import { useIntl } from 'react-intl';
import { ContainerItemBecome } from './BecomeGoldCSVModal.style';
import { BecomeGoldCSVModalProps } from './BecomeGoldCSVModal.types';

export default function BecomeGoldCSVModal({
  isOpen,
  onClose,
  isLoading,
  onConfirm,
}: BecomeGoldCSVModalProps): React.ReactElement {
  const { formatMessage } = useIntl();

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
      buttonConfirmLabel="BECOME_PREMIUM_MODAL.BUTTON_BECOME"
      buttonCancelLabel="BECOME_PREMIUM_MODAL.BUTTON_LATER"
      isLoading={isLoading}
      css={{ '@tablet': { minWidth: '600px' } }}
      dataTestId="silver-become-premium-csv-modal"
      title="BECOME_PREMIUM_MODAL.TITLE"
      headerImage={
        <img
          data-testid="data-test-id-png-render"
          alt="CSV"
          src={ModalImage}
          style={{ width: '100%' }}
        />
      }
      onConfirmButton={onConfirm}
      onCancelButton={onClose}
    >
      <ContainerItemBecome>
        <Paragraph data-testid="first-message-modal">
          {formatMessage({ id: 'BECOME_PREMIUM_MODAL.FIRST_DESCRIPTION' })}
        </Paragraph>
      </ContainerItemBecome>
    </AppModal>
  );
}
