import { Button, Heading, Paragraph } from '@hexa-ui/components';
import { ArrowRight } from '@hexa-ui/icons';
import { useMatchMedia } from '@hexa-ui/theme';
import CustomCanComponent from 'Components/CustomCanComponent/CustomCanComponent';
import CountrySelect from 'Components/GetStarted/CountrySelect/CountrySelect';
import ServiceModelSelect from 'Components/GetStarted/ServiceModelSelect/ServiceModelSelect';
import VendorSelect from 'Components/GetStarted/VendorSelect/VendorSelect';
import MFERoutes, { generateReportsPath } from 'Config/MFERoutes';
import permissions from 'Config/permissions';
import Vendor from 'Domain/Vendor';
import { getSegmentBusinessModel } from 'Hooks/segment/useSegmentCommonProps/useSegmentCommonProps';
import useSupportedCountries from 'Hooks/useSupportedCountries/useSupportedCountries';
import useSupportedServiceModels from 'Hooks/useSupportedServiceModels/useSupportedServiceModels';
import { useVendor } from 'Services/GetVendorById/useVendor';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, useHistory } from 'react-router-dom';

import useAppEvents from 'Hooks/segment/useAppEvents/useAppEvents';
import useReportsEvents from 'Hooks/segment/useReportsEvents/useReportsEvents';
import DefaultContainerView from '../DefaultContainer/DefaultContainerView';
import { Container, FiltersContainer, NextButtonContainer, SpaceSelect } from './GetStarted.style';

function DesktopArrowRightIcon() {
  return <ArrowRight size="tiny" />;
}

function GetStarted(): React.ReactElement {
  const supportedCountries = useSupportedCountries();
  const supportedServiceModels = useSupportedServiceModels();

  const [country, setCountry] = useState<string>(
    supportedCountries.length === 1 ? supportedCountries[0] : ''
  );
  const [serviceModel, setServiceModel] = useState<string>(
    supportedServiceModels.length === 1 ? supportedServiceModels[0] : ''
  );
  const [vendorId, setVendorId] = useState<string>('');

  const vendor = useVendor(vendorId, country);

  const { formatMessage } = useIntl();
  const { triggerPartnerSelected } = useReportsEvents();
  const { triggerPageViewed } = useAppEvents();

  useEffect(() => {
    triggerPageViewed({
      URL: window.location.href,
      path: window.location.pathname,
      category: 'REPORTS',
    });
  }, []);

  const disabledButton = !country || !serviceModel || !vendorId || !vendor.data;

  const history = useHistory();
  const handleClick = useCallback(() => {
    const selectedVendor = vendor.data as Vendor;

    triggerPartnerSelected({
      vendor_id: selectedVendor.id,
      vendor_name: selectedVendor.trackingName,
      business_model: getSegmentBusinessModel(selectedVendor.serviceModel, country),
      country: selectedVendor.country,
    });

    history.push({ pathname: generateReportsPath({ vendorId, country }) });
  }, [vendor.data, triggerPartnerSelected, history, vendorId, country]);

  const [isMobile] = useMatchMedia(['mobile']);

  const textAlign = isMobile ? 'left' : 'center';

  return (
    <CustomCanComponent
      perform={[
        [permissions.PartnerDashboard.GLOBAL],
        [permissions.PartnerDashboard['1P']],
        [permissions.PartnerDashboard['3PPRO']],
        [permissions.PartnerDashboard.DTaaS],
      ]}
      fallback={<Redirect to={MFERoutes.HOME} />}
    >
      <>
        <DefaultContainerView dataTest="get-started">
          <Container>
            <Heading
              size="H3"
              alignment={textAlign}
              data-testid="title"
              css={{ marginBottom: '$2' }}
            >
              {formatMessage({ id: 'GET_STARTED.TITLE' })}
            </Heading>
            <Paragraph
              alignment={textAlign}
              size="basis"
              data-testid="paragraph"
              css={{ marginBottom: '$4', '@tablet': { marginBottom: '$8' } }}
            >
              {formatMessage({ id: 'GET_STARTED.PARAGRAPH' })}
            </Paragraph>

            <FiltersContainer>
              <SpaceSelect>
                <CountrySelect
                  onChange={(e) => {
                    setCountry(e);
                    setVendorId('');
                  }}
                  selectedValue={country}
                />
              </SpaceSelect>
              <SpaceSelect>
                <ServiceModelSelect
                  onChange={(e) => {
                    setServiceModel(e);
                    setVendorId('');
                  }}
                  selectedValue={serviceModel}
                />
              </SpaceSelect>
              <SpaceSelect css={{ '@tablet': { marginBottom: '$6' } }}>
                <VendorSelect
                  onChange={(e) => setVendorId(e)}
                  selectedValue={vendorId}
                  country={country}
                  serviceModel={serviceModel}
                />
              </SpaceSelect>
              {!isMobile && (
                <NextButtonContainer>
                  <Button
                    data-testid="next-button"
                    disabled={disabledButton}
                    onClick={handleClick}
                    trailing
                    icon={DesktopArrowRightIcon}
                    size="small"
                  >
                    {formatMessage({ id: 'GET_STARTED.NEXT' })}
                  </Button>
                </NextButtonContainer>
              )}
            </FiltersContainer>
          </Container>
        </DefaultContainerView>
        {isMobile && (
          <div
            style={{
              margin: 'auto',
              width: 'max-content',
              marginTop: '40px',
            }}
          >
            <Button
              data-testid="next-button"
              disabled={disabledButton}
              onClick={handleClick}
              size="large"
              trailing
              icon={ArrowRight}
            >
              {formatMessage({ id: 'GET_STARTED.NEXT' })}
            </Button>
          </div>
        )}
      </>
    </CustomCanComponent>
  );
}

export default GetStarted;
