import { Button, Divider as Divider_, Heading } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const HeadingTitle = styled(Heading, {
  paddingTop: '$4',
  paddingBottom: '$4',
});

export const TextWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '16px',
  textAlign: 'left',
});

export const DetailsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const Prop = styled('div', {
  fontFamily: 'Work Sans',
  fontSize: '1rem',
  fontWeight: 'bold',
});

export const StyledButton = styled(Button, {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderColor: 'rgba(0, 0, 0, 0.16) !important',
  '&:hover': {
    borderColor: '$neutral100 !important',
    background: 'none !important',
    boxShadow: 'none !important',
  },
  '&[data-state="open"]': {
    borderColor: '$neutral100 !important',
    background: 'none !important',
    boxShadow: 'none !important',
  },
});

export const Divider = styled(Divider_, {
  margin: '20px 0 ',
});

export const TableTitle = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: '20px',
});

export const StyledIconContainer = styled('div', {
  display: 'flex',
  marginRight: '$1',
  justifyContent: 'flex-end',
});

export const StyledErrorWrapper = styled('div', {
  paddingTop: '180px',
});
