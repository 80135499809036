import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useCountry } from 'Hooks/useCountry/useCountry';
import { ReportProps } from 'Pages/ReportsDetailsPage/components/ReportRules/Table/ReportRulesTable.types';
import { getReportPermissionsService } from './getReportPermissionsService';

export const useReportPermissions = (
  id: string,
  isManufacturer: boolean,
  country?: string,
  cluster?: string
  // eslint-disable-next-line max-params
): UseQueryResult<ReportProps> => {
  const countryFromHook = useCountry();

  return useQuery({
    queryKey: ['reportPermissions', id],
    queryFn: () =>
      getReportPermissionsService({
        id,
        isManufacturer,
        country: country || countryFromHook,
        cluster: cluster || 'cluster-EU',
      }),
    refetchOnWindowFocus: false,
  });
};
