import { Card as _Card } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const CardContainer = styled(_Card, {
  width: '100%',
  padding: '$6',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  cursor: 'pointer',
  gap: '$4',

  '&:hover': {
    backgroundColor: '$neutral20',
    transition: 'background-color 0.3s ease',
  },
});

const CardContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
});

const Styled = {
  CardContainer,
  CardContent,
};

export default Styled;
