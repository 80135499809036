import { Paragraph, Table, TableProps } from '@hexa-ui/components';
import { EmptyState } from 'Components/EmptyState/EmptyState';
import KPI, { Kpi } from 'Domain/KPI';
import React from 'react';
import { useIntl } from 'react-intl';
import { CategoryTitle, TableContainer, TableStyles } from './KPIDocumentationTable.style';

function Cell({ value }: { value: unknown }) {
  return <Paragraph>{value}</Paragraph>;
}

export default function KPIDocumentationTable({
  kpis,
  isSearchable,
  categoryTitle,
  emptyStateTitle,
  emptyStateText,
}: {
  kpis?: any;
  isSearchable?: boolean;
  categoryTitle?: string;
  emptyStateTitle?: string;
  emptyStateText?: string;
}): React.ReactElement {
  const { formatMessage, locale, defaultLocale } = useIntl();

  const emptyStateTitleProps = emptyStateTitle ?? undefined;
  const emptyStateTextProps =
    emptyStateText ?? formatMessage({ id: 'KPI_DOCUMENTATION_PAGE.EMPTY_STATE' });

  const columns: TableProps<KPI>['columns'] = [
    {
      Cell,
      Header: formatMessage({ id: 'KPI_DOCUMENTATION_PAGE.COLUMNS.NAME' }),
      id: 'name',
      accessor: (kpi: Kpi) => kpi.name,
      disableSortBy: true,
      style: { width: '20%' },
    },
    {
      Cell,
      Header: formatMessage({ id: 'KPI_DOCUMENTATION_PAGE.COLUMNS.DESCRIPTION' }),
      id: 'description',
      accessor: (kpi: Kpi) => kpi.description,
      disableSortBy: true,
      style: { width: '40%' },
    },
    {
      Cell,
      Header: formatMessage({ id: 'KPI_DOCUMENTATION_PAGE.COLUMNS.METHODOLOGY' }),
      id: 'methodology',
      accessor: (kpi: Kpi) => kpi.methodology,
      disableSortBy: true,
      style: { width: '40%' },
    },
  ];

  const searchedKPIS = (
    <>
      {categoryTitle && <CategoryTitle>{categoryTitle}</CategoryTitle>}
      <TableContainer data-testid="kpi-documentation-table">
        <TableStyles empty={kpis?.length < 1}>
          <Table
            columns={columns}
            data={kpis}
            pagination={false}
            emptyMessage={
              <EmptyState
                css={{ padding: '$24 0' }}
                title={emptyStateTitleProps}
                text={emptyStateTextProps}
              />
            }
          />
        </TableStyles>
      </TableContainer>
    </>
  );

  const mappedKPIS =
    !isSearchable &&
    kpis?.map((kpi: KPI) => {
      return (
        <>
          <CategoryTitle>{kpi.getCategory(locale, defaultLocale)}</CategoryTitle>
          <TableContainer data-testid="kpi-documentation-table">
            <TableStyles empty={kpis.length < 1}>
              <Table
                columns={columns}
                data={kpi.getKPIS(locale, defaultLocale)}
                pagination={false}
                emptyMessage={
                  <EmptyState
                    css={{ padding: '$24 0' }}
                    title={emptyStateTitleProps}
                    text={emptyStateTextProps}
                  />
                }
              />
            </TableStyles>
          </TableContainer>
        </>
      );
    });

  return <>{isSearchable ? searchedKPIS : mappedKPIS}</>;
}
