import { Select } from '@hexa-ui/components';
import { IconName } from 'Components/AddNewThemeModal/AddNewThemeModal.types';
import iconMap from 'Utils/iconMap/iconMap';
import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
  SelectIconContainer,
  SelectIconDropdown,
  SelectIconLabelDropdown,
} from './IconSelect.style';

export default function IconSelect(): React.ReactElement {
  const { control } = useFormContext();
  const { formatMessage } = useIntl();

  const renderIconWithLabel = useCallback(
    (iconName: IconName) => {
      const IconComponent = iconMap[iconName];
      return (
        <SelectIconLabelDropdown>
          {IconComponent}
          <Select.Label>{iconName}</Select.Label>
        </SelectIconLabelDropdown>
      );
    },
    [iconMap]
  );

  return (
    <Controller
      name="iconString"
      control={control}
      render={({ field: { onChange, value } }) => {
        const IconComponent = value ? iconMap[value as IconName] : null;
        return (
          <SelectIconContainer>
            <SelectIconDropdown>
              <Select.Root
                data-testid="select-icon"
                label={formatMessage({ id: 'ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.ICON' })}
                placeholder={formatMessage({
                  id: 'ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.ICONS_PLACEHOLDER',
                })}
                icon={IconComponent}
                onChange={onChange}
                value={value}
              >
                {Object.keys(iconMap).map((iconName) => (
                  <Select.Option key={iconName} value={iconName}>
                    {renderIconWithLabel(iconName as IconName)}
                  </Select.Option>
                ))}
              </Select.Root>
            </SelectIconDropdown>
          </SelectIconContainer>
        );
      }}
    />
  );
}
