import { Paragraph } from '@hexa-ui/components';
import AppModal from 'Components/AppModal/AppModal';
import { useClusterContext } from 'Context/ClusterContext/ClusterContext';
import { useCountry } from 'Hooks/useCountry/useCountry';
import { deleteRule } from 'Services/DeleteRule/DeleteRule';
import { dispatchCustomEvent } from 'Utils/customEvents/customEventWatchDispatch';
import { customEventsCommonConsts } from 'Utils/customEvents/customEventsCommonConsts';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import React from 'react';
import { useIntl } from 'react-intl';
import { ContainerItemRule } from './DeleteRuleModal.style';
import { DeleteRuleModalProps } from './DeleteRuleModal.types';

export default function DeleteRuleModal({
  isOpen,
  onClose,
  isLoading,
  id,
}: DeleteRuleModalProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const { cluster } = useClusterContext();
  const country = useCountry();

  const modalTitle = formatMessage({ id: 'DELETE_RULE_MODAL.TITLE' });
  const modalDescription = formatMessage({ id: 'DELETE_RULE_MODAL.DESCRIPTION' });
  const confirmButton = formatMessage({ id: 'DELETE_RULE_MODAL.CONFIRM_BUTTON' });
  const cancelButton = formatMessage({ id: 'DELETE_RULE_MODAL.CANCEL_BUTTON' });

  const toastService = useToast();

  const onCloseModal = () => {
    onClose();
  };

  const handleDeleteRule = async () => {
    try {
      await deleteRule({ id, country, cluster });
      toastService.notify({
        type: TypeToast.SUCCESS,
        message: formatMessage({ id: 'TOAST.SUCCESS' }),
      });
      dispatchCustomEvent(customEventsCommonConsts.REFETCH_GET_RULES);
      onClose();
    } catch (error: any) {
      /* istanbul ignore next */
      toastService.notify({
        type: TypeToast.ERROR,
        message: formatMessage({ id: 'TOAST.ERROR' }),
      });
    }
  };

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onCloseModal}
      buttonConfirmLabel={confirmButton}
      buttonCancelLabel={cancelButton}
      isLoading={isLoading}
      css={{ '@tablet': { minWidth: '500px' } }}
      dataTestId="delete-rule-modal"
      title={modalTitle}
      onCancelButton={onCloseModal}
      onConfirmButton={() => {
        handleDeleteRule();
      }}
    >
      <ContainerItemRule>
        <Paragraph>{modalDescription}</Paragraph>
      </ContainerItemRule>
    </AppModal>
  );
}
