import { styled } from '@hexa-ui/theme';

export const SearchFieldStyles = styled('div', {
  width: '100%',

  variants: {
    locale: {
      'pt-BR': {
        '@tablet': {
          maxWidth: '325px',
        },
      },
      'es-419': {
        '@tablet': {
          maxWidth: '290px',
        },
      },
      'en-US': {
        '@tablet': {
          maxWidth: '256px',
        },
      },
      'id-ID': {
        '@tablet': {
          maxWidth: '256px',
        },
      },
    },
  },

  defaultVariants: {
    locale: 'en-US',
  },

  '[data-testid="search-field-wrapper"]': {
    background: '#F2F2F2',
    padding: '0 8px',
  },
});
