/* eslint-disable react/jsx-no-useless-fragment */
import { Button, Error403, Heading } from '@hexa-ui/components';
import { FEATURE_ACTIVATION_WRITE, WORKSPACES_SCOPES } from 'Assets/Constants';
import { BreadCrumb } from 'Components/BreadCrumb/BreadCrumb';
import ClusterDropdown from 'Components/ClusterDropdown/ClusterDropdown';
import DefaultContainerView from 'Components/DefaultContainer/DefaultContainerView';
import PageContainer from 'Components/PageContainer/PageContainer';
import Sidebar from 'Components/Sidebar/Sidebar';
import WorkspaceCard from 'Components/WorkspaceCard/WorkspaceCard';
import { WorkspaceCardProps } from 'Components/WorkspaceCard/WorkspaceCard.types';
import useUserScopes from 'Hooks/useUserScopes/useUserScopes';
import { hasAdminPermission } from 'Utils/hasAdminPermission/hasAdminPermission';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  BreadCrumbContainer,
  ClusterContainer,
  HeadingTitle,
  StyledErrorWrapper,
  TextWrapper,
  TitleContainer,
} from './WorkspacesPage.style';

// This is a mock of the data that will be replaced by the real data
const FLAG =
  'https://images.tcdn.com.br/img/img_prod/1198389/tecido_bandeira_do_brasil_625_1_00648650f25a7c8d5ac39d58765530f3.jpg';
const cardMock: WorkspaceCardProps[] = [
  {
    id: '12345',
    title: 'Super Giant Workspace Card Name That Is Very Long And Will Be Truncated',
    numberOfUsers: 1,
    accessRules: [
      {
        flag: FLAG,
        name: 'Rule 1',
      },
      {
        flag: '',
        name: 'Rule 2',
      },
      {
        flag: undefined,
        name: 'Rule 3',
      },
    ],
  },
  {
    id: '54321',
    title: 'Workspace Card 2',
    numberOfUsers: 5,
    accessRules: [
      {
        flag: FLAG,
        name: 'Pedro Henrique Coelho',
      },
      {
        flag: '',
        name: 'Bruna Tavares Almeida',
      },
      {
        name: 'Julio César',
      },
      {
        name: 'Fernando',
      },
      {
        name: 'Larissa Manoela Ribeiro de Souza',
      },
      {
        name: 'Rule 6',
      },
    ],
  },
];

export function WorkspacesPage(): React.JSX.Element {
  const userScopes = useUserScopes();
  const hasWorkSpacePermission = hasAdminPermission(userScopes, WORKSPACES_SCOPES);
  const { formatMessage } = useIntl();
  const hasWritePermission = hasAdminPermission(userScopes, FEATURE_ACTIVATION_WRITE);

  return (
    <>
      {hasWorkSpacePermission ? (
        <PageContainer maxWidth="xl">
          <Sidebar />
          <BreadCrumbContainer>
            <BreadCrumb isWorkSpaces />
            <ClusterContainer>
              <ClusterDropdown
                label={formatMessage({ id: 'ACTIVATION_PAGE.CLUSTER_SELECT.LABEL' })}
              />
            </ClusterContainer>
          </BreadCrumbContainer>
          <TitleContainer>
            <HeadingTitle size="H1">{formatMessage({ id: 'WORKSPACES.PAGE_TITLE' })}</HeadingTitle>
          </TitleContainer>
          <DefaultContainerView
            dataTest="WorkspacesPage"
            css={{
              minHeight: '80vh',
              justifyContent: 'start !important',
              padding: '$8',
              background: 'none',
            }}
          >
            <TitleContainer>
              <TextWrapper>
                <Heading size="H2">{formatMessage({ id: 'WORKSPACES.TITLE' })}</Heading>
              </TextWrapper>
              {hasWritePermission && (
                <Button data-testid="modal-button">
                  {formatMessage({ id: 'WORKSPACES.ADD_NEW' })}
                </Button>
              )}
            </TitleContainer>

            {/*  This is the card mock that will be replaced by the real data */}
            <div
              style={{
                gap: '16px',
                display: 'grid',
                gridTemplateColumns: '50% 50%',
                margin: '16px 0',
              }}
            >
              {cardMock.map((card) => (
                <WorkspaceCard
                  key={card.id}
                  id={card.id}
                  title={card.title}
                  accessRules={card.accessRules}
                  numberOfUsers={card.numberOfUsers}
                />
              ))}
            </div>
            {/*  End of the card mock */}
          </DefaultContainerView>
        </PageContainer>
      ) : (
        <StyledErrorWrapper>
          <Error403
            header={formatMessage({ id: 'UNAUTHORIZED.ERROR_TITLE' })}
            description={formatMessage({ id: 'UNAUTHORIZED.ERROR_DESCRIPTION' })}
            hasButton={false}
          />
        </StyledErrorWrapper>
      )}
    </>
  );
}
