/* eslint-disable react/jsx-no-useless-fragment */
import { Error403, Heading } from '@hexa-ui/components';
import { FEATURE_ACTIVATION_MANAGER, FEATURE_SETUP_MANAGER } from 'Assets/Constants';
import { BreadCrumb } from 'Components/BreadCrumb/BreadCrumb';
import ClusterDropdown from 'Components/ClusterDropdown/ClusterDropdown';
import DefaultContainerView from 'Components/DefaultContainer/DefaultContainerView';
import PageContainer from 'Components/PageContainer/PageContainer';
import Sidebar from 'Components/Sidebar/Sidebar';
import useUserScopes from 'Hooks/useUserScopes/useUserScopes';
import React from 'react';
import { useIntl } from 'react-intl';
import { hasAdminPermission } from '../../utils/hasAdminPermission/hasAdminPermission';
import {
  HeadingTitle,
  StyledErrorWrapper,
  TextWrapper,
  TitleContainer,
  VendorManagementContainer,
} from './VendorManagement.style';
import { VendorManagementTable } from './components/VendorManagementTable/VendorManagementTable';

export function VendorManagement(): React.JSX.Element {
  const { formatMessage } = useIntl();
  const userScopes = useUserScopes();
  const hasSetupPermission = hasAdminPermission(userScopes, FEATURE_SETUP_MANAGER);
  const hasActivationPermission = hasAdminPermission(userScopes, FEATURE_ACTIVATION_MANAGER);

  return (
    <>
      {hasSetupPermission || hasActivationPermission ? (
        <PageContainer maxWidth="xl">
          <Sidebar />
          <TitleContainer>
            <HeadingTitle size="H1">
              {formatMessage({ id: 'REPORT_ADMIN_PAGE.DATA_ANALYTICS_TITLE' })}
            </HeadingTitle>
            <ClusterDropdown
              label={formatMessage({ id: 'ACTIVATION_PAGE.CLUSTER_SELECT.LABEL' })}
            />
          </TitleContainer>
          <BreadCrumb isReportsAdminPage={false} />
          <DefaultContainerView
            dataTest="VendorManagementPage"
            css={{
              minHeight: '80vh',
              justifyContent: 'start !important',
              padding: '$8',
            }}
          >
            <TitleContainer>
              <TextWrapper>
                <Heading size="H2">
                  {formatMessage({ id: 'REPORT_VENDOR_ADMIN_PAGE.VENDOR_MANAGEMENT.HEADER.TITLE' })}
                </Heading>
              </TextWrapper>
            </TitleContainer>
            <VendorManagementContainer>
              <VendorManagementTable />
            </VendorManagementContainer>
          </DefaultContainerView>
        </PageContainer>
      ) : (
        <StyledErrorWrapper>
          <Error403
            header={formatMessage({ id: 'UNAUTHORIZED.ERROR_TITLE' })}
            description={formatMessage({ id: 'UNAUTHORIZED.ERROR_DESCRIPTION' })}
            hasButton={false}
          />
        </StyledErrorWrapper>
      )}
    </>
  );
}
