import Api from 'Api/Api';
import APIEndpoint from 'Api/APIEndpoint';
import getAPIBaseURL from 'Api/getAPIBaseURL';

interface GetReportsByGroupIdParams {
  isManufacturer?: boolean;
}

const getReportsByGroupId = async (
  id: string,
  country: string,
  cluster: string,
  isManufacturer: undefined | boolean = undefined
  // eslint-disable-next-line max-params
): Promise<any> => {
  const baseURL = getAPIBaseURL(APIEndpoint.GET_REPORT_GROUP_BY_ID, country, cluster);
  const params: GetReportsByGroupIdParams = {};

  if (isManufacturer !== undefined) {
    params.isManufacturer = isManufacturer;
  }
  const response = await Api.get({
    baseURL,
    params,
    url: `/activation/reports?reportGroupId=${id}`,
    headers: {
      country,
    },
  });

  return response.data;
};

export default getReportsByGroupId;
