export enum ViewMode {
  TAB = 'TAB',
  VIEW = 'VIEW',
}

export default interface ReportGroupById {
  id?: string;
  iconString: string;
  slug: string;
  trackingId: string;
  title: {
    'en-US': string;
    'es-419': string;
    'pt-BR': string;
    'id-ID': string;
  };
  order: number;
  viewMode: ViewMode;
  enabled: boolean;
}
