import Api from 'Api/Api';
import APIEndpoint from 'Api/APIEndpoint';
import getAPIBaseURL from 'Api/getAPIBaseURL';

interface VendorIdServiceParams {
  pageSize?: number;
  customTotalElements?: number;
  country?: string;
  serviceModel?: string;
  cluster?: string;
}

export type GetVendorsActivationResponse = {
  id: string;
  name: string;
  isManufacturer: boolean;
};

export const getVendorsActivationService = async (
  params: VendorIdServiceParams
): Promise<GetVendorsActivationResponse[]> => {
  const baseURL = getAPIBaseURL(APIEndpoint.GET_VENDORS, params.country, params.cluster);

  const response = await Api.get({
    url: `/activation/vendors`,
    baseURL,
  });
  return response.data;
};
