import { Heading } from '@hexa-ui/components';
import CustomTooltip from 'Components/CustomTooltip/CustomTooltip';
import React from 'react';
import { Container } from './Header.style';

function Header({ title }: { title: string }): React.ReactElement {
  return (
    <Container>
      <CustomTooltip placement="bottom" showTooltip text={title}>
        <Heading
          size="H3"
          css={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {title}
        </Heading>
      </CustomTooltip>
    </Container>
  );
}

export default Header;
