import { Heading, Paragraph } from '@hexa-ui/components';
import { Card, IconContainer } from 'Components/ExternalReportLink/ExternalReportLink.styles';
import React from 'react';

export interface ExternalReportLinkProps {
  title: React.ReactNode;
  description: string;
  icon: React.ReactElement;
  color: string;
  href: string;
  dataTestId?: string;
  borderColor?: string;
  isExternal: boolean;
  onClick?: () => void;
}

export function ExternalReportLink({
  title,
  description,
  icon,
  color,
  href,
  dataTestId,
  borderColor = '',
  isExternal,
  onClick,
}: ExternalReportLinkProps): React.ReactElement {
  const cardProps = isExternal ? { as: 'a', href } : { to: href };
  return (
    <Card {...cardProps} target="_blank" data-testid={dataTestId} onClick={onClick}>
      <IconContainer data-testid="icon-container" css={{ background: color, border: borderColor }}>
        {icon}
      </IconContainer>
      <Heading css={{ marginBottom: '$2' }} size="H4">
        {title}
      </Heading>
      <Paragraph size="small">{description}</Paragraph>
    </Card>
  );
}
