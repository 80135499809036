import { Heading } from '@hexa-ui/components';
import React from 'react';
import { useIntl } from 'react-intl';
import { VendorTable } from './Table/VendorTable';
import {
  VendorManagementContainer,
  VendorManagementHeaderContainer,
} from './VendorManagementTable.style';

export function VendorManagementTable(): React.JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <VendorManagementContainer>
      <VendorManagementHeaderContainer>
        <Heading size="H4">
          {formatMessage({ id: 'REPORT_VENDOR_ADMIN_PAGE.VENDOR_MANAGEMENT.HEADER.SUBTITLE' })}
        </Heading>
      </VendorManagementHeaderContainer>
      <VendorTable activation={false} />
    </VendorManagementContainer>
  );
}
