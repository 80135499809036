import { SkeletonLoader } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import React from 'react';

export const Container = styled('header', {
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: '16px',
});

export const HeaderContainer = styled('header', {
  background: '#FFF',
  padding: '$10 $8',
  width: '100%',
  borderRadius: '$8',
  display: 'flex',
  boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.16)',
  alignItems: 'flex-start',
});

export const IconContainer = styled('div', {
  background: '#CBB9FF',
  padding: '12px 4px 4px 12px',
  borderRadius: '$4',
  marginRight: '$8',
});

export const TitleContainer = styled('div', {
  maxWidth: '700px',
  width: '100%',
});

export const SearchFieldContainer = styled('div', {
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-start',
});

export const HeaderLoaderContainer = styled('header', {
  width: '100%',
  display: 'flex',
  marginTop: '32px',
});

export function KPIHeaderLoader(): React.ReactElement {
  return (
    <Container>
      <HeaderLoaderContainer data-testid="kpi-documentation-header-loader">
        <SkeletonLoader width="100%" height="160px" />
      </HeaderLoaderContainer>
      <SearchFieldContainer data-testid="kpi-documentation-search-field-loader">
        <SkeletonLoader width="261px" height="40px" />
      </SearchFieldContainer>
    </Container>
  );
}
