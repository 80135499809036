import APIEndpoint from 'Api/APIEndpoint';
import Api from 'Api/Api';
import getAPIBaseURL from 'Api/getAPIBaseURL';
import AddNewRule from 'Domain/AddNewRule';

export const updateRules = async (
  data: AddNewRule,
  country: string,
  cluster: string
  // eslint-disable-next-line max-params
): Promise<{ id: string }> => {
  const baseURL = getAPIBaseURL(APIEndpoint.ADD_NEW_REPORT, country, cluster);

  const response = await Api.post({
    url: `/permissions`,
    body: data,
    baseURL,
  });

  return response;
};
