import useUserScopes from 'Hooks/useUserScopes/useUserScopes';
import { useMemo } from 'react';
import { CustomCanComponentProps } from './CustomCanComponent.d';

const CustomCanComponent = ({
  perform,
  fallback,
  children,
}: CustomCanComponentProps): React.ReactElement | null => {
  const userScopes = useUserScopes();

  const permissionGranted = useMemo(() => {
    if (!userScopes) {
      return false;
    }

    return perform.some((scopes) => scopes.every((scope) => userScopes.includes(scope)));
  }, [perform, userScopes]);

  if (!userScopes) return null;

  return (permissionGranted ? children : fallback) || null;
};

export default CustomCanComponent;
