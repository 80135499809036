import { SearchField } from '@hexa-ui/components';
import { SearchFieldStyles } from 'Components/KPIDocumentationSearchField/KPIDocumentationSearchField.style';
import { useQueryParam } from 'Hooks/useQueryParam/useQueryParam';
import { SupportedLanguage } from 'I18n/i18n';
import debounce from 'lodash.debounce';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

function KPIDocumentationSearchField(): React.ReactElement {
  const history = useHistory();
  const { formatMessage } = useIntl();

  /*
   * The user could type a lot of characters in a short amount of time, so we
   * debounce the search query to avoid unnecessary re-renders.
   *
   * We use the useRef hook to keep the same debounced function between renders.
   */
  const handleSearch = useRef(
    debounce((newSearchQuery) => {
      history.push({
        search: newSearchQuery ? `?search=${encodeURIComponent(newSearchQuery)}` : '',
      });
    }, 100)
  ).current;

  const searchQuery = useQueryParam('search');

  /*
   * Since we debounce the search query, we need to keep track of the input value separately.
   * This cover the case where the user change the input multiple times before the query is updated.
   *
   * The initial value of the input is the search query from the URL.
   */
  const [searchInputValue, setSearchInputValue] = useState(searchQuery || undefined);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
    handleSearch(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchInputValue('');
    handleSearch(undefined);
  };

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && searchInputValue) {
        handleClearSearch();
        inputRef.current?.blur();
      }
    };

    document.addEventListener('keydown', handleEsc);

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  });

  const inputRef = useRef(null as HTMLInputElement | null);

  useEffect(() => {
    const handleTypingOutsideInput = (e: KeyboardEvent) => {
      if (e.target instanceof HTMLInputElement) {
        return;
      }

      if (e.key.match(/^[A-z]$/i)) {
        inputRef.current?.focus();
      }
    };

    document.addEventListener('keydown', handleTypingOutsideInput);

    return () => {
      document.removeEventListener('keydown', handleTypingOutsideInput);
    };
  }, []);

  const { locale } = useIntl();

  return (
    <SearchFieldStyles locale={locale as SupportedLanguage}>
      <SearchField.Root
        value={searchInputValue}
        onChange={handleSearchInputChange}
        onClear={handleClearSearch}
        ref={inputRef}
        placeholder={formatMessage({
          id: 'KPI_DOCUMENTATION_PAGE.SEARCH_FIELD',
        })}
      />
    </SearchFieldStyles>
  );
}

export default KPIDocumentationSearchField;
