import { Paragraph, TextLink } from '@hexa-ui/components';
import MFERoutes from 'Config/MFERoutes';
import { isManager } from 'Domain/User';
import useReportsEvents from 'Hooks/segment/useReportsEvents/useReportsEvents';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import LoggedUserStore from 'Store/LoggedUser/LoggedUserStore';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { SelectedVendorName } from '../SelectedVendorName/SelectedVendorName';
import { CardContainer, ServiceModelAndCountryContainer } from './SelectedVendor.style';

export function SelectedVendor(): React.ReactElement | null {
  const { selectedVendor } = useSelectedVendor();
  const { formatMessage } = useIntl();
  const { loggedUserType } = LoggedUserStore.getState();
  const { triggerChangePartnerButtonClicked } = useReportsEvents();

  if (selectedVendor.isLoading) {
    return null;
  }

  if (selectedVendor.isError) {
    return null;
  }
  const userIsManager = isManager();

  const changeVendor = () => {
    triggerChangePartnerButtonClicked();
  };

  return (
    <CardContainer border="small" elevated="small" data-testid="selected-vendor">
      <SelectedVendorName
        vendorName={selectedVendor.data.name}
        serviceModel={selectedVendor.data.serviceModel}
        vendorId={selectedVendor.data.id}
        tier={userIsManager ? undefined : loggedUserType}
        country={selectedVendor.data.country}
      />

      {userIsManager && (
        <ServiceModelAndCountryContainer>
          <Paragraph
            data-testid="selected-vendor-service-model"
            size="small"
            css={{ marginLeft: '$8' }}
          >
            {formatMessage({
              id: `SERVICE_MODELS.${selectedVendor.data.serviceModel}`,
            })}
          </Paragraph>
          <Paragraph size="small" data-testid="selected-vendor-country" css={{ marginLeft: '$8' }}>
            {formatMessage({ id: `COUNTRIES.${selectedVendor.data.country}` })}
          </Paragraph>
        </ServiceModelAndCountryContainer>
      )}

      {userIsManager && (
        <TextLink
          css={{ marginLeft: 'auto', paddingLeft: '$4', '@tablet': { paddingLeft: '$8' } }}
          asChild
          size="small"
          hasUnderline={false}
        >
          <Link data-testid="change-selected-vendor" to={MFERoutes.GET_STARTED} onClick={changeVendor}>
            {formatMessage({ id: 'SELECTED_VENDOR.CHANGE' })}
          </Link>
        </TextLink>
      )}
    </CardContainer>
  );
}