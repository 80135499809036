import { Paragraph, Toggle } from '@hexa-ui/components';
import { ReportDataBody } from 'Components/AddNewReportModal/AddNewReportModal.types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

export default function IsManufactureToggle(): React.ReactElement {
  const { control } = useFormContext<ReportDataBody>();
  const { formatMessage } = useIntl();

  return (
    <Controller
      name="isManufacturer"
      control={control}
      rules={{
        required: false,
      }}
      render={({ field: { onChange, value } }) => (
        <div>
          <Paragraph size="basis" weight="medium">
            {formatMessage({ id: 'ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.IS_MANUFACTURER' })}
          </Paragraph>
          <Toggle onClick={onChange} checked={value} data-testid="isManufacturer-toggle" />
        </div>
      )}
    />
  );
}
