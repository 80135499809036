import { Icons } from 'Components/Sidebar/icons';
import { generateKPIDocumentationPath } from 'Config/MFERoutes';
import { kpiDocumentationServiceModels } from 'Config/kpiDocumentation';
import { ReportsPageContext } from 'Context/ReportsPageContext';
import { isSalesManager } from 'Domain/User';
import useAppEvents from 'Hooks/segment/useAppEvents/useAppEvents';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import useReportsMFEConfig from 'Services/GetReportsMFEConfig/useReportsMFEConfig';
import { SidebarUtilitiesItem } from 'admin-portal-shared-services';
import { usePartnerSidebarUtils } from 'partner-mfe-shared-features';
import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

export default function useSidebarUtils(): SidebarUtilitiesItem[] {
  const { formatMessage } = useIntl();
  const { triggerMenuInteraction } = useAppEvents();

  const { vendorId, selectedVendor, selectedCountry } = useSelectedVendor();
  const reportsPageContext = useContext(ReportsPageContext);
  const partnerSidebarUtils = usePartnerSidebarUtils();
  const reportsMFEConfig = useReportsMFEConfig({ vendorId, country: selectedCountry });

  return useMemo(() => {
    const utils: SidebarUtilitiesItem[] = [];

    const onPageChange = () => {
      triggerMenuInteraction('KPI_DOCUMENTATION');
    };

    if (
      reportsMFEConfig.isLoading ||
      reportsMFEConfig.isError ||
      reportsMFEConfig.data.menuItems.length < 1
    ) {
      return utils;
    }

    if (
      selectedVendor.data &&
      kpiDocumentationServiceModels.includes(selectedVendor.data.serviceModel) &&
      !isSalesManager()
    ) {
      utils.push({
        id: 'kpi-documentation',
        title: formatMessage({ id: 'KPI_DOCUMENTATION_PAGE.TITLE' }),
        path: generateKPIDocumentationPath({
          vendorId,
          country: selectedCountry,
          menuItem:
            reportsPageContext?.selectedMenuItem?.slug || reportsMFEConfig.data?.menuItems[0].slug,
        }),
        icon: Icons.KPIDocumentation,
        target: '_blank',
        onPageChange,
      });
    }

    return utils;
  }, [
    reportsMFEConfig.isLoading,
    reportsMFEConfig.isError,
    selectedVendor.data,
    partnerSidebarUtils,
    formatMessage,
    vendorId,
    selectedCountry,
    reportsPageContext?.selectedMenuItem?.slug,
    triggerMenuInteraction,
    reportsMFEConfig.data?.menuItems,
  ]);
}
