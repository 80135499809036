import { SkeletonLoader } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import React from 'react';

export const TabsContainer = styled('div', {
  marginBottom: '$6',
});

const TabsLoaderContainer = styled('div', {
  display: 'flex',
  paddingTop: '16px',
  flexShrink: 0,
});

export function KPITabsLoader(): React.ReactElement {
  return (
    <TabsLoaderContainer data-testid="kpi-documentation-tabs-loader">
      <SkeletonLoader width="100%" height="52px" />
    </TabsLoaderContainer>
  );
}
