import APIEndpoint from 'Api/APIEndpoint';
import Api from 'Api/Api';
import getAPIBaseURL from 'Api/getAPIBaseURL';

interface VendorIdServiceParams {
  country: string;
  cluster: string;
  selectedVendorId: string;
}

export const deleteVendorsActivationService = async (
  params: VendorIdServiceParams
): Promise<any> => {
  const baseURL = getAPIBaseURL(APIEndpoint.GET_VENDORS, params.country, params.cluster);

  const response = await Api.delete({
    url: `/activation/vendors/${params.selectedVendorId}`,
    baseURL,
  });
  return response.data;
};
