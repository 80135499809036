import BusinessModelSelect from 'Components/AddNewReportModal/AddNewReportForm/BusinessModelSelect/BusinessModelSelect';
import UserTypeSelect from 'Components/UserTypeSelect/UserTypeSelect';
import React from 'react';
import { FormContainerItemAddRule } from './AddNewRuleForm.style';

export default function AddNewRuleForm(): React.JSX.Element {
  return (
    <FormContainerItemAddRule>
      <UserTypeSelect name="userType" />
      <BusinessModelSelect />
    </FormContainerItemAddRule>
  );
}
