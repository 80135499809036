import { Paragraph } from '@hexa-ui/components';
import ExclusiveGoldBadge from 'Components/ExclusiveGoldBadge/ExclusiveGoldBadge';

import useSelectedTab from 'Hooks/useSelectedTab/useSelectedTab';
import React from 'react';
import { useIntl } from 'react-intl';
import CSVDownload from '../../CSVDownload/CSVDownload';
import { TabsStyles } from './ContentReportsTabsView.style';

const ContentReportsTabsView = (): React.ReactElement | null => {
  const { formatMessage } = useIntl();
  const selectedTab = useSelectedTab();

  return (
    <TabsStyles>
      <div className="container">
        {['ORDERS', 'INSIGHTS'].includes(selectedTab.reportCode) && (
          <Paragraph size="basis">
            {formatMessage({ id: 'REPORTS_PAGE.CONTENT_REPORTS_TAB_VIEW' })}
          </Paragraph>
        )}
        <ExclusiveGoldBadge />
        <CSVDownload />
      </div>
    </TabsStyles>
  );
};

export default ContentReportsTabsView;
