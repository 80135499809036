import { Heading } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const HeadingTitle = styled(Heading, {
  paddingTop: '$4',
  paddingBottom: '$4',
});

export const SubtitleWrapper = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '$6',
  paddingTop: '$8',
});

export const TitleContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const StyledErrorWrapper = styled('div', {
  paddingTop: '180px',
});
