import { Paragraph } from '@hexa-ui/components';
import React from 'react';
import { useIntl } from 'react-intl';

import CustomInput from 'Components/CustomInput/CustomInput';
import { FormContainerItemAddNewTheme, ItemsContainer } from './AddNewThemeForm.style';
import IconSelect from './IconSelect/IconSelect';
import ViewModeToggle from './ViewModeToggle/ViewModeToggle';

export default function AddNewThemeForm(): React.ReactElement {
  const { formatMessage } = useIntl();

  return (
    <FormContainerItemAddNewTheme>
      <Paragraph size="small" weight="semibold">
        {formatMessage({ id: 'ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.NAME' })}
      </Paragraph>
      <ItemsContainer>
        <CustomInput
          name="title.en-US"
          hint="ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.NAME_HINT_US"
          placeholderId="ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.NAME_US"
          errorId="ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.ERRORS.REQUIRED"
          data-testid="title-en-US"
        />
        <CustomInput
          name="title.es-419"
          hint="ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.NAME_HINT_ES"
          placeholderId="ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.NAME_ES"
          errorId="ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.ERRORS.REQUIRED"
        />
        <CustomInput
          name="title.pt-BR"
          hint="ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.NAME_HINT_PT"
          placeholderId="ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.NAME_PT"
          errorId="ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.ERRORS.REQUIRED"
        />
        <CustomInput
          name="title.id-ID"
          hint="ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.NAME_HINT_ID"
          placeholderId="ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.NAME_ID"
          errorId="ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.ERRORS.REQUIRED"
        />
      </ItemsContainer>

      <ItemsContainer>
        <IconSelect />
        <ViewModeToggle />
      </ItemsContainer>
      <ItemsContainer>
        <CustomInput
          label="ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.TRACKING_ID"
          name="trackingId"
          placeholderId="ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.TRACKING_ID_PLACEHOLDER"
          errorId="ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.ERRORS.REQUIRED"
        />
        <CustomInput
          label="ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.ORDER"
          name="order"
          placeholderId="ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.ORDER_PLACEHOLDER"
          errorId="ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.ERRORS.REQUIRED"
          isNumber
        />
      </ItemsContainer>
    </FormContainerItemAddNewTheme>
  );
}
