import { styled } from '@hexa-ui/theme';

export const AvatarContainer = styled('div', {
  variants: {
    type: {
      MANAGER: {
        backgroundColor: '#E6FBFB',
      },
      PARTNER: {
        backgroundColor: '#F8F5FF',
      },
      SALES_MANAGER: {
        backgroundColor: '#ECFEF2',
      },
      GROUP_ICON: {
        backgroundColor: '$neutral30',
        color: '$neutral100',
      },
    },
  },
  borderRadius: '50%',
  width: '20px',
  height: '20px',
  padding: '$6',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
