import BecomeGoldCSVModal from 'Components/CSVDownloadModal/BecomeGoldCSVModal/BecomeGoldCSVModal';
import Last12MonthsCSVModal from 'Components/CSVDownloadModal/Last12MonthsCSVModal/Last12MonthsCSVModal';
import Last30DaysCSVModal from 'Components/CSVDownloadModal/Last30DaysCSVModal/Last30DaysCSVModal';
import CSVDownloadType from 'Domain/CSVDownloadType';
import useCSVDownload from 'Hooks/useCSVDownload/useCSVDownload';
import useSelectedTab from 'Hooks/useSelectedTab/useSelectedTab';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface CSVDownloadModalProps {
  isOpen: boolean;
  isLoading: boolean;
  error: string;
  onClose: () => void;
  setError: (error: string) => void;
  setIsLoading: (value: boolean) => void;
  indexCSVDownload: number;
}

export default function CSVDownloadModal({
  isOpen,
  isLoading,
  error,
  onClose,
  setError,
  setIsLoading,
  indexCSVDownload,
}: CSVDownloadModalProps): React.ReactElement | null {
  const history = useHistory();
  const selectedTab = useSelectedTab();

  const { downloadCSV } = useCSVDownload({
    setError,
    setIsLoading,
    indexCSVDownload,
    onSuccess: () => onClose(),
  });

  const handleBecomeGold = () => {
    history.push('/reports/become-premium');
  };

  const handleFreeTrialDownload = () => {
    downloadCSV();
  };

  const handleGoldDownload = (period: { year: number; month: number }) => {
    downloadCSV(period);
  };

  if (!selectedTab.csvDownload.length) return null;

  if (selectedTab.csvDownload[indexCSVDownload].type === CSVDownloadType.BECOME_GOLD) {
    return (
      <BecomeGoldCSVModal
        isOpen={isOpen}
        isLoading={isLoading}
        onClose={onClose}
        onConfirm={handleBecomeGold}
      />
    );
  }

  if (selectedTab.csvDownload[indexCSVDownload].type === CSVDownloadType.LAST_30_DAYS) {
    return (
      <Last30DaysCSVModal
        isOpen={isOpen}
        isLoading={isLoading}
        isError={!!error}
        onClose={onClose}
        onConfirm={handleFreeTrialDownload}
      />
    );
  }

  return (
    <Last12MonthsCSVModal
      isLoading={isLoading}
      onConfirm={handleGoldDownload}
      isOpen={isOpen}
      onClose={onClose}
      error={error}
      setError={setError}
    />
  );
}
