import { SkeletonLoader } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import React from 'react';

export const TableContainer = styled('div', {
  paddingBottom: '2rem',
});

export const TableStyles = styled('div', {
  boxShadow: '$2',
  borderRadius: '$4',

  'td, th': {
    padding: '$4 $8',
    lineHeight: '20px',
  },

  p: {
    fontSize: '14px',
    lineHeight: '20px',
  },

  'tr:last-child': {
    borderBottom: 'none',
  },

  'table tr td:first-child p': {
    fontWeight: 600,
  },

  'tr:hover': { backgroundColor: 'rgba(242, 242, 242, 0.5)' },

  th: {
    background: '#F2F2F2',
  },

  table: {
    borderRadius: '$4',
    overflow: 'hidden',
  },

  variants: {
    empty: {
      true: {
        'table tbody tr td div': {
          height: 'unset',
        },
        'table tr td:first-child p': {
          fontWeight: 'normal',
        },
      },
    },
  },
});
const TableLoaderContainer = styled('div', {
  display: 'flex',
  marginTop: '24px',
  flexShrink: 0,
});

export const CategoryTitle = styled('div', {
  marginBottom: '16px',
  width: '100%',
  fontFamily: 'Work Sans',
  fontSize: '20px',
  fontWeight: 600,
});
export function KPITableLoader(): React.ReactElement {
  return (
    <TableLoaderContainer data-testid="kpi-documentation-table-loader">
      <SkeletonLoader width="100%" height="550px" />
    </TableLoaderContainer>
  );
}
