/* eslint-disable react/jsx-no-useless-fragment */
import { Divider, Error403 } from '@hexa-ui/components';
import { FEATURE_SETUP_MANAGER } from 'Assets/Constants';
import AddNewReportModal from 'Components/AddNewReportModal/AddNewReportModal';
import { Permission } from 'Components/AddNewReportModal/AddNewReportModal.types';
import { BreadCrumb } from 'Components/BreadCrumb/BreadCrumb';
import DefaultContainerView from 'Components/DefaultContainer/DefaultContainerView';
import PageContainer from 'Components/PageContainer/PageContainer';
import Sidebar from 'Components/Sidebar/Sidebar';
import { useClusterContext } from 'Context/ClusterContext/ClusterContext';
import { useCountry } from 'Hooks/useCountry/useCountry';
import useUserScopes from 'Hooks/useUserScopes/useUserScopes';
import { useReportsById } from 'Services/GetReportsById/useReportsById';
import { useReportsByGroupId } from 'Services/GetReportsByReportGroupId/useReportsByGroupId';
import { hasAdminPermission } from 'Utils/hasAdminPermission/hasAdminPermission';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import { HeadingTitle, StyledErrorWrapper, TitleContainer } from './ReportsDetailsPage.style';
import { ReportRules } from './components/ReportRules/ReportRules';
import { ReportDetailsContainer } from './components/ReportRules/Table/ReportRulesTable.style';
import { ReportsDetailsHeader } from './components/ReportsDetailsHeader/ReportsDetailsHeader';

export function ReportsDetailsPage(): React.JSX.Element {
  const { formatMessage } = useIntl();
  const urlParams = useParams();
  const params = Object.values(urlParams);
  const country = useCountry();
  const { cluster } = useClusterContext();
  const reportId = params[1] as string;
  const location = useLocation();

  const { manufacturer } = location.state || {};

  const { data: reportDetails, refetch: refetchReportDetails } = useReportsById(
    reportId,
    country,
    cluster,
    manufacturer
  );

  const [isNewReportModalEditOpen, setIsNewReportModalEditOpen] = useState<boolean>(false);
  const userScopes = useUserScopes();

  const hasSetupPermission = hasAdminPermission(userScopes, FEATURE_SETUP_MANAGER);

  const {
    trackingId,
    order,
    powerBIWorkspaceId,
    powerBIReportId,
    permissions,
    title,
    reportGroup,
    slug,
    reportCode,
    isManufacturer,
  } = reportDetails || {};

  const { refetch: refetchUseReportsByGroupId } = useReportsByGroupId(
    reportGroup || '',
    country,
    cluster
  );

  const toggleNewReportModalEdit = useCallback(() => {
    setIsNewReportModalEditOpen((prev) => !prev);
  }, []);

  const getDetailsById = () => ({
    title,
    trackingId,
    order,
    powerBIWorkspaceId,
    powerBIReportId,
    slug,
    reportCode,
    isManufacturer,
    id: reportId,
    permissionIds: permissions?.map((permission: Permission) => permission.permissionId) ?? [],
  });

  return (
    <>
      {hasSetupPermission ? (
        <PageContainer maxWidth="xl">
          <Sidebar />
          <TitleContainer>
            <HeadingTitle size="H1">
              {formatMessage({ id: 'REPORT_ADMIN_PAGE.DATA_ANALYTICS_TITLE' })}
            </HeadingTitle>
          </TitleContainer>
          <BreadCrumb />
          <DefaultContainerView
            dataTest="ReportsManagementPage"
            css={{
              minHeight: '80vh',
              justifyContent: 'start !important',
              padding: '$8',
            }}
          >
            <ReportDetailsContainer>
              <ReportsDetailsHeader
                data={getDetailsById()}
                handleOpenEditModal={toggleNewReportModalEdit}
              />
              <Divider />
              <ReportRules />
            </ReportDetailsContainer>
          </DefaultContainerView>
          {trackingId && (
            <AddNewReportModal
              isOpen={isNewReportModalEditOpen}
              onClose={toggleNewReportModalEdit}
              isLoading={false}
              editingReport={getDetailsById()}
              reportGroupId={reportGroup}
              refetchReportDetails={refetchReportDetails}
              refetchUseReportsByGroupId={refetchUseReportsByGroupId}
            />
          )}
        </PageContainer>
      ) : (
        <StyledErrorWrapper>
          <Error403
            header={formatMessage({ id: 'UNAUTHORIZED.ERROR_TITLE' })}
            description={formatMessage({ id: 'UNAUTHORIZED.ERROR_DESCRIPTION' })}
            hasButton={false}
          />
        </StyledErrorWrapper>
      )}
    </>
  );
}
