import { IconButton } from '@hexa-ui/components';
import { Edit2 } from '@hexa-ui/icons';
import { FEATURE_SETUP_MANAGER } from 'Assets/Constants';
import useUserScopes from 'Hooks/useUserScopes/useUserScopes';
import { ReportsDetailsHeaderProps } from 'Pages/ReportsDetailsPage/ReportsDetailsPage.types';
import { hasAdminPermission } from 'Utils/hasAdminPermission/hasAdminPermission';
import React from 'react';
import { useIntl } from 'react-intl';
import LabelValueComponent from '../LabelValueComponent/LabelValueComponent';
import { HeaderWrapper, StyledIconContainer, TableGrid } from './ReportsDetailsHeader.styles';

export function ReportsDetailsHeader({
  data,
  handleOpenEditModal,
}: ReportsDetailsHeaderProps): React.JSX.Element {
  const { formatMessage } = useIntl();

  const userScopes = useUserScopes();

  const hasSetupPermission = hasAdminPermission(userScopes, FEATURE_SETUP_MANAGER);

  return (
    <HeaderWrapper>
      <TableGrid>
        <LabelValueComponent
          label={formatMessage({ id: 'REPORT_ADMIN_PAGE.REPORT_DETAILS.HEADER.TITLE' })}
          value={data?.title?.['en-US']}
          gridArea="reportTitle"
          testId="column-title"
        />
        <LabelValueComponent
          label={formatMessage({ id: 'REPORT_ADMIN_PAGE.REPORT_DETAILS.HEADER.TRACKING_ID' })}
          value={data.trackingId}
          gridArea="reportTrakingId"
          testId="column-tracking-id"
        />
        <LabelValueComponent
          label={formatMessage({ id: 'REPORT_ADMIN_PAGE.REPORT_DETAILS.HEADER.TAB_ORDER' })}
          value={data.order}
          gridArea="reportTabOrder"
          testId="column-tab-order"
        />
        <LabelValueComponent
          label={formatMessage({ id: 'REPORT_ADMIN_PAGE.REPORT_DETAILS.HEADER.WORKSPACE_ID' })}
          value={data.powerBIWorkspaceId}
          gridArea="workspaceId"
          testId="column-workspace-id"
        />
        <LabelValueComponent
          label={formatMessage({ id: 'REPORT_ADMIN_PAGE.REPORT_DETAILS.HEADER.REPORT_ID' })}
          value={data.powerBIReportId}
          gridArea="ReportId"
          testId="column-report-id"
        />
      </TableGrid>
      {hasSetupPermission && (
        <StyledIconContainer>
          <IconButton
            icon={Edit2}
            onClick={handleOpenEditModal}
            data-testid="toggle-edit-report-modal-button"
            variant="inherit"
          />
        </StyledIconContainer>
      )}
    </HeaderWrapper>
  );
}
