import { Select } from '@hexa-ui/components';
import { useClusterContext } from 'Context/ClusterContext/ClusterContext';
import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { ClusterSelectContainer } from './ClusterDropdown.style';

interface ClusterDropdownType {
  label?: string;
  disabled?: boolean;
}

function ClusterDropdown({ label, disabled }: ClusterDropdownType): ReactElement {
  const { cluster, setCluster } = useClusterContext();
  const { formatMessage } = useIntl();

  const handleChange = (event: string) => {
    setCluster(event);
  };

  return (
    <ClusterSelectContainer>
      <Select.Root
        defaultValue={cluster}
        onChange={handleChange}
        data-testid="select-cluster"
        label={label}
        disabled={disabled}
      >
        <Select.Option value="Global_US" data-testid="option-global-us">
          {formatMessage({ id: 'ACTIVATION_PAGE.CLUSTER_SELECT.GLOBAL_US' })}
        </Select.Option>
        <Select.Option value="Global_EU" data-testid="option-global-eu">
          {formatMessage({ id: 'ACTIVATION_PAGE.CLUSTER_SELECT.GLOBAL_EU' })}
        </Select.Option>
      </Select.Root>
    </ClusterSelectContainer>
  );
}

export default ClusterDropdown;
