import APIEndpoint from 'Api/APIEndpoint';
import Api from 'Api/Api';
import getAPIBaseURL from 'Api/getAPIBaseURL';
import { Report } from 'Pages/VendorManagementDetailsPage/VendorManagementDetails.types';

interface Activation {
  permissions: [
    {
      userType: string;
      tier: null;
      businessModel: string;
      countries: string[];
      vendor: [{ id: string; name: string }];
      enabled: boolean;
    }
  ];
}
export const updateVendorsReportById = async (
  data: Activation,
  permissionId: string | undefined,
  country?: string,
  cluster?: string
  // eslint-disable-next-line max-params
): Promise<Report> => {
  const baseURL = getAPIBaseURL(APIEndpoint.ACTIVATE_REPORT_BY_REPORT_ID, country, cluster);

  const response = await Api.patch({
    url: `/permissions/${permissionId}`,
    body: data,
    baseURL,
  });

  return response.data;
};
