import APIEndpoint from 'Api/APIEndpoint';
import Api from 'Api/Api';
import getAPIBaseURL from 'Api/getAPIBaseURL';

export const getAllReportGroups = async (
  params: { country?: string },
  cluster: string
): Promise<any> => {
  const baseURL = getAPIBaseURL(APIEndpoint.GET_REPORT_GROUP, params.country, cluster);

  let headers = {};
  /* istanbul ignore next */
  if (params.country) {
    headers = { country: params.country };
    delete params.country;
  }

  const response = await Api.get({
    url: '/activation/report-groups',
    params,
    baseURL,
    headers,
  });
  return response;
};
/* istanbul ignore next */
export const addNewReportGroupService = async (
  data: any,
  country: string,
  cluster: string
  // eslint-disable-next-line max-params
): Promise<any> => {
  const baseURL = getAPIBaseURL(APIEndpoint.ADD_REPORT_GROUP, country, cluster);

  const response = await Api.post({
    url: `/activation/report-groups`,
    body: data,
    baseURL,
  });

  return response.data;
};
