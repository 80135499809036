import { styled } from '@hexa-ui/theme';

export const StyledIconContainer = styled('div', {
  display: 'flex',
  marginRight: '$1',
  justifyContent: 'flex-end',
});

export const TableContainer = styled('div', {
  td: {
    textAlign: 'left',
  },

  width: '100%',

  variants: {
    empty: {
      true: {
        'table tbody tr td div': {
          height: 'unset',
        },
        'table tr td:first-child p': {
          fontWeight: 'normal',
        },
      },
    },
  },
});

export const TextWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const TitleContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '$6 0',
  width: '100%',
  margin: '0 auto',
});
