import { styled } from '@hexa-ui/theme';

export const StyledIconContainer = styled('div', {
  display: 'flex',
  marginRight: '$1',
  justifyContent: 'flex-end',
});

export const TableContainer = styled('div', {
  td: {
    textAlign: 'left',
  },

  variants: {
    empty: {
      true: {
        'table tbody tr td div': {
          height: 'unset',
        },
        'table tr td:first-child p': {
          fontWeight: 'normal',
        },
      },
    },
  },
});
