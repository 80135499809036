import React from 'react';
import { useIntl } from 'react-intl';
import { Info, Prop, TextWrapper, TitleContainer } from './Header.style';
import { HeaderProps } from './Header.types';

function Header({ reportDetails, reportGroups }: HeaderProps): React.JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <TitleContainer>
      <TextWrapper>
        <Prop>{formatMessage({ id: 'REPORT_DETAILS_PAGE.THEME_NAME' })}</Prop>
        <Info>{reportDetails.themeName || reportGroups?.title?.['en-US']}</Info>
      </TextWrapper>
      <TextWrapper>
        <Prop>{formatMessage({ id: 'REPORT_DETAILS_PAGE.STRUCTURE' })}</Prop>
        <Info>{reportDetails.structure || reportGroups?.viewMode} </Info>
      </TextWrapper>
      <TextWrapper>
        <Prop>{formatMessage({ id: 'REPORT_DETAILS_PAGE.TRACKING_ID' })}</Prop>
        <Info>{reportDetails.trackingId || reportGroups?.trackingId}</Info>
      </TextWrapper>
      <TextWrapper>
        <Prop>{formatMessage({ id: 'REPORT_DETAILS_PAGE.ORDER' })}</Prop>
        <Info>{reportDetails.order || reportGroups?.order}</Info>
      </TextWrapper>
    </TitleContainer>
  );
}

export default Header;
