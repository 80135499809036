import AppModal from 'Components/AppModal/AppModal';
import { useClusterContext } from 'Context/ClusterContext/ClusterContext';
import { useCountry } from 'Hooks/useCountry/useCountry';
import { addNewReportService } from 'Services/AddNewReportService/AddNewReportService';
import { editReportService } from 'Services/EditReportService/EditReportService';
import { createSlug } from 'Utils/createSlug/createSlug';
import { watchCustomEvent } from 'Utils/customEvents/customEventWatchDispatch';
import { customEventsCommonConsts } from 'Utils/customEvents/customEventsCommonConsts';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import AddNewReportForm from './AddNewReportForm/AddNewReportForm';
import { ContainerItemAddNewReport } from './AddNewReportModal.style';
import { AddNewReportModalProps, ReportDataBody } from './AddNewReportModal.types';

export default function AddNewReportModal({
  isOpen,
  onClose,
  isLoading = false,
  editingReport,
  reportGroupId,
  refetchUseReportsByGroupId,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refetchReportDetails,
}: AddNewReportModalProps & { editingReport?: ReportDataBody }): React.ReactElement {
  const toastService = useToast();
  const { formatMessage } = useIntl();
  const { cluster } = useClusterContext();
  const country = useCountry();

  const methods = useForm<ReportDataBody>({
    mode: 'onChange',
    defaultValues: editingReport || {
      title: {
        'en-US': '',
        'es-419': '',
        'pt-BR': '',
        'id-ID': '',
      },
      trackingId: '',
      powerBIReportId: '',
      powerBIWorkspaceId: '',
      order: '',
      isManufacturer: false,
    },
  });

  useEffect(() => {
    methods.reset(editingReport);
  }, [editingReport, methods]);

  useEffect(() => {
    if (refetchReportDetails) {
      watchCustomEvent(customEventsCommonConsts.REFETCH_REPORT_DETAILS, refetchReportDetails);
    }
  }, [refetchReportDetails]);

  const onCloseModal = () => {
    onClose();
    methods.reset();
  };

  /* istanbul ignore next */
  const onSubmit = async (data: ReportDataBody) => {
    const { trackingId, powerBIReportId, powerBIWorkspaceId, reportCode, slug, isManufacturer } =
      data;
    const payload = {
      reportCode: reportCode || data.trackingId,
      reportGroup: reportGroupId,
      slug: slug || createSlug(data.trackingId),
      title: {
        'en-US': data.title['en-US'],
        'es-419': data.title['es-419'],
        'pt-BR': data.title['pt-BR'],
        'id-ID': data.title['id-ID'],
      },
      type: 'POWERBI',
      order: Number(data.order),
      powerBIReportId,
      powerBIWorkspaceId,
      trackingId,
    };

    try {
      if (editingReport) {
        await editReportService(
          { ...payload, permissionIds: editingReport.permissionIds || [] },
          country,
          cluster,
          editingReport.id
        );
      } else {
        await addNewReportService(
          { ...payload, isManufacturer: Boolean(isManufacturer) },
          country,
          cluster
        );
      }
      toastService.notify({
        type: TypeToast.SUCCESS,
        message: formatMessage({ id: 'TOAST.SUCCESS' }),
      });
      if (refetchUseReportsByGroupId) {
        refetchUseReportsByGroupId();
      }
      if (refetchReportDetails && refetchUseReportsByGroupId) {
        refetchReportDetails();
        refetchUseReportsByGroupId();
      }
    } catch (error) {
      toastService.notify({
        type: TypeToast.ERROR,
        message: formatMessage({ id: 'TOAST.ERROR' }),
      });
    }

    onCloseModal();
  };

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onCloseModal}
      buttonConfirmLabel={
        editingReport
          ? 'ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.EDIT'
          : 'ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.CONFIRM'
      }
      buttonCancelLabel="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.CANCEL"
      isLoading={isLoading}
      css={{ '@tablet': { minWidth: '800px' } }}
      dataTestId="add-new-report-modal"
      title={
        editingReport
          ? 'ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.MODAL_EDIT_TITLE'
          : 'ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.MODAL_TITLE'
      }
      onCancelButton={onCloseModal}
      onConfirmButton={methods.handleSubmit(onSubmit)}
      disableConfirmButton={!methods.formState.isValid}
    >
      <ContainerItemAddNewReport>
        <FormProvider {...methods}>
          <AddNewReportForm isEditing={Boolean(editingReport)} />
        </FormProvider>
      </ContainerItemAddNewReport>
    </AppModal>
  );
}
