import { Divider } from '@hexa-ui/components';
import { iconsMap } from 'Components/IconsMap/IconsMap';
import MFERoutes from 'Config/MFERoutes';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PersonasWrapper, ReportGroupContainer } from './ReportGroupCard.style';
import { IReportGroupCardProps } from './ReportGroupCard.types';
import { Avatar } from './components/Avatar/Avatar';
import { CardHeader } from './components/CardHeader/CardHeader';

export function ReportGroupCard({
  title,
  reportCount,
  personas,
  iconString,
  enabled,
  id,
}: IReportGroupCardProps): React.JSX.Element {
  const ReportIcon = iconsMap[iconString as keyof typeof iconsMap] || iconsMap.Store;
  const UserIcon = iconsMap.User;
  const history = useHistory();
  const [enabledToggle, setEnabledToggle] = React.useState<boolean>(enabled);

  const REPORT_DETAILS_URL = `${MFERoutes.REPORTS_MANAGEMENT}/details/${id}`;

  const onCardClick = () => {
    history.push(REPORT_DETAILS_URL);
  };

  return (
    <ReportGroupContainer
      border="medium"
      elevated="medium"
      data-testid="report-group-card"
      onClick={onCardClick}
      id="report-group-card"
    >
      <CardHeader
        reportIcon={ReportIcon}
        reportName={title}
        reportsLength={reportCount}
        enabledToggle={enabledToggle}
        setEnabledToggle={setEnabledToggle}
      />
      <Divider />
      <PersonasWrapper>
        {personas?.map((persona) => (
          <Avatar key={persona} type={persona} Icon={UserIcon} />
        ))}
      </PersonasWrapper>
    </ReportGroupContainer>
  );
}
