import { Paragraph, SegmentedControl } from '@hexa-ui/components';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ViewModeContainer } from './ViewModeToggle.style';

export default function ViewModeToggle(): React.ReactElement {
  const { control, setValue } = useFormContext();
  const { formatMessage } = useIntl();

  return (
    <Controller
      name="viewMode"
      control={control}
      render={({ field }) => (
        <ViewModeContainer>
          <Paragraph size="small" weight="semibold">
            {formatMessage({ id: 'ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.STRUCTURE' })}
          </Paragraph>
          <SegmentedControl
            value={field.value === 'TAB' ? 'TAB' : 'VIEW'}
            onValueChange={(newValue: 'TAB' | 'VIEW') => {
              const viewModeValue = newValue.toUpperCase() as 'TAB' | 'VIEW';
              setValue('viewMode', viewModeValue);
            }}
            options={[
              {
                label: formatMessage({ id: 'ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.STRUCTURE_TAB' }),
                value: 'TAB',
              },
              {
                label: formatMessage({
                  id: 'ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.STRUCTURE_TOGGLE',
                }),
                value: 'VIEW',
              },
            ]}
          />
        </ViewModeContainer>
      )}
    />
  );
}
