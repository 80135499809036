import { useQuery, UseQueryResult } from '@tanstack/react-query';
import Vendor from 'Domain/Vendor';
import getVendorByIdService from './getVendorByIdService';

export const useVendor = (vendorId: string, selectedCountry: string): UseQueryResult<Vendor> => {
  const result = useQuery({
    queryKey: ['vendor', vendorId, selectedCountry],
    queryFn: () => getVendorByIdService(vendorId, selectedCountry),
    enabled: !!vendorId,
    refetchOnWindowFocus: false,
  });

  return result;
};
