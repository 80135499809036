import { Heading } from '@hexa-ui/components';
import GetStarted from 'Components/GetStarted/GetStarted';
import PageContainer from 'Components/PageContainer/PageContainer';
import Sidebar from 'Components/Sidebar/Sidebar';
import MFERoutes from 'Config/MFERoutes';
import { isGlobalPartner, isManager, isMultiVendor } from 'Domain/User';
import { HeadingContainer } from 'Pages/ReportsPage/ReportsPage.style';
import React from 'react';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';

export default function GetStartedPage(): React.ReactElement {
  const { formatMessage } = useIntl();

  if ((!isManager() && !isMultiVendor()) || isGlobalPartner()) {
    return <Redirect to={MFERoutes.HOME} />;
  }

  return (
    <PageContainer>
      <Sidebar />
      <HeadingContainer css={{ display: 'none', '@desktopSmall': { display: 'block' } }}>
        <Heading id="heading" size="H1">
          <div>{formatMessage({ id: 'REPORTS_PAGE.REPORTS_PAGE_TITLE' })}</div>
        </Heading>
      </HeadingContainer>
      <GetStarted />
    </PageContainer>
  );
}
