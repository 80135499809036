import { Heading } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const HeadingTitle = styled(Heading, {
  paddingTop: '$4',
  paddingBottom: '$4',
});

export const TextWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '$4',
});

export const TitleContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const VendorManagementDetailsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  paddingTop: '$8',
  minHeight: '60vh',
});

export const StyledErrorWrapper = styled('div', {
  paddingTop: '180px',
});
