import { kpiDocumentationServiceModels } from 'Config/kpiDocumentation';
import KPI from 'Domain/KPI';
import MenuItem from 'Domain/MenuItem';
import { isGlobalPartner, isMultiVendor } from 'Domain/User';
import { useQueryParam } from 'Hooks/useQueryParam/useQueryParam';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import kpiMap from 'Pages/KPIDocumentationPage/kpiMap/kpiMap';
import useReportsMFEConfig from 'Services/GetReportsMFEConfig/useReportsMFEConfig';
import { setSelectedVendor } from 'Store/LoggedUser/LoggedUserEvents';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

export enum KPIDocumentationPageStatus {
  INITIAL_LOADING = 'INITIAL_LOADING',
  UNAUTHORISED = 'UNAUTHORISED',
  LOADING_KPIS = 'LOADING_KPIS',
  ERROR = 'ERROR',
  NO_MENU_ITEMS = 'NO_MENU_ITEMS',
  INVALID_MENU_ITEM = 'INVALID_MENU_ITEM',
  LOADED = 'LOADED',
  SEARCH_RESULTS = 'SEARCH_RESULTS',
}

type StatusWithNoSelectedMenuItem =
  | KPIDocumentationPageStatus.INITIAL_LOADING
  | KPIDocumentationPageStatus.UNAUTHORISED
  | KPIDocumentationPageStatus.LOADING_KPIS
  | KPIDocumentationPageStatus.ERROR
  | KPIDocumentationPageStatus.NO_MENU_ITEMS
  | KPIDocumentationPageStatus.INVALID_MENU_ITEM;

export type KPIDocumentationPageState =
  | {
      status: StatusWithNoSelectedMenuItem;
      kpis: KPI[];
      menuItems: MenuItem[];
      selectedMenuItem: undefined;
    }
  | {
      status: KPIDocumentationPageStatus.LOADED;
      kpis: KPI[];
      menuItems: MenuItem[];
      selectedMenuItem: MenuItem;
    }
  | {
      status: KPIDocumentationPageStatus.SEARCH_RESULTS;
      kpis: undefined;
      menuItems: MenuItem[];
      selectedMenuItem: MenuItem;
    };

export default function useKPIDocumentationPage(): KPIDocumentationPageState {
  const { vendorId, selectedCountry, selectedVendor } = useSelectedVendor();
  const {
    country: countryFromUrl,
    vendorId: vendorIdFromURL,
    menuItem: selectedMenuItemSlug,
  } = useParams<{ country: string; vendorId: string; menuItem: string }>();

  const isMultiVendorOrGlobalPartner = isMultiVendor() || isGlobalPartner();

  const vendorIdRequest = isMultiVendorOrGlobalPartner ? vendorIdFromURL : vendorId;
  const countryRequest = isMultiVendorOrGlobalPartner ? countryFromUrl : selectedCountry;

  const reportsMFEConfig = useReportsMFEConfig({
    vendorId: vendorIdRequest,
    country: countryRequest,
  });

  const globalOverviewSlug = 'global-overview';

  const searchQuery = useQueryParam('search');

  const selectedMenuItem = useMemo(
    () =>
      selectedMenuItemSlug
        ? reportsMFEConfig.data?.menuItems.find((item) => item.slug === selectedMenuItemSlug)
        : undefined,
    [reportsMFEConfig.data?.menuItems, selectedMenuItemSlug]
  );

  useEffect(() => {
    if (isMultiVendorOrGlobalPartner) {
      setSelectedVendor({
        id: vendorIdFromURL,
        country: countryFromUrl,
      });
    }
  }, [countryFromUrl, vendorIdFromURL]);

  const status = useMemo(() => {
    if (selectedVendor.isLoading) {
      return KPIDocumentationPageStatus.INITIAL_LOADING;
    }

    if (
      selectedVendor.isError ||
      !kpiDocumentationServiceModels.includes(selectedVendor.data.serviceModel)
    ) {
      return KPIDocumentationPageStatus.UNAUTHORISED;
    }

    if (reportsMFEConfig.isError) {
      return KPIDocumentationPageStatus.ERROR;
    }

    if (reportsMFEConfig.isLoading) {
      return KPIDocumentationPageStatus.LOADING_KPIS;
    }

    if (reportsMFEConfig.data.menuItems.length < 1) {
      return KPIDocumentationPageStatus.NO_MENU_ITEMS;
    }

    if (!selectedMenuItem) {
      return KPIDocumentationPageStatus.INVALID_MENU_ITEM;
    }

    if (searchQuery) {
      return KPIDocumentationPageStatus.SEARCH_RESULTS;
    }

    if (!kpiMap[selectedMenuItemSlug]) {
      return KPIDocumentationPageStatus.INVALID_MENU_ITEM;
    }
    return KPIDocumentationPageStatus.LOADED;
  }, [
    reportsMFEConfig.data?.menuItems.length,
    reportsMFEConfig.isError,
    reportsMFEConfig.isLoading,
    searchQuery,
    selectedMenuItem,
    selectedVendor.data?.serviceModel,
    selectedVendor.isError,
    selectedVendor.isLoading,
  ]);

  const kpis = kpiMap[selectedMenuItemSlug];

  const menuItems = useMemo(() => {
    const itemsFromConfig = reportsMFEConfig.data?.menuItems || [];
    const globalOverviewTab = reportsMFEConfig.data?.menuItems.find(
      (report) => report.id === globalOverviewSlug
    );

    if (!isMultiVendor() && globalOverviewTab) {
      const items = itemsFromConfig.filter((report) => report.id !== globalOverviewSlug);
      return items;
    }
    return itemsFromConfig;
  }, [reportsMFEConfig.data?.menuItems]);

  return {
    status,
    kpis,
    menuItems,
    selectedMenuItem,
  } as KPIDocumentationPageState;
}
