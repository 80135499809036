import { useQuery, UseQueryResult } from '@tanstack/react-query';
import getReportsByGroupId from './getReportsByGroupId';

export const useReportsByGroupId = (
  reportGroupId: string,
  selectedCountry: string,
  selectedCluster: string,
  isManufacturer: undefined | boolean = undefined
  // eslint-disable-next-line max-params
): UseQueryResult<any> => {
  const result = useQuery({
    queryKey: ['reports', reportGroupId, selectedCountry],
    queryFn: () =>
      getReportsByGroupId(reportGroupId, selectedCountry, selectedCluster, isManufacturer),
    enabled: !!reportGroupId,
  });

  return result;
};
