import { TypeToast, useToast } from 'admin-portal-shared-services';
import { format } from 'date-fns';
import useCSVEvents from 'Hooks/segment/useCSVEvents/useCSVEvents';
import useSelectedTab from 'Hooks/useSelectedTab/useSelectedTab';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import { useIntl } from 'react-intl';
import { IDataRequest, IRequestBuildCSV } from 'Services/BuildCSV/buildCSV.d';
import buildCSV from 'Services/BuildCSV/buildCSVService';
import getCSV from 'Services/GetCSV/getCSVService';
import { IRequestGetCSV } from 'Services/GetCSV/getCSVService.types';
import { common } from 'Utils/common';

interface UseCSVDownloadParams {
  setIsLoading: (isLoading: boolean) => void;
  setError: (error: string) => void;
  onSuccess: () => void;
  indexCSVDownload: number;
}

interface UseCSVDownloadReturn {
  downloadCSV: (period?: { year: number; month: number }) => void;
}

export default function useCSVDownload({
  setIsLoading,
  setError,
  onSuccess,
  indexCSVDownload,
}: UseCSVDownloadParams): UseCSVDownloadReturn {
  const { formatMessage, locale } = useIntl();
  const selectedTab = useSelectedTab();

  const onGetBuildCSV = (params: IRequestBuildCSV) => buildCSV(params);
  /* istanbul ignore next */
  const onGetCSV = (params: IRequestGetCSV) => getCSV(params);
  const { selectedVendor, selectedCountry } = useSelectedVendor();
  const { triggerCSVDownloadSuccessful, triggerCSVDownloadRequested, triggerCsvDownloadFailed } =
    useCSVEvents();
  const toastService = useToast();

  const onGetDownloadCSV = (period?: { year: number; month: number }) => {
    setIsLoading(true);
    setError('');

    triggerCSVDownloadRequested(period);

    toastService.notify({
      type: TypeToast.INFO,
      message: formatMessage({ id: 'EXPORT_FILE.PREPARING_DOWNLOAD' }),
    });

    const startDate = period && new Date(period.year, period.month - 1, 1);

    onGetBuildCSV({
      onSuccess: onBuildCSVSuccess,
      onFail: onBuildCSVFail,
      startDate: startDate && format(startDate, 'yyyy-MM-dd'),
      vendorId: selectedVendor.data?.id,
      csvExportCode: selectedTab.csvDownload[indexCSVDownload].csvExportCode,
      preferredLanguage: locale,
      country: selectedCountry,
    });
  };

  /* istanbul ignore next */
  const onBuildCSVSuccess = ({ processId }: IDataRequest) => {
    if (!processId) {
      setError('has-error');
      triggerCsvDownloadFailed('NO_INFO_AVAILABLE');
      setIsLoading(false);
      return;
    }

    onGetCSV({
      onSuccess: onGetCSVSuccess,
      onFail: onGetCSVFail,
      country: selectedCountry,
      processId,
    });
  };

  /* istanbul ignore next */
  const onBuildCSVFail = () => {
    toastService.notify({
      type: TypeToast.ERROR,
      message: formatMessage({ id: 'EXPORT_FILE.SOMETHING_WRONG' }),
    });
    setIsLoading(false);
    triggerCsvDownloadFailed('SOMETHING_WENT_WRONG');
  };

  const retryInMilliseconds = 2000;

  /* istanbul ignore next */
  const onGetCSVSuccess = async (data: IDataRequest) => {
    if (data && data.status !== 'DONE') {
      await common.sleep(retryInMilliseconds);
      onBuildCSVSuccess(data);
      return;
    }
    triggerCSVDownloadSuccessful();

    const link = document.createElement('a');
    link.href = data.url;
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();

    toastService.notify({
      type: TypeToast.SUCCESS,
      message: formatMessage({ id: 'EXPORT_FILE.SUCCESS_DOWNLOAD' }),
    });

    setIsLoading(false);

    if (onSuccess) {
      onSuccess();
    }
  };

  /* istanbul ignore next */
  const onGetCSVFail = () => {
    setIsLoading(false);

    toastService.notify({
      type: TypeToast.ERROR,
      message: formatMessage({ id: 'EXPORT_FILE.SOMETHING_WRONG' }),
    });

    triggerCsvDownloadFailed('SOMETHING_WENT_WRONG');
  };

  return {
    downloadCSV: onGetDownloadCSV,
  };
}
