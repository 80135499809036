export interface Kpi {
  name: Record<string, string>;
  description: Record<string, string>;
  methodology: Record<string, string>;
}
export default class KPI {
  constructor(private readonly category: Record<string, string>, private readonly kpis: Kpi[]) {}

  getCategory(locale: string, defaultLocale: string) {
    return this.category[locale] || this.category[defaultLocale];
  }

  getName(kpi: any, locale: string, defaultLocale: string) {
    return kpi.name[locale] || kpi.name[defaultLocale];
  }

  getDescription(kpi: any, locale: string, defaultLocale: string) {
    return kpi.description[locale] || kpi.description[defaultLocale];
  }

  getMethodology(kpi: any, locale: string, defaultLocale: string) {
    return kpi.methodology[locale] || kpi.methodology[defaultLocale];
  }

  getKPIS(locale: string, defaultLocale: string) {
    return this.kpis.map((kpi) => ({
      name: this.getName(kpi, locale, defaultLocale),
      description: this.getDescription(kpi, locale, defaultLocale),
      methodology: this.getMethodology(kpi, locale, defaultLocale),
    }));
  }
}
