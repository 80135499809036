import { Divider, Paragraph } from '@hexa-ui/components';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import AccessRules from './Components/AccessRules/AccessRules';
import Header from './Components/Header/Header';
import Styled from './WorkspaceCard.style';
import { WorkspaceCardProps } from './WorkspaceCard.types';

export const GRAY_COLOR = 'rgba(20, 20, 20, 0.56)';

function WorkspaceCard({
  id,
  title,
  numberOfUsers,
  accessRules,
}: WorkspaceCardProps): React.ReactElement {
  const history = useHistory();
  const { formatMessage } = useIntl();

  const handleClick = () => {
    history.push(`${id}`);
  };

  // eslint-disable-next-line max-params
  function formatPlural(value: number, singular: string, plural: string): string {
    return `${value} ${value === 1 ? singular : plural}`;
  }

  return (
    <Styled.CardContainer
      border="medium"
      elevated="medium"
      data-testid="workspace-card"
      onClick={handleClick}
      id="workspace-card"
    >
      <Header title={title} />

      <Styled.CardContent>
        <AccessRules accessRules={accessRules} />

        <Divider />

        <Paragraph size="xsmall" css={{ color: GRAY_COLOR }}>
          {formatPlural(
            numberOfUsers,
            formatMessage({ id: 'WORKSPACES.CARD.USER' }),
            formatMessage({ id: 'WORKSPACES.CARD.USERS' })
          )}
        </Paragraph>
      </Styled.CardContent>
    </Styled.CardContainer>
  );
}

export default WorkspaceCard;
