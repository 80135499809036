import { useAuthenticationService, useUserMetadata } from 'admin-portal-shared-services';
import {
  GLOBAL_PARTNER_SCOPES,
  GOLD_PARTNER_SCOPE,
  MANAGER_SCOPES,
  PARTNER_SCOPE,
  SALES_MANAGER_SCOPE,
  UserType,
} from 'Assets/Constants';
import useUserScopes from 'Hooks/useUserScopes/useUserScopes';
import { useCallback, useEffect } from 'react';
import {
  setJWT,
  setLoggedUserType,
  setSelectedVendor,
  setVendors,
} from 'Store/LoggedUser/LoggedUserEvents';

export const useJwtProcessing = (): void => {
  const jwtDecoded = useAuthenticationService().parseJwt();
  const userScopes = useUserScopes();
  const userMetadata = useUserMetadata();
  const vendorsFromMetadata = userMetadata.data?.vendors;

  const getFilteredVendorsFromMetadata = useCallback(
    (currentVendors: any[]) => {
      const hasManufacturer = currentVendors?.some((vendor) => vendor.isManufacturer);

      if (
        hasManufacturer &&
        !GLOBAL_PARTNER_SCOPES?.every((scope) => userScopes?.includes(scope))
      ) {
        return currentVendors?.filter((vendor) => vendor.isManufacturer);
      }

      return currentVendors || [];
    },
    [userScopes]
  );

  const getUserType = useCallback((): UserType => {
    if (userMetadata.isLoading) {
      return UserType.LOADING;
    }

    if (userScopes?.some((scope) => MANAGER_SCOPES.includes(scope))) {
      return UserType.MANAGER;
    }

    if (GLOBAL_PARTNER_SCOPES?.every((scope) => userScopes?.includes(scope))) {
      return UserType.GLOBAL_PARTNER;
    }

    if (userScopes?.includes(PARTNER_SCOPE)) {
      const vendorIdFromJWT = jwtDecoded?.vendorId;
      const vendorsFromMetadata = userMetadata.data?.vendors ?? [];

      const hasManufacturer = vendorsFromMetadata?.some((vendor) => vendor.isManufacturer);

      if (!hasManufacturer && vendorsFromMetadata?.length > 1) {
        return UserType.UNAUTHORIZED;
      }

      const hasGoldVendor =
        vendorIdFromJWT &&
        vendorsFromMetadata &&
        vendorsFromMetadata.find(
          (vendor) =>
            vendor.id === vendorIdFromJWT &&
            (vendor as unknown as { scopes: string[] }).scopes.includes(GOLD_PARTNER_SCOPE)
        );

      if (hasGoldVendor) {
        return UserType.GOLD_PARTNER;
      }

      return UserType.SILVER_PARTNER;
    }

    if (userScopes?.includes(SALES_MANAGER_SCOPE)) {
      return UserType.SALES_MANAGER;
    }
    return UserType.UNAUTHORIZED;
  }, [jwtDecoded?.vendorId, userMetadata.data?.vendors, userMetadata.isLoading, userScopes]);

  useEffect(() => {
    setJWT(jwtDecoded);
  }, [jwtDecoded]);

  useEffect(() => {
    const processedUserType = getUserType();
    setLoggedUserType(processedUserType);
  }, [getUserType]);

  useEffect(() => {
    const currentVendors = getFilteredVendorsFromMetadata(vendorsFromMetadata as any);

    setVendors(currentVendors);

    if (currentVendors.length) {
      setSelectedVendor({
        country: currentVendors[0]?.country || '',
        id: currentVendors[0]?.id || '',
      });
    }
  }, [getFilteredVendorsFromMetadata, vendorsFromMetadata]);
};
