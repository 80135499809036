import { Button, Error403, IconButton } from '@hexa-ui/components';
import { Edit2 } from '@hexa-ui/icons';
import { FEATURE_SETUP_MANAGER } from 'Assets/Constants';
import AddNewReportModal from 'Components/AddNewReportModal/AddNewReportModal';
import AddNewThemeModal from 'Components/AddNewThemeModal/AddNewThemeModal';
import { BreadCrumb } from 'Components/BreadCrumb/BreadCrumb';
import DefaultContainerView from 'Components/DefaultContainer/DefaultContainerView';
import Loading from 'Components/Loading/Loading';
import PageContainer from 'Components/PageContainer/PageContainer';
import Sidebar from 'Components/Sidebar/Sidebar';
import { useClusterContext } from 'Context/ClusterContext/ClusterContext';
import { useCountry } from 'Hooks/useCountry/useCountry';
import useUserScopes from 'Hooks/useUserScopes/useUserScopes';
import { useReportGroupById } from 'Services/GetReportGroupById/useReportGroupById';
import { useReportsByGroupId } from 'Services/GetReportsByReportGroupId/useReportsByGroupId';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import { hasAdminPermission } from '../../utils/hasAdminPermission/hasAdminPermission';
import Header from './Header/Header';
import {
  DetailsContainer,
  Divider,
  HeadingTitle,
  Prop,
  StyledErrorWrapper,
  StyledIconContainer,
  TableTitle,
  TextWrapper,
} from './ReportsManagementDetailsPage.style';
import { ReportsManagementDetailsTable } from './Table/ReportsManagementDetailsTable';

export function ReportsManagementDetailsPage(): React.JSX.Element {
  const { formatMessage } = useIntl();
  const urlParams = useParams();
  const params = Object.values(urlParams);
  const id = params[0] as string;
  const country = useCountry();
  const { cluster } = useClusterContext();
  const reportGroups = useReportGroupById(id, country, cluster);
  const { viewMode, trackingId, order, id: groupId } = reportGroups?.data || {};
  const [reportDetails, setReportDetails] = useState({
    themeName: reportGroups?.data?.title['en-US'],
    structure: viewMode,
    trackingId,
    order,
  });

  const location = useLocation();

  const { isManufacturer } = location.state || {};

  const reportGroupId = groupId || '';
  const [isNewReportModalOpen, setIsNewReportModalOpen] = useState<boolean>(false);
  const [isThemeModalEditOpen, setIsThemeModalEditOpen] = useState<boolean>(false);
  const userScopes = useUserScopes();
  const hasSetupPermission = hasAdminPermission(userScopes, FEATURE_SETUP_MANAGER);
  /* istanbul ignore next */
  const handleUpdate = (updatedData: any) => {
    setReportDetails({
      themeName: updatedData.title['en-US'],
      structure: updatedData.viewMode,
      trackingId: updatedData.trackingId,
      order: updatedData.order,
    });
  };

  const toggleNewReportModal = useCallback(() => {
    setIsNewReportModalOpen((prev) => !prev);
  }, []);

  const toggleThemeModalEdit = useCallback(() => {
    setIsThemeModalEditOpen((prev) => !prev);
  }, []);

  const {
    data,
    isLoading,
    refetch: refetchUseReportsByGroupId,
  } = useReportsByGroupId(reportGroupId || '', country, cluster, isManufacturer || undefined);

  const hasPermission = hasSetupPermission;

  if (!hasPermission) {
    return (
      <StyledErrorWrapper>
        <Error403
          header={formatMessage({ id: 'UNAUTHORIZED.ERROR_TITLE' })}
          description={formatMessage({ id: 'UNAUTHORIZED.ERROR_DESCRIPTION' })}
          hasButton={false}
        />
      </StyledErrorWrapper>
    );
  }

  return (
    <PageContainer maxWidth="xl">
      <Sidebar />
      <HeadingTitle size="H1">
        {formatMessage({ id: 'REPORT_ADMIN_PAGE.DATA_ANALYTICS_TITLE' })}
      </HeadingTitle>
      <BreadCrumb />
      <DefaultContainerView
        dataTest="ReportsManagementDetailsPage"
        css={{
          minHeight: '80vh',
          justifyContent: 'start !important',
          padding: '$8',
        }}
      >
        <DetailsContainer>
          {hasPermission && (
            <StyledIconContainer>
              <IconButton
                icon={Edit2}
                onClick={toggleThemeModalEdit}
                data-testid="toggle-edit-theme-modal-button"
                variant="inherit"
              />
            </StyledIconContainer>
          )}
          <Header reportDetails={reportDetails} reportGroups={reportGroups.data} />
          <Divider />
          <TableTitle>
            <TextWrapper>
              <Prop>{formatMessage({ id: 'REPORT_DETAILS_PAGE.REPORTS' })}</Prop>
            </TextWrapper>
            {hasPermission && (
              <Button onClick={toggleNewReportModal} data-testid="toggle-modal-button">
                {formatMessage({ id: 'REPORT_DETAILS_PAGE.ADD_NEW' })}
              </Button>
            )}
          </TableTitle>
          {isLoading ? (
            <Loading />
          ) : (
            <ReportsManagementDetailsTable reportGroupId={reportGroupId} data={data} />
          )}
        </DetailsContainer>
      </DefaultContainerView>
      <AddNewReportModal
        isOpen={isNewReportModalOpen}
        onClose={toggleNewReportModal}
        reportGroupId={reportGroupId}
        refetchUseReportsByGroupId={refetchUseReportsByGroupId}
      />
      <AddNewThemeModal
        isOpen={isThemeModalEditOpen}
        onClose={toggleThemeModalEdit}
        editingTheme={reportGroups?.data}
        onUpdate={handleUpdate}
      />
    </PageContainer>
  );
}
