import APIEndpoint from 'Api/APIEndpoint';
import Api from 'Api/Api';
import getAPIBaseURL from 'Api/getAPIBaseURL';
import { ReportDataBody } from 'Components/AddNewReportModal/AddNewReportModal.types';

export const addNewReportService = async (
  data: ReportDataBody,
  country: string,
  cluster: string
  // eslint-disable-next-line max-params
): Promise<{ id: string }> => {
  const baseURL = getAPIBaseURL(APIEndpoint.ADD_NEW_REPORT, country, cluster);

  const response = await Api.post({
    url: `/activation/reports`,
    body: data,
    baseURL,
  });

  return response;
};
