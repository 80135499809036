/* eslint-disable max-params */
/* eslint-disable consistent-return */
import { Bees, BeesCare, BeesForce, BeesGrow, Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { GLOBAL_OVERVIEW } from 'Assets/Constants';
import { ExternalReportLink } from 'Components/ExternalReportLink/ExternalReportLink';
import GenericErrorView from 'Components/GenericError/GenericErrorView';
import Loading from 'Components/Loading/Loading';
import PageContainer from 'Components/PageContainer/PageContainer';
import Sidebar from 'Components/Sidebar/Sidebar';
import { generateExternalReportsPath, generateReportsPath } from 'Config/MFERoutes';
import { isGlobalOrMultivendorPartner, isMultiVendor } from 'Domain/User';
import useAppEvents from 'Hooks/segment/useAppEvents/useAppEvents';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import useReportsMFEConfig from 'Services/GetReportsMFEConfig/useReportsMFEConfig';
import LoggedUserStore from 'Store/LoggedUser/LoggedUserStore';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, useParams } from 'react-router-dom';
import { HeadingContainerComponent } from './components/HeadingContainer/HeadingContainerComponent';

const Grid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, 221px)',
  padding: '$8 0',
  gridGap: '$4',
});

const colorMap: Record<string, string> = {
  BEES_FORCE_COMMERCIAL_FRONTLINE: '#000',
  BEES_FORCE_IMAGE_RECOGNITION: '#000',
  BEES_GROW_TOPLINE_PROTECTION: '#1af07e',
  BEES_CARE_CUSTOMER_SUPPORT: '#ff6f71',
  BEES_TRANSFORMATION_GUIDE: '#ffffff',
};

const iconMap: Record<string, React.ReactElement> = {
  BEES_FORCE_COMMERCIAL_FRONTLINE: <BeesForce variant="icon" colorType="onDarkBg" />,
  BEES_FORCE_IMAGE_RECOGNITION: <BeesForce variant="icon" colorType="onDarkBg" />,
  BEES_GROW_TOPLINE_PROTECTION: <BeesGrow variant="icon" colorType="onColorBg" />,
  BEES_CARE_CUSTOMER_SUPPORT: <BeesCare variant="icon" colorType="onColorBg" />,
  BEES_TRANSFORMATION_GUIDE: <Bees variant="icon" colorType="standard" />,
};

const borderColorMap: Record<string, string> = {
  BEES_TRANSFORMATION_GUIDE: '1px solid #14141440',
};

// eslint-disable-next-line max-params
const getHref = (
  isExternal: boolean,
  externalUrl?: string,
  slug?: string,
  vendorId?: string,
  selectedCountry?: string
) => {
  if (isExternal) {
    return externalUrl ?? '';
  }
  return `${generateExternalReportsPath({
    vendorId,
    country: selectedCountry,
  })}/${slug}`;
};

export function ExternalReportListPage(): React.ReactElement {
  const { triggerPageViewed, triggerLinkOutButtonClicked } = useAppEvents();
  const { formatMessage, locale, defaultLocale } = useIntl();
  const { selectedVendor, vendors } = LoggedUserStore.getState();

  const { vendorId: vendorIdFromURL } = useParams<{
    vendorId?: string;
  }>();

  const manufacturerIds = vendors.map((vendor) => vendor.id).join(',');

  const { vendorId, selectedCountry } = useSelectedVendor();

  const reportsMFEConfig = useReportsMFEConfig({
    vendorId,
    country: selectedCountry,
  });

  useEffect(() => {
    if (reportsMFEConfig?.data && reportsMFEConfig.data.externalReports.length >= 1) {
      triggerPageViewed({
        URL: window.location.href,
        path: window.location.pathname,
        category: 'ADDITIONAL_REPORTS',
      });
    }
  }, [triggerPageViewed, reportsMFEConfig]);

  const multivendorOrGlobalMultivendor = isGlobalOrMultivendorPartner() || isMultiVendor();

  if (multivendorOrGlobalMultivendor && selectedVendor.id === GLOBAL_OVERVIEW) {
    return <Redirect to={generateReportsPath({ vendorId, country: selectedCountry })} />;
  }

  const triggerEventTransformationGuide = (
    buttonName: string,
    buttonLabel: string,
    isExternal: boolean
    // eslint-disable-next-line max-params
  ) => {
    /* istanbul ignore else */
    if (isExternal) {
      triggerLinkOutButtonClicked({
        button_name: buttonName,
        button_label: buttonLabel,
      });
    }
  };

  if (reportsMFEConfig.isError) {
    return (
      <PageContainer maxWidth="xl">
        <Sidebar />
        <GenericErrorView />
      </PageContainer>
    );
  }

  if (reportsMFEConfig.isLoading) {
    return (
      <PageContainer maxWidth="xl">
        <Sidebar />
        <Loading container />
      </PageContainer>
    );
  }

  const { externalReports } = reportsMFEConfig.data;

  if (!vendorId || externalReports.length < 1) {
    return <Redirect to={generateReportsPath({ vendorId, country: selectedCountry })} />;
  }

  if (multivendorOrGlobalMultivendor && vendorId !== vendorIdFromURL) {
    return (
      <Redirect
        to={generateExternalReportsPath({
          vendorId,
          country: selectedCountry,
        })}
      />
    );
  }

  return (
    <PageContainer maxWidth="xl">
      <Sidebar
        reportsMenuItems={reportsMFEConfig.data.menuItems}
        externalReports={externalReports}
      />
      <HeadingContainerComponent
        multivendorOrGlobalMultivendor={multivendorOrGlobalMultivendor}
        selectedVendorId={selectedVendor.id}
        manufacturerIds={manufacturerIds}
      />

      <Paragraph size="small">
        {formatMessage({ id: 'EXTERNAL_REPORTS_PAGE.DESCRIPTION' })}
      </Paragraph>
      <Grid>
        {externalReports.map((report) => (
          <ExternalReportLink
            key={report.reportCode}
            title={report.getTitle(locale, defaultLocale)}
            description={report.getDescription(locale, defaultLocale)}
            icon={iconMap[report.reportCode]}
            color={colorMap[report.reportCode]}
            borderColor={borderColorMap[report.reportCode]}
            href={getHref(
              report.isExternal,
              report.externalUrl,
              report.slug,
              vendorId,
              selectedCountry
            )}
            dataTestId={`external-report-link-${report.slug}`}
            isExternal={report.isExternal}
            onClick={() =>
              triggerEventTransformationGuide(
                report.reportCode,
                report.getTitle(locale, defaultLocale),
                report.isExternal
              )
            }
          />
        ))}
      </Grid>
    </PageContainer>
  );
}
