import AppModal from 'Components/AppModal/AppModal';
import { useClusterContext } from 'Context/ClusterContext/ClusterContext';
import AddNewVendor from 'Domain/AddNewVendor';
import { useCountry } from 'Hooks/useCountry/useCountry';
import { addNewVendorService } from 'Services/AddNewVendorService/AddNewVendorService';
import { updateVendor } from 'Services/UpdateVendor/UpdateVendor';
import { dispatchCustomEvent } from 'Utils/customEvents/customEventWatchDispatch';
import { customEventsCommonConsts } from 'Utils/customEvents/customEventsCommonConsts';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ContainerItemNewVendor } from './NewVendorModal.style';
import { IFormInput, NewVendorModalProps } from './NewVendorModal.types';
import NewVendorModalForm from './NewVendorModalForm/NewVendorModalForm';

export default function NewVendorModal({
  isOpen,
  onClose,
  isLoading,
  editingVendor,
  onUpdate,
}: NewVendorModalProps & { editingVendor?: AddNewVendor }): React.ReactElement {
  const { formatMessage } = useIntl();
  const { cluster } = useClusterContext();
  const country = useCountry();
  const methods = useForm<IFormInput>({
    mode: 'onChange',
    defaultValues: {
      vendorId: '',
      name: '',
      isManufacturer: false,
    },
  });

  useEffect(() => {
    if (!editingVendor) {
      methods.reset();
    }
  }, [isOpen]);

  const addTitle = formatMessage({ id: 'VENDOR_REGISTRATION_PAGE.MODAL.TITLE_ADD_VENDOR' });
  const editTitle = formatMessage({ id: 'VENDOR_REGISTRATION_PAGE.MODAL.TITLE_EDIT_VENDOR' });
  const addButton = formatMessage({ id: 'VENDOR_REGISTRATION_PAGE.MODAL.ADD_BUTTON' });
  const editButton = formatMessage({ id: 'VENDOR_REGISTRATION_PAGE.MODAL.EDIT_BUTTON' });
  const cancelButton = formatMessage({ id: 'VENDOR_REGISTRATION_PAGE.MODAL.CANCEL_BUTTON' });

  const successStatus = 200;
  const typeError = TypeToast.ERROR;
  const toastService = useToast();

  const errorMessage = 'already are created in the database';

  const onCloseModal = () => {
    onClose();
    methods.reset();
  };

  useEffect(() => {
    if (editingVendor) {
      methods.reset(editingVendor);
    }
  }, [editingVendor, methods]);

  /* istanbul ignore next */
  const handleToast = (type: TypeToast, message: string) => {
    toastService.notify({
      type,
      message,
    });
  };

  /* istanbul ignore next */
  const handleErrors = (error: any) => {
    if (
      error.response ||
      error?.response?.data?.clientResponse?.body?.message.includes(errorMessage)
    ) {
      handleToast(typeError, formatMessage({ id: 'TOAST.ID_ALREADY_EXISTS' }));
      return;
    }
    if (error.response && error.response.status !== successStatus) {
      handleToast(typeError, formatMessage({ id: 'TOAST.ERROR' }));
    }
  };

  /* istanbul ignore next */
  const handleAddNewVendor = async (data: AddNewVendor) => {
    try {
      await addNewVendorService(data, cluster, country);
      handleToast(TypeToast.SUCCESS, formatMessage({ id: 'TOAST.SUCCESS' }));
      onClose();
      dispatchCustomEvent(customEventsCommonConsts.REFETCH_GET_ALL_VENDORS);
    } catch (error: any) {
      handleErrors(error);
    }
  };

  /* istanbul ignore next */
  const handleEditVendor = async (data: AddNewVendor) => {
    try {
      await updateVendor(editingVendor?.vendorId || '', data, country, cluster);
      handleToast(TypeToast.SUCCESS, formatMessage({ id: 'TOAST.SUCCESS' }));
      onUpdate?.(data);
      onClose();
      dispatchCustomEvent(customEventsCommonConsts.REFETCH_GET_ALL_VENDORS);
    } catch (error: any) {
      handleErrors(error);
    }
  };

  /* istanbul ignore next */
  const onSubmit = (data: AddNewVendor) => {
    const newData = { ...data };
    if (editingVendor) {
      handleEditVendor(data);
    } else {
      handleAddNewVendor(newData);
    }
  };

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onCloseModal}
      buttonConfirmLabel={!editingVendor ? addButton : editButton}
      buttonCancelLabel={cancelButton}
      isLoading={isLoading}
      css={{ '@tablet': { minWidth: '600px' } }}
      dataTestId="add-new-vendor-modal"
      title={!editingVendor ? addTitle : editTitle}
      onCancelButton={onCloseModal}
      onConfirmButton={methods.handleSubmit(onSubmit)}
      disableConfirmButton={!methods.formState.isValid}
    >
      <ContainerItemNewVendor>
        <FormProvider {...methods}>
          <NewVendorModalForm manufacturer={!!editingVendor?.isManufacturer} />
        </FormProvider>
      </ContainerItemNewVendor>
    </AppModal>
  );
}
