import { EU_CLUSTER_COUNTRIES } from 'Assets/Constants';
import MFERoutes from 'Config/MFERoutes';
import permissions from 'Config/permissions';
import { TrackingContext } from 'Context/TrackingContext';
import { isManager, isSalesManager } from 'Domain/User';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import useUserScopes from 'Hooks/useUserScopes/useUserScopes';
import { useContext } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import {
  SegmentBusinessModel,
  SegmentCommonProps,
  SegmentScreenName,
  SegmentUserType,
} from './useSegmentCommonProps.types';

const useScreenName = (): SegmentScreenName => {
  const { pathname } = useLocation();

  if (pathname === MFERoutes.GET_STARTED) return SegmentScreenName.PARTNER_SELECTION;

  if (pathname === MFERoutes.BECOME_PREMIUM) return SegmentScreenName.BECOME_PREMIUM;

  if (
    matchPath(pathname, { path: MFERoutes.KPI_DOCUMENTATION_MANAGER }) ||
    matchPath(pathname, { path: MFERoutes.KPI_DOCUMENTATION_PARTNER })
  )
    return SegmentScreenName.KPI_DOC;

  return SegmentScreenName.PARTNER_INSIGHTS;
};

export const getSegmentBusinessModel = (
  serviceModel?: string,
  country?: string
): SegmentBusinessModel | null => {
  if (country && EU_CLUSTER_COUNTRIES.includes(country)) return SegmentBusinessModel.SAAS;
  const map = {
    '1P': SegmentBusinessModel['1P'],
    '3P-3L-SS': SegmentBusinessModel['3P_PRO'],
    '3P-3L-SF': SegmentBusinessModel.DTAAS,
  };

  return map[serviceModel as keyof typeof map] || null;
};

const getSegmentUserType = (scopes?: string[]): SegmentUserType => {
  if (scopes?.includes(permissions.PartnerDashboard.GLOBAL)) return SegmentUserType.ADMIN;
  if (isManager()) return SegmentUserType.MANAGER;
  if (isSalesManager()) return SegmentUserType.SALES_MANAGER;
  return SegmentUserType.PARTNER;
};

export function useSegmentCommonProps(): SegmentCommonProps {
  const screenName = useScreenName();
  const { selectedVendor, selectedCountry, vendorId } = useSelectedVendor();
  const userScopes = useUserScopes();

  const userType = getSegmentUserType(userScopes);
  const trackingContext = useContext(TrackingContext);

  return {
    screen_name: screenName,
    vendor_id: vendorId || null,
    vendor_name: selectedVendor.data?.trackingName || null,
    country: selectedCountry,
    business_model: getSegmentBusinessModel(
      selectedVendor.data?.serviceModel,
      selectedVendor.data?.country
    ),
    user_type: userType,
    report_type: trackingContext && trackingContext.reportType,
    report_subtype: trackingContext && trackingContext?.reportSubType,
    tab_name: trackingContext && trackingContext.tabName,
  };
}
