import { Select } from '@hexa-ui/components';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { SelectUserTypeContainer, SelectUserTypeDropdown } from './UserTypeSelect.style';

export default function UserTypeSelect({ name }: { name: string }): React.ReactElement {
  const { control } = useFormContext();
  const { formatMessage } = useIntl();

  const options = [
    { name: 'MANAGER', value: 'MANAGER' },
    { name: 'PARTNER', value: 'PARTNER' },
    { name: 'SALES MANAGER', value: 'SALES_MANAGER' },
  ];

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: true,
      }}
      render={({ field: { onChange, value } }) => (
        <SelectUserTypeContainer>
          <SelectUserTypeDropdown>
            <Select.Root
              data-testid="select-user-type"
              label={formatMessage({ id: 'ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.USERTYPE' })}
              placeholder={formatMessage({
                id: 'ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.USERTYPE_PLACEHOLDER',
              })}
              onChange={onChange}
              value={value}
            >
              {options.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.name}
                </Select.Option>
              ))}
            </Select.Root>
          </SelectUserTypeDropdown>
        </SelectUserTypeContainer>
      )}
    />
  );
}
