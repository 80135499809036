import Api from 'Api/Api';
import APIEndpoint from 'Api/APIEndpoint';
import getAPIBaseURL from 'Api/getAPIBaseURL';
import ReportGroupById from 'Domain/ReportGroupById';

const getReportByIdService = async (
  id: string,
  country: string,
  cluster: string
  // eslint-disable-next-line max-params
): Promise<ReportGroupById> => {
  const baseURL = getAPIBaseURL(APIEndpoint.GET_REPORT_GROUP_BY_ID, country, cluster);

  const response = await Api.get({
    url: `/activation/report-groups/${id}`,
    headers: {
      country,
    },
    baseURL,
  });

  return response.data;
};

export default getReportByIdService;
