// CustomInput.tsx
import React from 'react';
import { Input } from '@hexa-ui/components';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

interface CustomInputProps {
  name: string;
  label?: string;
  hint?: string;
  placeholderId: string;
  errorId: string;
  isNumber?: boolean;
}

function CustomInput({
  name,
  label,
  hint,
  placeholderId,
  errorId,
  isNumber = false,
}: CustomInputProps): JSX.Element {
  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext();
  const { formatMessage } = useIntl();

  return (
    <Input
      label={label && formatMessage({ id: label })}
      hint={hint && formatMessage({ id: hint })}
      placeholder={formatMessage({ id: placeholderId })}
      width="100%"
      required
      errorText={formatMessage({ id: errorId })}
      hasError={Boolean(errors[name])}
      defaultValue={getValues(name)}
      {...register(name, { required: true })}
      type={isNumber ? 'number' : 'text'}
    />
  );
}

export default CustomInput;
