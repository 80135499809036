import { css } from '@hexa-ui/theme';

export const gridContainer = css({
  height: 'calc(100vh - 56px)',
  overflow: 'auto',
  margin: '0 !important',

  variants: {
    sidebar: {
      true: {
        '@desktopSmall': {
          paddingLeft: '0 !important',
        },
      },
    },
  },
});
export const gridItem = css({
  margin: 'auto',
  width: '100%',
  height: '100%',
  padding: '0 $4 !important',

  flexDirection: 'column',
  justifyContent: 'flex-start',

  '@desktopSmall': {
    padding: '0 $8 !important',
  },

  variants: {
    maxWidth: {
      lg: {
        maxWidth: '1280px',
      },
      xl: {
        maxWidth: '1920px',
      },
    },
    sidebar: {
      true: {
        '@desktopSmall': {
          paddingLeft: 'calc($4 + 4rem + $10) !important',
        },
      },
    },
  },
});
