import { Error500, Table } from '@hexa-ui/components';
import { FEATURE_ACTIVATION_MANAGER, FEATURE_SETUP_MANAGER } from 'Assets/Constants';
import MFERoutes from 'Config/MFERoutes';
import useUserScopes from 'Hooks/useUserScopes/useUserScopes';
import { hasAdminPermission } from 'Utils/hasAdminPermission/hasAdminPermission';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { TableContainer, TableStyles } from './ReportsManagementDetailsTable.style';

interface IReport {
  title: Record<string, string>;
  powerBIWorkspaceId: string;
  powerBIReportId: string;
  rlsRule: string | undefined;
  isManufacturer: boolean;
  id: string;
}
interface ITableProps {
  reportGroupId: string;
  data: { reports: IReport[] };
}

export function ReportsManagementDetailsTable({
  reportGroupId,
  data,
}: ITableProps): React.JSX.Element {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const getRlsRuleForManufacturer = (isManufacturer: boolean, rlsRule: string | undefined) => {
    if (!rlsRule || rlsRule === 'undefined') {
      return '-';
    }
    if (isManufacturer) {
      return 'manufacturerId';
    }
    return rlsRule;
  };

  const dataReports = data.reports?.map((report) => ({
    report: report.title['en-US'],
    workspaceId: report.powerBIWorkspaceId,
    reportId: report.powerBIReportId,
    rlsRule: getRlsRuleForManufacturer(report.isManufacturer, report.rlsRule),
    id: report.id,
  }));

  const userScopes = useUserScopes();

  const hasSetupPermission = hasAdminPermission(userScopes, FEATURE_SETUP_MANAGER);
  const hasActivationPermission = hasAdminPermission(userScopes, FEATURE_ACTIVATION_MANAGER);

  const handleRowClick = useCallback(
    (id: string) => {
      history.push(`${MFERoutes.REPORTS_MANAGEMENT}/details/${reportGroupId}/${id}`);
    },
    [history, reportGroupId]
  );

  const onRow = useCallback(
    (row: { id: string }) => ({
      onClick: () => handleRowClick(row.id),
    }),
    [handleRowClick]
  );

  const columns: any['columns'] = [
    {
      Header: formatMessage({ id: 'REPORT_DETAILS_PAGE.REPORT' }),
      accessor: 'report',
      disableSortBy: true,
      style: { width: '22%' },
    },
    {
      Header: formatMessage({ id: 'REPORT_DETAILS_PAGE.WORKSPACE_ID' }),
      accessor: 'workspaceId',
      disableSortBy: true,
      style: { width: '22%' },
    },
    {
      Header: formatMessage({ id: 'REPORT_DETAILS_PAGE.REPORT_ID' }),
      accessor: 'reportId',
      disableSortBy: true,
      style: { width: '22%' },
    },
    (hasSetupPermission || hasActivationPermission) && {
      Header: formatMessage({ id: 'REPORT_DETAILS_PAGE.RLS_RULE' }),
      accessor: 'rlsRule',
      disableSortBy: true,
      style: { width: '22%' },
    },
  ];

  return (
    <TableContainer data-testid="kpi-documentation-table">
      <TableStyles>
        <Table
          columns={columns}
          data={dataReports}
          onRow={onRow}
          emptyMessage={
            <div style={{ width: '420px' }}>
              <Error500
                headerText={
                  !dataReports.length
                    ? formatMessage({ id: 'REPORT_ADMIN_PAGE.NO_DATA' })
                    : formatMessage({ id: 'REPORT_ADMIN_PAGE.SOMETHING_WRONG' })
                }
              />
            </div>
          }
        />
      </TableStyles>
    </TableContainer>
  );
}
