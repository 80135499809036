import { useToggleStates } from 'Hooks/useVendorToggleState/useVendorToggleState';
import React from 'react';
import { useIntl } from 'react-intl';
import { CellRendererFunc, createColumn } from '../TableCommon/CreateColumn/CreateColumn';
import CustomTable from '../TableCommon/CustomTable/CustomTable';
import useReports from '../TableCommon/useReports/useReports';

export function TableSalesManager(): React.JSX.Element {
  const { formatMessage } = useIntl();

  const { data, refetch } = useReports();

  const salesManagerReports =
    data?.reports.filter(
      (report: { userType: { sales_manager: boolean } }) => report.userType.sales_manager
    ) || [];

  const { toggleCell, isFetching } = useToggleStates(data, refetch);

  const columnsSalesManagerReports = React.useMemo(() => {
    const getHeader = (id: string) => formatMessage({ id });

    return [
      createColumn({
        header: getHeader('VENDOR_MANAGEMENT_DETAILS_PAGE.TABLE_SALES_MANAGER.THEME'),
        accessor: 'reportGroup',
        width: '20',
      }),
      createColumn({
        header: getHeader('VENDOR_MANAGEMENT_DETAILS_PAGE.TABLE_SALES_MANAGER.REPORT'),
        accessor: 'title',
        width: '20',
      }),
      createColumn({
        header: getHeader('VENDOR_MANAGEMENT_DETAILS_PAGE.TABLE_SALES_MANAGER.WORKSPACE_ID'),
        accessor: 'powerBIWorkspaceId',
        width: '25',
      }),
      createColumn({
        header: getHeader('VENDOR_MANAGEMENT_DETAILS_PAGE.TABLE_SALES_MANAGER.REPORT_ID'),
        accessor: 'powerBIReportId',
        width: '25',
      }),
      createColumn({
        header: getHeader('VENDOR_MANAGEMENT_DETAILS_PAGE.TABLE_SALES_MANAGER.SALES_MANAGER'),
        accessor: 'enabledInUserType.sales_manager',
        width: '10',
        cellRenderer: toggleCell as CellRendererFunc,
      }),
    ];
  }, [formatMessage, toggleCell]);

  return (
    <CustomTable
      titleId="VENDOR_MANAGEMENT_DETAILS_PAGE.SALES_MANAGER_TABLE_TITLE"
      columns={columnsSalesManagerReports}
      data={salesManagerReports}
      noDataMessageId="REPORT_ADMIN_PAGE.NO_DATA"
      tableType="salesManager"
      loading={isFetching}
    />
  );
}
