import { styled } from '@hexa-ui/theme';

export const SpaceSelect = styled('div', {
  marginTop: '16px',
});

export const FiltersContainer = styled('div', {
  width: '100%',
  margin: 'auto',

  '@tablet': {
    maxWidth: '320px',
  },
});

export const NextButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'end',
  marginTop: '16px',
});

export const Container = styled('div', {
  padding: '$6',

  '@tablet': {
    padding: '$14 $4',
  },

  '@desktopSmall': {
    padding: '96px $4',
  },
});
