import KPI from 'Domain/KPI';
import BehaviorKpis from './kpis/BehaviorKpis.json';
import BusinessKpis from './kpis/BusinessKpis.json';
import ClubBKpis from './kpis/ClubBKpis.json';
import PurchaseDriversKpis from './kpis/PurchaseDriversKpis.json';
import RealTimeKpi from './kpis/RealTimeKpis.json';

const kpiMap: Record<string, KPI[]> = {
  'daily-report': RealTimeKpi.map((kpi) => new KPI(kpi.category, kpi.kpis)),
  business: BusinessKpis.map((kpi) => new KPI(kpi.category, kpi.kpis)),
  'buyers-and-behavior': BehaviorKpis.map((kpi) => new KPI(kpi.category, kpi.kpis)),
  'purchase-drivers': PurchaseDriversKpis.map((kpi) => new KPI(kpi.category, kpi.kpis)),
  'club-b': ClubBKpis.map((kpi) => new KPI(kpi.category, kpi.kpis)),
};

export default kpiMap;
