import APIEndpoint from 'Api/APIEndpoint';
import Api from 'Api/Api';
import getAPIBaseURL from 'Api/getAPIBaseURL';
import { Permission, Report, TransformedReport } from './getReportsByVendorsService.types';

export const getReportsByVendorsService = async ({
  cluster,
  vendorId,
  country,
  isManufacturer,
}: {
  cluster: string;
  vendorId?: string;
  country?: string;
  isManufacturer?: boolean;
}): Promise<{ reports: TransformedReport[] }> => {
  const baseURL = getAPIBaseURL(APIEndpoint.GET_REPORT_GROUP, country, cluster);
  try {
    const allReportsResponse = await Api.get({
      baseURL,
      url: `/activation/reports`,
      params: {
        isManufacturer,
      },
    });

    let allReports: Report[] = [];
    if (Array.isArray(allReportsResponse.data.reports)) {
      allReports = allReportsResponse.data.reports;
    }

    const transformedReports: TransformedReport[] = allReports.map((report: Report) => {
      const permissions: Record<string, boolean> = {
        partner: false,
        manager: false,
        sales_manager: false,
      };
      const validEnabledUsers: Record<string, boolean> = {};
      const processUserTypePermission = (permission: Permission, userType: string) => {
        const lowerCaseUserType = userType.toLowerCase();
        permissions[lowerCaseUserType] = !!permission.vendor;
        if (!validEnabledUsers[lowerCaseUserType]) {
          validEnabledUsers[lowerCaseUserType] = false;
        }
        if (
          vendorId &&
          permission.vendor?.some((vendorPermission) => vendorPermission.id === vendorId)
        ) {
          validEnabledUsers[lowerCaseUserType] = true;
        }
      };
      report.permissions.forEach((permission: Permission) => {
        const userType: string = permission?.userType?.toLowerCase();
        if (Object.hasOwn(permissions, userType)) {
          processUserTypePermission(permission, userType);
        }
      });

      return {
        id: report?.id || '',
        reportGroup: report?.reportGroupTitle?.['en-US'] || '',
        title: report?.title?.['en-US'] || '',
        powerBIWorkspaceId: report?.powerBIWorkspaceId || '',
        powerBIReportId: report?.powerBIReportId || '',
        userType: {
          partner: permissions.partner || false,
          manager: permissions.manager || false,
          sales_manager: permissions.sales_manager || false,
        },
        enabledInUserType: {
          partner: validEnabledUsers.partner,
          manager: validEnabledUsers.manager,
          sales_manager: validEnabledUsers.sales_manager,
        },
        permission: report.permissions,
      };
    });

    const sortedTransformedReports = transformedReports
      .slice()
      .sort((a, b) => a.reportGroup.localeCompare(b.reportGroup));

    return {
      reports: sortedTransformedReports,
    };
  } catch (error) {
    console.error('Failed to fetch reports by vendors', error);
    throw new Error('Failed to fetch reports by vendors');
  }
};
