import { styled } from '@hexa-ui/theme';

export const ContainerItemBecome = styled('div', {
  marginBottom: '32px',
});
export const ContainerButton = styled('div', {
  display: 'flex',
  justifyContent: 'right',

  '& Button': {
    margin: '0 0 0 16px',
  },
});
