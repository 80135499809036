import React from 'react';
import { Select } from '@hexa-ui/components';
import { useMatchMedia } from '@hexa-ui/theme';

interface GetStartedSelectProps {
  onChange: (value: string) => void;
  placeholder: string;
  value: string;
  disabled?: boolean;
  children?: React.ReactNode;
  dataTestId: string;
}

function GetStartedSelect({
  value,
  disabled,
  placeholder,
  onChange,
  children,
  dataTestId,
}: GetStartedSelectProps): React.ReactElement {
  const [isMobile] = useMatchMedia(['mobile']);

  return (
    <Select.Root
      data-testid={dataTestId}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      value={value || undefined}
      shape={isMobile ? 'square' : 'pill'}
    >
      {children}
    </Select.Root>
  );
}

export default GetStartedSelect;
