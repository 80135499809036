import { Paragraph } from '@hexa-ui/components';
import React from 'react';
import { LabelValueComponentProps } from '../../ReportsDetailsPage.types';
import { ColumnLabel } from './LabelValueComponent.style';

function LabelValueComponent({
  label,
  value,
  gridArea,
  testId,
  children,
}: LabelValueComponentProps): React.JSX.Element {
  return (
    <div style={{ gridArea: `${gridArea}`, textAlign: 'start' }} data-testid={testId}>
      <ColumnLabel>{label}</ColumnLabel>
      {children || <Paragraph>{value}</Paragraph>}
    </div>
  );
}

export default LabelValueComponent;
