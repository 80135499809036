import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ReportGroupById from 'Domain/ReportGroupById';
import getReportGroupByIdService from './getReportGroupByIdService';

export const useReportGroupById = (
  reportGroupId: string,
  selectedCountry: string,
  selectedCluster: string
  // eslint-disable-next-line max-params
): UseQueryResult<ReportGroupById> => {
  const result = useQuery({
    queryKey: ['report', reportGroupId, selectedCountry],
    queryFn: () => getReportGroupByIdService(reportGroupId, selectedCountry, selectedCluster),
    enabled: !!reportGroupId,
    refetchOnWindowFocus: false,
  });

  return result;
};
