import CountryMultipleVendorsSelect from 'Components/CountryMultipleVendorsSelect/CountryMultipleVendorsSelect';
import { MultiVendorSelectWrapper } from 'Components/ReportsPageWrapper/ReportsPageWrapper.style';
import { SelectedVendor } from 'Pages/ReportsPage/components/SelectedVendor/SelectedVendor';
import { HeadingContainer, PageTitle } from 'Pages/ReportsPage/ReportsPage.style';
import React from 'react';
import { useIntl } from 'react-intl';

interface HeadingContainerProps {
  multivendorOrGlobalMultivendor: boolean;
  selectedVendorId: string;
  manufacturerIds: string;
}

export function HeadingContainerComponent({
  multivendorOrGlobalMultivendor,
  selectedVendorId,
  manufacturerIds,
}: HeadingContainerProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <HeadingContainer>
      <PageTitle>{formatMessage({ id: 'EXTERNAL_REPORTS_PAGE.TITLE' })}</PageTitle>
      {multivendorOrGlobalMultivendor ? (
        <MultiVendorSelectWrapper>
          <CountryMultipleVendorsSelect
            selectedValue={selectedVendorId}
            manufacturerIds={manufacturerIds}
          />
        </MultiVendorSelectWrapper>
      ) : (
        <SelectedVendor />
      )}
    </HeadingContainer>
  );
}
