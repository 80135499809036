import { styled } from '@hexa-ui/theme';

export const ReportDetailsEditButton = styled('button', {
  all: 'unset',
  cursor: 'pointer',
});

export const TableGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '$4',
  gridTemplateAreas: `"reportTitle reportTrakingId reportTabOrder"
                        "workspaceId ReportId ReportId"`,
  justifyItems: 'start',
  width: '80%',
});

export const HeaderWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  paddingBottom: '20px',
  width: '100%',
});

export const StyledIconContainer = styled('div', {
  display: 'flex',
  marginRight: '$1',
  justifyContent: 'flex-end',
});
