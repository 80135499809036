import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getReportsByVendorsService } from './getReportsByVendorsService';
import { TransformedReport } from './getReportsByVendorsService.types';

interface UseReportsByVendorsParams {
  cluster?: string;
  vendorId?: string;
  country?: string;
  isManufacturer?: boolean;
}

export interface ReportsQueryData {
  reports: TransformedReport[];
}

const useReportsByVendors = ({
  cluster,
  vendorId,
  country,
  isManufacturer,
}: UseReportsByVendorsParams): UseQueryResult<ReportsQueryData> =>
  useQuery<ReportsQueryData>({
    queryKey: ['reportByVendors', cluster, vendorId],
    queryFn: () =>
      getReportsByVendorsService({
        cluster: cluster ?? '',
        vendorId: vendorId ?? '',
        country: country ?? '',
        isManufacturer: isManufacturer ?? false,
      }),
    refetchOnWindowFocus: false,
  });

export default useReportsByVendors;
