import { useToggleStates } from 'Hooks/useVendorToggleState/useVendorToggleState';
import { TransformedReport } from 'Services/GetReportsByVendors/getReportsByVendorsService.types';
import React from 'react';
import { useIntl } from 'react-intl';
import CustomTable from '../TableCommon/CustomTable/CustomTable';
import useReports from '../TableCommon/useReports/useReports';

export function TablePartnerAndManager(): React.JSX.Element {
  const { formatMessage } = useIntl();

  const { data, refetch } = useReports();

  const partnerAndManagerReports =
    data?.reports.reduce((acc: TransformedReport[], report: TransformedReport) => {
      if (report.userType.partner || report.userType.manager) {
        acc.push(report);
      }
      return acc;
    }, [] as TransformedReport[]) || [];

  const { toggleCell, isFetching } = useToggleStates(data, refetch);

  const columns = [
    {
      Header: formatMessage({
        id: 'VENDOR_MANAGEMENT_DETAILS_PAGE.TABLE_PARTNER_AND_MANAGER.THEME',
      }),
      accessor: 'reportGroup',
      disableSortBy: true,
      style: { width: '15%' },
    },
    {
      Header: formatMessage({
        id: 'VENDOR_MANAGEMENT_DETAILS_PAGE.TABLE_PARTNER_AND_MANAGER.REPORT',
      }),
      accessor: 'title',
      disableSortBy: true,
      style: { width: '15%' },
    },
    {
      Header: formatMessage({
        id: 'VENDOR_MANAGEMENT_DETAILS_PAGE.TABLE_PARTNER_AND_MANAGER.WORKSPACE_ID',
      }),
      accessor: 'powerBIWorkspaceId',
      disableSortBy: true,
      style: { width: '25%' },
    },
    {
      Header: formatMessage({
        id: 'VENDOR_MANAGEMENT_DETAILS_PAGE.TABLE_PARTNER_AND_MANAGER.REPORT_ID',
      }),
      accessor: 'powerBIReportId',
      disableSortBy: true,
      style: { width: '25%' },
    },
    {
      Header: formatMessage({
        id: 'VENDOR_MANAGEMENT_DETAILS_PAGE.TABLE_PARTNER_AND_MANAGER.PARTNER',
      }),
      accessor: 'enabledInUserType.partner',
      disableSortBy: true,
      Cell: toggleCell,
      style: { width: '10%' },
    },
    {
      Header: formatMessage({
        id: 'VENDOR_MANAGEMENT_DETAILS_PAGE.TABLE_PARTNER_AND_MANAGER.MANAGER',
      }),
      accessor: 'enabledInUserType.manager',
      disableSortBy: true,
      Cell: toggleCell,
      style: { width: '10%' },
    },
  ];

  const removeEmptyData = partnerAndManagerReports.filter((report) => {
    if (report.reportGroup === '') {
      return (report.reportGroup = 'Additional reports');
    }
    if (report.powerBIWorkspaceId === '' && report.powerBIReportId === '') {
      return (report.powerBIWorkspaceId = '-') && (report.powerBIReportId = '-');
    }
    return report;
  });

  return (
    <CustomTable
      titleId="VENDOR_MANAGEMENT_DETAILS_PAGE.PARTNER_AND_MANAGER_TABLE_TITLE"
      columns={columns}
      data={removeEmptyData}
      noDataMessageId="REPORT_ADMIN_PAGE.NO_DATA"
      tableType="partnerAndManager"
      loading={isFetching}
    />
  );
}
