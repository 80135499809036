import ExternalReport from 'Domain/ExternalReport';
import MenuItem from 'Domain/MenuItem';
import { useSidebar } from 'admin-portal-shared-services';
import useSidebarItems from './useSidebarItems';
import useSidebarUtils from './useSidebarUtils';

function Sidebar({
  reportsMenuItems,
  externalReports,
}: {
  reportsMenuItems?: MenuItem[];
  externalReports?: ExternalReport[];
}): null {
  const items = useSidebarItems({ reportsMenuItems, externalReports });
  const utils = useSidebarUtils();

  useSidebar({ items, utils });

  return null;
}

export default Sidebar;
