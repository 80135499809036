import APIEndpoint from 'Api/APIEndpoint';
import Api from 'Api/Api';
import getAPIBaseURL from 'Api/getAPIBaseURL';

interface DeleteRuleParams {
  id: string;
  country: string;
  cluster: string;
}

export const deleteRule = async (params: DeleteRuleParams): Promise<any> => {
  const baseURL = getAPIBaseURL(APIEndpoint.GET_REPORTS_BY_ID, params.country, params.cluster);

  const response = await Api.delete({
    url: `/permissions/${params.id}`,
    baseURL,
  });
  return response.data;
};
