import React from 'react';

export type CellRendererFunc = (cell: unknown) => React.ReactNode;
export type ColumnConfig = {
  header: string;
  accessor: string;
  width: string;
  cellRenderer?: CellRendererFunc;
};

export interface Column {
  Header: string;
  accessor: string;
  disableSortBy: boolean;
  style: { width: string };
  Cell?: CellRendererFunc;
}

export function createColumn({ header, accessor, width, cellRenderer }: ColumnConfig): Column {
  const column: Column = {
    accessor,
    Header: header,
    disableSortBy: true,
    style: { width: `${width}%` },
  };

  if (cellRenderer) {
    column.Cell = cellRenderer;
  }

  return column;
}
