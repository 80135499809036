import { Heading } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const HeadingTitle = styled(Heading, {
  paddingBottom: '$4',
});

export const TextWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const TitleContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const ReportGroupCardContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  gap: '$6',
  paddingTop: '$8',
});

export const StyledErrorWrapper = styled('div', {
  paddingTop: '180px',
});

export const BreadCrumbContainer = styled('div', {
  paddingTop: '$4',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const ClusterContainer = styled('div', {
  marginTop: '-20px',
});
