import { styled } from '@hexa-ui/theme';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$1',
});

const CircleContainer = styled('div', {
  display: 'flex',
});

const Styled = {
  Container,
  CircleContainer,
};

export default Styled;
