import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { useCountry } from 'Hooks/useCountry/useCountry';
import {
  GetVendorsActivationResponse,
  getVendorsActivationService,
} from './getVendorsActivationService';

interface UseVendorsActivationParams {
  country?: string;
  serviceModel?: string;
  cluster?: string;
}

const useVendorsActivation = ({
  country,
  serviceModel,
  cluster,
}: UseVendorsActivationParams): UseQueryResult<GetVendorsActivationResponse[]> => {
  const countryFromHook = useCountry();
  return useQuery({
    queryKey: ['vendors', country || countryFromHook, cluster],
    queryFn: () =>
      getVendorsActivationService({
        country: country || countryFromHook,
        cluster,
        serviceModel,
      }),
    refetchOnWindowFocus: false,
  });
};

export default useVendorsActivation;
