import { Button, Error403, Heading } from '@hexa-ui/components';
import { FEATURE_ACTIVATION_MANAGER, FEATURE_SETUP_MANAGER } from 'Assets/Constants';
import Loading from 'Components/Loading/Loading';
import { useClusterContext } from 'Context/ClusterContext/ClusterContext';
import { useCountry } from 'Hooks/useCountry/useCountry';
import useUserScopes from 'Hooks/useUserScopes/useUserScopes';
import { useReportPermissions } from 'Services/GetReportPermissions/useReportPermissions';
import { hasAdminPermission } from 'Utils/hasAdminPermission/hasAdminPermission';
import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import AddNewRuleModal from './AddNewRuleModal/AddNewRuleModal';
import {
  ReportRulesContainer,
  ReportRulesHeaderContainer,
  StyledErrorWrapper,
} from './ReportRules.style';
import { ReportRulesTable } from './Table/ReportRulesTable';

export function ReportRules(): React.JSX.Element {
  const { reportId: reportIdFromUrl } = useParams<{
    reportId: string;
  }>();

  const location = useLocation();

  const country = useCountry();
  const { cluster } = useClusterContext();

  const { isManufacturer } = location.state || {};

  const { formatMessage } = useIntl();
  const { data, isLoading, refetch } = useReportPermissions(
    reportIdFromUrl,
    isManufacturer,
    country,
    cluster
  );
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const userScopes = useUserScopes();

  const handleOpenNewRuleModal = () => setIsOpen(true);
  const handleCloseNewRuleModal = () => setIsOpen(false);

  const hasSetupPermission = hasAdminPermission(userScopes, FEATURE_SETUP_MANAGER);
  const hasActivationPermission = hasAdminPermission(userScopes, FEATURE_ACTIVATION_MANAGER);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {hasSetupPermission || hasActivationPermission ? (
        <ReportRulesContainer data-testid="report-rules">
          <ReportRulesHeaderContainer>
            <Heading size="H4">
              {formatMessage({ id: 'REPORT_ADMIN_PAGE.REPORT_DETAILS.RULES.TITLE' })}
            </Heading>
            {hasSetupPermission && (
              <Button onClick={handleOpenNewRuleModal} data-testid="add-new-rule">
                {formatMessage({ id: 'REPORT_ADMIN_PAGE.REPORT_DETAILS.RULES.ADD_NEW' })}
              </Button>
            )}
          </ReportRulesHeaderContainer>
          {isLoading && !data ? (
            <Loading container />
          ) : (
            <ReportRulesTable permissionsData={data} refetch={refetch} />
          )}
          <AddNewRuleModal
            isOpen={isOpen}
            onClose={handleCloseNewRuleModal}
            isLoading={false}
            data={data}
            refetch={refetch}
          />
        </ReportRulesContainer>
      ) : (
        <StyledErrorWrapper>
          <Error403
            header={formatMessage({ id: 'UNAUTHORIZED.ERROR_TITLE' })}
            description={formatMessage({ id: 'UNAUTHORIZED.ERROR_DESCRIPTION' })}
            hasButton={false}
          />
        </StyledErrorWrapper>
      )}
    </>
  );
}
