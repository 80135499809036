import APIEndpoint from 'Api/APIEndpoint';
import Api from 'Api/Api';
import getAPIBaseURL from 'Api/getAPIBaseURL';
import { ReportDataBody } from 'Components/AddNewReportModal/AddNewReportModal.types';

export const editReportService = async (
  data: ReportDataBody,
  country: string,
  cluster: string,
  id: string | undefined
  // eslint-disable-next-line max-params
): Promise<{ id: string }> => {
  try {
    const baseURL = getAPIBaseURL(APIEndpoint.ADD_NEW_REPORT, country, cluster);

    const response = await Api.put({
      url: `/activation/reports/${id}`,
      body: data,
      baseURL,
    });

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
