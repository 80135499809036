import { Button, Error403, Heading } from '@hexa-ui/components';
import { FEATURE_SETUP_MANAGER } from 'Assets/Constants';
import ClusterDropdown from 'Components/ClusterDropdown/ClusterDropdown';
import DefaultContainerView from 'Components/DefaultContainer/DefaultContainerView';
import PageContainer from 'Components/PageContainer/PageContainer';
import Sidebar from 'Components/Sidebar/Sidebar';
import useUserScopes from 'Hooks/useUserScopes/useUserScopes';
import { VendorTable } from 'Pages/VendorManagementPage/components/VendorManagementTable/Table/VendorTable';
import { hasAdminPermission } from 'Utils/hasAdminPermission/hasAdminPermission';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  HeadingTitle,
  StyledErrorWrapper,
  SubtitleWrapper,
  TitleContainer,
} from './VendorRegistrationPage.style';
import NewVendorModal from './components/NewVendorModal/NewVendorModal';

export function VendorRegistrationPage(): React.JSX.Element {
  const { formatMessage } = useIntl();
  const userScopes = useUserScopes();
  const hasSetupPermission = hasAdminPermission(userScopes, FEATURE_SETUP_MANAGER);
  const [isOpen, setIsOpen] = useState(Boolean);

  /* istanbul ignore next */
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {hasSetupPermission ? (
        <PageContainer maxWidth="xl">
          <Sidebar />
          <TitleContainer>
            <HeadingTitle size="H1">
              {formatMessage({ id: 'REPORT_ADMIN_PAGE.DATA_ANALYTICS_TITLE' })}
            </HeadingTitle>
            <ClusterDropdown
              label={formatMessage({ id: 'ACTIVATION_PAGE.CLUSTER_SELECT.LABEL' })}
            />
          </TitleContainer>
          <DefaultContainerView
            dataTest="VendorRegistrationPage"
            css={{
              minHeight: '80vh',
              justifyContent: 'start !important',
              padding: '$8',
            }}
          >
            <TitleContainer>
              <Heading size="H2">{formatMessage({ id: 'VENDOR_REGISTRATION_PAGE.TITLE' })}</Heading>
            </TitleContainer>
            <SubtitleWrapper>
              <Heading size="H4">
                {formatMessage({ id: 'VENDOR_REGISTRATION_PAGE.SUBTITLE' })}
              </Heading>
              <Button
                onClick={() => setIsOpen(true)}
                variant="secondary"
                data-testid="add-new-vendor-button"
              >
                {formatMessage({ id: 'VENDOR_REGISTRATION_PAGE.ADD_BUTTON' })}
              </Button>
              <NewVendorModal isOpen={isOpen} onClose={() => setIsOpen(false)} isLoading={false} />
            </SubtitleWrapper>
            <VendorTable activation />
          </DefaultContainerView>
        </PageContainer>
      ) : (
        <StyledErrorWrapper>
          <Error403
            header={formatMessage({ id: 'UNAUTHORIZED.ERROR_TITLE' })}
            description={formatMessage({ id: 'UNAUTHORIZED.ERROR_DESCRIPTION' })}
            hasButton={false}
          />
        </StyledErrorWrapper>
      )}
    </>
  );
}
