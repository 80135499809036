import { styled } from '@hexa-ui/theme';

export const ReportDetailsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left !important',
});

export const StyledIconContainer = styled('div', {
  display: 'flex',
  marginRight: '$1',
  justifyContent: 'flex-end',
});

export const TableExpandlableStyles = styled('div', {
  th: {
    background: 'var(--colors-brandAccentNeutralBasis)',
    paddingLeft: '60px',
  },

  td: {
    paddingLeft: '60px',
  },

  variants: {
    empty: {
      true: {
        'table tbody tr td div': {
          height: 'unset',
        },
        'table tr td:first-child p': {
          fontWeight: 'normal',
        },
      },
    },
  },
});
