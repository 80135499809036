import { Paragraph } from '@hexa-ui/components';
import React from 'react';
import { useIntl } from 'react-intl';

import CustomInput from 'Components/CustomInput/CustomInput';
import { FormContainerItemAddNewReport, ItemsContainer } from './AddNewReportForm.style';
import IsManufactureToggle from './IsManufactureToggle/IsManufactureToggle';

export default function AddNewReportForm({
  isEditing = false,
}: {
  isEditing?: boolean;
}): React.ReactElement {
  const { formatMessage } = useIntl();

  return (
    <FormContainerItemAddNewReport>
      <Paragraph size="small" weight="semibold">
        {formatMessage({ id: 'ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.TITLE' })}
      </Paragraph>
      <ItemsContainer>
        <CustomInput
          name="title.en-US"
          hint="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.TITLE_HINT_US"
          placeholderId="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.TITLE_US"
          errorId="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.ERRORS.REQUIRED"
        />
        <CustomInput
          name="title.es-419"
          hint="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.TITLE_HINT_ES"
          placeholderId="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.TITLE_ES"
          errorId="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.ERRORS.REQUIRED"
        />
        <CustomInput
          name="title.pt-BR"
          hint="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.TITLE_HINT_PT"
          placeholderId="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.TITLE_PT"
          errorId="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.ERRORS.REQUIRED"
        />
        <CustomInput
          name="title.id-ID"
          hint="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.TITLE_HINT_ID"
          placeholderId="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.TITLE_ID"
          errorId="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.ERRORS.REQUIRED"
        />
      </ItemsContainer>

      <ItemsContainer>
        <CustomInput
          label="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.TRACKING_ID"
          name="trackingId"
          placeholderId="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.TRACKING_ID_PLACEHOLDER"
          errorId="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.ERRORS.REQUIRED"
        />
        <CustomInput
          label="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.WORKSPACE_ID"
          name="powerBIWorkspaceId"
          placeholderId="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.WORKSPACE_PLACEHOLDER"
          errorId="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.ERRORS.REQUIRED"
        />
        <CustomInput
          label="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.REPORT_ID"
          name="powerBIReportId"
          placeholderId="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.REPORT_ID_PLACEHOLDER"
          errorId="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.ERRORS.REQUIRED"
        />
        <CustomInput
          label="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.TAB_ORDER"
          name="order"
          placeholderId="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.TAB_ORDER_PLACEHOLDER"
          errorId="ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.ERRORS.REQUIRED"
          isNumber
        />
        {!isEditing && <IsManufactureToggle />}
      </ItemsContainer>
    </FormContainerItemAddNewReport>
  );
}
