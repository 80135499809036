/* eslint-disable react/jsx-no-useless-fragment */
import { Button, Error403, Error500, Heading, Paragraph } from '@hexa-ui/components';
import { useQuery } from '@tanstack/react-query';
import { FEATURE_SETUP_MANAGER } from 'Assets/Constants';
import AddNewThemeModal from 'Components/AddNewThemeModal/AddNewThemeModal';
import { BreadCrumb } from 'Components/BreadCrumb/BreadCrumb';
import ClusterDropdown from 'Components/ClusterDropdown/ClusterDropdown';
import DefaultContainerView from 'Components/DefaultContainer/DefaultContainerView';
import Loading from 'Components/Loading/Loading';
import PageContainer from 'Components/PageContainer/PageContainer';
import { ReportGroupCard } from 'Components/ReportGroupCard/ReportGroupCard';
import {
  ReportGroupItem,
  ReportGroupsResponse,
} from 'Components/ReportGroupCard/ReportGroupCard.types';
import Sidebar from 'Components/Sidebar/Sidebar';
import { useClusterContext } from 'Context/ClusterContext/ClusterContext';
import { useCountry } from 'Hooks/useCountry/useCountry';
import useUserScopes from 'Hooks/useUserScopes/useUserScopes';
import { getAllReportGroups } from 'Services/ReportGroupActivation/ReportGroupActivation';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { hasAdminPermission } from '../../utils/hasAdminPermission/hasAdminPermission';
import {
  HeadingTitle,
  ReportGroupCardContainer,
  StyledErrorWrapper,
  TextWrapper,
  TitleContainer,
} from './ReportsManagementPage.style';

export function ReportsManagementPage(): React.JSX.Element {
  const { formatMessage } = useIntl();
  const { cluster } = useClusterContext();
  const [content, setContent] = React.useState<ReportGroupsResponse | null>(null);
  const [isNewThemeModalOpen, setIsNewThemeModalOpen] = useState<boolean>(false);
  const defaultCountry = useCountry();

  const userScopes = useUserScopes();

  const hasSetupPermission = hasAdminPermission(userScopes, FEATURE_SETUP_MANAGER);

  /* istanbul ignore next */
  const handleOnError = () => {
    setContent(null);
  };

  const {
    isLoading,
    error,
    refetch: refetchGetAllReportGroups,
  } = useQuery({
    queryKey: ['getAllReportGroups'],
    queryFn: () => getAllReportGroups({ country: defaultCountry }, cluster),
    onSuccess: (response) => {
      setContent(response.data);
    },

    onError: handleOnError,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    refetchGetAllReportGroups();
  }, [cluster, refetchGetAllReportGroups]);

  const toggleModal = useCallback(() => {
    setIsNewThemeModalOpen((prev) => !prev);
  }, []);

  return (
    <>
      {hasSetupPermission ? (
        <PageContainer maxWidth="xl">
          <Sidebar />
          <TitleContainer>
            <HeadingTitle size="H1">
              {formatMessage({ id: 'REPORT_ADMIN_PAGE.DATA_ANALYTICS_TITLE' })}
            </HeadingTitle>
            <ClusterDropdown
              label={formatMessage({ id: 'ACTIVATION_PAGE.CLUSTER_SELECT.LABEL' })}
            />
          </TitleContainer>
          <BreadCrumb />
          <DefaultContainerView
            dataTest="ReportsManagementPage"
            css={{
              minHeight: '80vh',
              justifyContent: 'start !important',
              padding: '$8',
            }}
          >
            <TitleContainer>
              <TextWrapper>
                <Heading size="H2">
                  {formatMessage({ id: 'REPORT_ADMIN_PAGE.THEME_TITLE' })}
                </Heading>
                <Paragraph>{formatMessage({ id: 'REPORT_ADMIN_PAGE.THEME_SUBTITLE' })}</Paragraph>
              </TextWrapper>
              {hasSetupPermission && (
                <Button onClick={toggleModal} data-testid="toggle-modal-button">
                  {formatMessage({ id: 'ACTIVATION_PAGE.THEME_ADD' })}
                </Button>
              )}
            </TitleContainer>
            {error ||
              /* istanbul ignore next */
              (content?.reportGroups.length === 0 && (
                <StyledErrorWrapper>
                  <Error500
                    headerText={
                      error
                        ? formatMessage({ id: 'REPORT_ADMIN_PAGE.SOMETHING_WRONG' })
                        : formatMessage({ id: 'REPORT_ADMIN_PAGE.NO_DATA' })
                    }
                  />
                </StyledErrorWrapper>
              ))}
            {!isLoading ? (
              <ReportGroupCardContainer>
                {content?.reportGroups.map((reportGroup: ReportGroupItem) => (
                  <ReportGroupCard
                    key={reportGroup.id}
                    title={reportGroup.title['en-US']}
                    reportCount={reportGroup.reportCount}
                    personas={reportGroup.personas}
                    iconString={reportGroup?.iconString}
                    enabled={reportGroup.enabled}
                    id={reportGroup.id}
                  />
                ))}
              </ReportGroupCardContainer>
            ) : (
              <Loading />
            )}
          </DefaultContainerView>
          <AddNewThemeModal
            refetchGetAllReportGroups={refetchGetAllReportGroups}
            isOpen={isNewThemeModalOpen}
            onClose={toggleModal}
            isLoading={false}
          />
        </PageContainer>
      ) : (
        <StyledErrorWrapper>
          <Error403
            header={formatMessage({ id: 'UNAUTHORIZED.ERROR_TITLE' })}
            description={formatMessage({ id: 'UNAUTHORIZED.ERROR_DESCRIPTION' })}
            hasButton={false}
          />
        </StyledErrorWrapper>
      )}
    </>
  );
}
