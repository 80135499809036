/* eslint-disable no-unused-expressions */
import AppModal from 'Components/AppModal/AppModal';
import { useClusterContext } from 'Context/ClusterContext/ClusterContext';
import ReportGroupById, { ViewMode } from 'Domain/ReportGroupById';
import { useCountry } from 'Hooks/useCountry/useCountry';
import { addNewReportGroupService } from 'Services/ReportGroupActivation/ReportGroupActivation';
import { updateReportGroupService } from 'Services/UpdateReportGroup/updateReportGroupService';
import { createSlug } from 'Utils/createSlug/createSlug';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import AddNewThemeForm from './AddNewThemeForm/AddNewThemeForm';

import { ContainerItemAddNewTheme } from './AddNewThemeModal.style';
import { AddNewThemeModalProps } from './AddNewThemeModal.types';

export default function AddNewThemeModal({
  isOpen,
  onClose,
  isLoading,
  editingTheme,
  onUpdate,
  refetchGetAllReportGroups,
}: AddNewThemeModalProps & { editingTheme?: ReportGroupById }): React.ReactElement {
  const { cluster } = useClusterContext();
  const country = useCountry();
  const methods = useForm<ReportGroupById>({
    mode: 'onChange',
    defaultValues: editingTheme || {
      id: '',
      iconString: undefined,
      slug: '',
      trackingId: '',
      title: {
        'en-US': '',
        'es-419': '',
        'pt-BR': '',
        'id-ID': '',
      },
      order: 0,
      viewMode: ViewMode.VIEW,
      enabled: true,
    },
  });

  const { formatMessage } = useIntl();
  const successStatus = 200;
  const typeError = TypeToast.ERROR;
  const toastService = useToast();

  const onCloseModal = () => {
    onClose();
    methods.reset();
  };

  useEffect(() => {
    if (editingTheme) {
      methods.reset(editingTheme);
    }
  }, [editingTheme, methods]);

  /* istanbul ignore next */
  const handleToast = (type: TypeToast, message: string) => {
    toastService.notify({
      type,
      message,
    });
  };

  /* istanbul ignore next */
  const handleAddNewTheme = async (data: ReportGroupById) => {
    try {
      await addNewReportGroupService(data, country, cluster);
      handleToast(TypeToast.SUCCESS, formatMessage({ id: 'TOAST.SUCCESS' }));
      onClose();
      refetchGetAllReportGroups && refetchGetAllReportGroups();
    } catch (error: any) {
      /* istanbul ignore next */
      if (error.response && error.response.status !== successStatus) {
        handleToast(typeError, formatMessage({ id: 'TOAST.ERROR' }));
      }
    }
  };

  const handleEditTheme = async (data: ReportGroupById) => {
    try {
      await updateReportGroupService(editingTheme?.id || '', data, cluster, country);
      handleToast(TypeToast.SUCCESS, formatMessage({ id: 'TOAST.SUCCESS' }));
      onUpdate?.(data);
      onClose();
      refetchGetAllReportGroups && refetchGetAllReportGroups();
    } catch (error: any) {
      /* istanbul ignore next */
      if (error.response && error.response.status !== successStatus) {
        handleToast(typeError, formatMessage({ id: 'TOAST.ERROR' }));
      }
    }
  };

  /* istanbul ignore next */
  const onSubmit = (data: ReportGroupById) => {
    const slug = createSlug(data.trackingId);
    const newData = { ...data, slug };
    if (editingTheme) {
      handleEditTheme(data);
    } else {
      handleAddNewTheme(newData);
    }
  };

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onCloseModal}
      buttonConfirmLabel={
        editingTheme
          ? 'ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.EDIT'
          : 'ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.CONFIRM'
      }
      buttonCancelLabel="ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.CANCEL"
      isLoading={isLoading}
      css={{ '@tablet': { minWidth: '800px' } }}
      dataTestId="add-new-theme-modal"
      title={
        editingTheme
          ? 'ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.MODAL_EDIT_TITLE'
          : 'ACTIVATION_PAGE.ADD_NEW_THEME_MODAL.MODAL_TITLE'
      }
      onCancelButton={onCloseModal}
      onConfirmButton={methods.handleSubmit(onSubmit)}
      disableConfirmButton={!methods.formState.isValid}
    >
      <ContainerItemAddNewTheme>
        <FormProvider {...methods}>
          <AddNewThemeForm />
        </FormProvider>
      </ContainerItemAddNewTheme>
    </AppModal>
  );
}
