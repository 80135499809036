import APIEndpoint from 'Api/APIEndpoint';
import Api from 'Api/Api';
import getAPIBaseURL from 'Api/getAPIBaseURL';
import { SUCCESS_CODE } from 'Assets/Constants';
import { IRequestBuildCSV } from './buildCSV.d';

const buildCSV = async (requestBuildCSV: IRequestBuildCSV): Promise<string | undefined> => {
  const { onSuccess, onFail, startDate, vendorId, csvExportCode, preferredLanguage, country } =
    requestBuildCSV;

  const baseURL = getAPIBaseURL(APIEndpoint.BUILD_CSV, country);

  try {
    const response = await Api.post({
      params: { vendorId },
      body: {
        csvExportCode,
        startDate,
        preferredLanguage,
      },
      headers: {
        country,
      },
      url: `/exporting-process`,
      baseURL,
    });
    if (SUCCESS_CODE.includes(response.status)) {
      onSuccess(response.data);
      return undefined;
    }

    onFail();
  } catch (error) {
    onFail();
  }

  return undefined;
};

export default buildCSV;
