import APIEndpoint from 'Api/APIEndpoint';
import Api from 'Api/Api';
import getAPIBaseURL from 'Api/getAPIBaseURL';
import AddNewVendor from 'Domain/AddNewVendor';

export const addNewVendorService = async (
  data: AddNewVendor,
  cluster: string,
  country: string
  // eslint-disable-next-line max-params
): Promise<AddNewVendor> => {
  const baseURL = getAPIBaseURL(APIEndpoint.ADD_NEW_VENDOR, country, cluster);

  const response = await Api.post({
    url: `/activation/vendors`,
    body: data,
    baseURL,
  });

  return response.data;
};
