/* eslint-disable @typescript-eslint/no-unused-vars */
import { Heading, Paragraph, Toggle } from '@hexa-ui/components';
import React from 'react';
import { Avatar } from '../Avatar/Avatar';
import { CardHeaderContainer, CardHeaderWrapper } from './CardHeader.styles';

interface CardHeaderProps {
  reportsLength: number;
  reportName: string;
  reportIcon: any;
  setEnabledToggle: React.Dispatch<React.SetStateAction<boolean>>;
  enabledToggle: boolean;
}

export function CardHeader({
  reportsLength,
  reportName,
  reportIcon,
  setEnabledToggle,
  enabledToggle,
}: CardHeaderProps): React.JSX.Element {
  /* istanbul ignore next */
  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setEnabledToggle((prev) => !prev);
  };
  return (
    <CardHeaderContainer data-testid="card-header">
      <CardHeaderWrapper>
        <Avatar type="GROUP_ICON" Icon={reportIcon} />
        <div>
          <Heading size="H4">{reportName}</Heading>
          <Paragraph>
            {reportsLength} {reportsLength === 1 ? 'report' : 'reports'}
          </Paragraph>
        </div>
      </CardHeaderWrapper>
      <Toggle
        style={{ display: 'none' }} // TO-DO REMOVE THIS STYLE WHEN THE FEATURE IS ENABLED
        onClick={handleToggle}
        checked={enabledToggle}
        name="enableReport"
        disabled
        data-testid="card-header-toggle"
      />
    </CardHeaderContainer>
  );
}
