import React, { SVGProps } from 'react';

function KPIDocumentationEmptyStateImage(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={187} height={202} fill="none" {...props}>
      <g clipPath="url(#a)">
        <path
          d="M80.0531 9.92716L20.64 41.9503C11.9105 46.6554 6.46777 55.772 6.46777 65.6887L6.46777 136.114C6.46777 145.803 11.6658 154.748 20.0843 159.545L79.1557 193.203C87.5105 197.963 97.7682 197.915 106.078 193.076L168.137 156.933C176.431 152.103 181.532 143.228 181.532 133.63L181.532 65.9226C181.532 55.8825 175.954 46.6737 167.056 42.0231L105.339 9.76606C97.4061 5.6198 87.9327 5.68015 80.0531 9.92716Z"
          fill="white"
          stroke="#F0ECFC"
          strokeWidth="11.9853"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.1163 107.522L89.4983 112.913L82.3221 120.1L76.9401 114.71L84.1163 107.522Z"
          fill="#E2D246"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.322 105.725L85.9105 109.319L78.7343 116.507L75.1458 112.912L82.322 105.725Z"
          fill="#F0E44C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.1143 118.313L80.5258 114.718L73.3488 118.313L80.5258 121.907C81.517 122.9 83.1231 122.9 84.1143 121.907C85.1055 120.915 85.1055 119.305 84.1143 118.313Z"
          fill="#F0E44C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.526 118.313C81.5172 117.32 81.5172 115.711 80.526 114.719L76.9375 111.124C75.9463 110.132 74.3402 110.132 73.349 111.124C72.3578 112.117 72.3578 113.726 73.349 114.719V118.313H76.9375C77.9287 119.306 79.5348 119.306 80.526 118.313Z"
          fill="#FFF578"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.2928 111.125L87.7042 107.53L84.1157 111.125L87.7042 114.719C88.6954 115.712 90.3016 115.712 91.2928 114.719C92.284 113.726 92.284 112.117 91.2928 111.125Z"
          fill="#F0E44C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.703 111.124C88.6942 110.132 88.6942 108.523 87.703 107.53L84.1145 103.936C83.1233 102.943 81.5172 102.943 80.526 103.936C79.5348 104.929 79.5348 106.537 80.526 107.53L84.1145 111.124C85.1057 112.117 86.7119 112.117 87.703 111.124Z"
          fill="#FFF578"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.5254 121.908L76.9368 118.313L53.5055 141.782C56.4783 144.76 61.2983 144.76 64.2711 141.782L80.5254 125.502C81.5166 124.51 81.5166 122.9 80.5254 121.908Z"
          fill="#CBCBCB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.6832 138.188L76.9375 121.908C77.9287 120.915 77.9287 119.306 76.9375 118.313L73.349 114.719C72.3578 113.726 70.7509 113.726 69.7605 114.719L53.5062 130.999C50.5326 133.977 50.5326 138.805 53.5062 141.782C54.4966 142.775 57.7105 141.165 60.6832 138.188Z"
          fill="#DDDCDC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M107.441 59.8032C122.857 59.8032 135.354 72.3205 135.354 87.7607C135.354 103.202 122.857 115.718 107.441 115.718C92.0252 115.718 79.5278 103.202 79.5278 87.7607C79.5278 72.3205 92.0252 59.8032 107.441 59.8032Z"
          fill="#9993E1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M107.441 64.8867V110.636C120.054 110.636 130.279 100.395 130.279 87.7608C130.279 75.1279 120.054 64.8867 107.441 64.8867Z"
          fill="#DDDCDC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M125.204 87.7608C125.204 75.1279 117.251 64.8867 107.44 64.8867C94.8276 64.8867 84.6027 75.1279 84.6027 87.7608C84.6027 100.394 94.8276 110.635 107.44 110.635C117.251 110.635 125.204 100.394 125.204 87.7608Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M117.591 85.2197C118.992 85.2197 120.128 86.3574 120.128 87.761C120.128 89.1647 118.992 90.3031 117.591 90.3031C116.189 90.3031 115.053 89.1647 115.053 87.761C115.053 86.3574 116.189 85.2197 117.591 85.2197Z"
          fill="#5F538F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M107.441 85.2197C108.842 85.2197 109.978 86.3574 109.978 87.761C109.978 89.1647 108.842 90.3031 107.441 90.3031C106.039 90.3031 104.904 89.1647 104.904 87.761C104.904 86.3574 106.039 85.2197 107.441 85.2197Z"
          fill="#5F538F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.2912 85.2197C98.6926 85.2197 99.8284 86.3574 99.8284 87.761C99.8284 89.1647 98.6926 90.3031 97.2912 90.3031C95.8897 90.3031 94.7539 89.1647 94.7539 87.761C94.7539 86.3574 95.8897 85.2197 97.2912 85.2197Z"
          fill="#5F538F"
        />
        <path
          d="M152.685 70.6173C154.099 61.6758 148.008 53.2794 139.08 51.8635C130.153 50.4476 121.77 56.5484 120.356 65.49C118.942 74.4316 125.034 82.828 133.961 84.2439C142.888 85.6597 151.271 79.5589 152.685 70.6173Z"
          fill="#E2D246"
        />
        <path
          d="M150.086 70.3058C152.163 61.4954 146.716 52.6668 137.92 50.5864C129.124 48.5061 120.309 53.9618 118.232 62.7721C116.155 71.5824 121.602 80.4111 130.398 82.4914C139.195 84.5718 148.009 79.1161 150.086 70.3058Z"
          fill="#F0E44C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.948 72.3297L138.115 66.487L143.948 60.6444L140.222 56.9116L134.388 62.7542L128.555 56.9116L124.828 60.6444L130.661 66.487L124.828 72.3297L128.555 76.0624L134.388 70.2198L140.222 76.0624L143.948 72.3297Z"
          fill="white"
        />
        <path
          d="M140.261 56.8016L134.875 62.1953L135.678 62.9986L141.063 57.605L140.261 56.8016Z"
          fill="#DDDCDC"
        />
        <path
          d="M135.682 70.5668L135.224 71.0259L140.265 76.0753L140.724 75.6162L135.682 70.5668Z"
          fill="#DDDCDC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M126.124 72.6542L131.958 66.8116L126.124 60.969L129.365 57.7231L128.555 56.9116L124.828 60.6444L130.661 66.487L124.828 72.3297L128.555 76.0624L129.041 75.5756L126.124 72.6542Z"
          fill="#DDDCDC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M134.388 62.754L135.685 63.0786L134.875 62.2671L134.388 62.754Z"
          fill="#DDDCDC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M134.388 70.2197L135.685 70.5443L135.199 71.0312L134.388 70.2197Z"
          fill="#DDDCDC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M124.828 72.3296L126.124 72.6542L129.041 75.5755L128.555 76.0624L124.828 72.3296Z"
          fill="#424242"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M124.828 60.6445L126.124 60.9691L131.958 66.8117L130.661 66.4872L124.828 60.6445Z"
          fill="#424242"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h187v202H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default KPIDocumentationEmptyStateImage;
