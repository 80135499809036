import {
  BarChart,
  HelpCircle,
  List,
  SellerManagement,
  Star,
  Store2Edit,
  World,
} from '@hexa-ui/icons';
import ExternalLink from 'Assets/Icons/ExternalLink.svg';
import SvgRender from 'Components/SvgRender/SvgRender';
import React from 'react';

/* istanbul ignore next */
export const Icons: Record<string, () => React.ReactElement> = {
  Star: () => <Star />,
  BarChart: () => <BarChart />,
  World: () => <World />,
  ExternalLink: () => <SvgRender svg={ExternalLink} />,
  KPIDocumentation: () => <HelpCircle />,
  Store: () => <SellerManagement />,
  Store2Edit: () => <Store2Edit />,
  List: () => <List />,
};
