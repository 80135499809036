import MessageMap from 'I18n/i18n';

const id: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only Indonesian option available',
  },

  REPORTS_PAGE: {
    REPORTS_PAGE_TITLE: 'Data dan analitik',
    REPORTS_PAGE_VENDOR: 'Vendor',
    CONTENT_REPORTS_TAB_VIEW:
      'Measure your performance across all delivered orders within the selected timeframe.',
  },

  GLOBAL_OVERVIEW_PAGE: {
    GLOBAL_OVERVIEW_PAGE_TITLE: 'Ikhtisar global',
  },

  CONTAINER_REPORTS: {
    CONTAINER_ERROR: 'Terjadi kesalahan',
    CONTAINER_ERROR_LABEL: 'Mohon maaf, kami mengalami masalah teknis. Cobalah lagi.',
  },

  SELECT_RANGE_DATE_CSV_MODAL: {
    TITLE: 'Ekspor file CSV',
    DESCRIPTION: 'Pilih data yang ingin Anda ekspor.',
    YEAR_LABEL: 'Tahun',
    MONTH_LABEL: 'Bulan',
    NO_INFO_AVAILABLE: 'Tidak ada informasi untuk periode yang dipilih. Cobalah tanggal yang lain.',
    CANCEL: 'Batalkan',
    DOWNLOAD: 'Ekspor',
  },

  BECOME_PREMIUM_MODAL: {
    TITLE: 'Business insights taken to the next level',
    FIRST_DESCRIPTION: 'Get access to CSV export and many other features to improve your analysis.',
    BUTTON_BECOME: 'Become a Gold Partner',
    BUTTON_LATER: 'Later',
    MODAL_STYLE: 'EN',
  },

  CSV_DOWNLOAD_SELECT: {
    PLACEHOLDER: 'Ekspor file',
  },

  CSV_SILVER_MODAL: {
    TITLE: 'Your free trial will expire soon',
    FIRST_DESCRIPTION:
      'This CSV you are about to download is a courtesy from our membership program.',
    SECOND_DESCRIPTION:
      'To keep access to special insights tailored for your company in the future, you’ll have to upgrade to Gold Partner.',
    BUTTON: 'DOWNLOAD FILE',
    NO_INFO_AVAILABLE: "There's no information for the period.",
  },

  HELP_CENTER_MODAL: {
    TITLE: 'Cara mengakses file CSV',
    DESCRIPTION:
      'Kurang familier dengan file CSV? Bacalah panduan langkah demi langkah untuk menggunakan file CSV.',
    LINK: 'Tampilkan panduan file CSV',
    PROCEED_BUTTON: 'Lanjut mengunduh',
  },

  GET_STARTED: {
    LOADING: 'Memuat',
    TITLE: 'Memulai',
    PARAGRAPH: 'Pilih vendor untuk melihat data dan wawasan mereka.',
    NEXT: 'Berikutnya',
  },

  SELECTED_VENDOR: {
    CHANGE: 'Ubah',
    TITLE: 'You are viewing',
  },

  SELECT_TOOLTIP: {
    OPEN: 'Open',
    CLEAR: 'Clear',
  },

  MONTH: {
    1: 'Januari',
    2: 'Februari',
    3: 'Maret',
    4: 'April',
    5: 'Mei',
    6: 'Juni',
    7: 'Juli',
    8: 'Agustus',
    9: 'September',
    10: 'Oktober',
    11: 'November',
    12: 'Desember',
  },

  COMMON: {
    CANCEL: 'Batalkan',
    CONFIRM: 'Confirm',
  },
  LOADING: {
    TITLE: 'Memuat',
    PARAGRAPH: 'Kami sedang menyiapkan halaman untuk Anda.',
  },
  SELECT_VENDOR: {
    TITLE: 'Pilih vendor',
  },
  UNAUTHORIZED: {
    ERROR_TITLE: 'Hmm… Akses ditolak',
    ERROR_DESCRIPTION: 'Sepertinya Anda tidak memiliki izin untuk mengakses halaman ini',
  },
  EXPORT_FILE: {
    SOMETHING_WRONG: 'Something went wrong. Please try again.',
    PREPARING_DOWNLOAD: 'Mempersiapkan file CSV untuk diunduh.',
    SUCCESS_DOWNLOAD: 'Unduhan berhasil diselesaikan',
  },
  // NEED COPY DECK
  BECOME_PREMIUM: {
    PAGE_TITLE: 'Become Premium',
    TITLE_NORMAL: `We've got a plan\nthat's perfect for`,
    TITLE_HIGHLIGHTED: 'your business',
    SUBTITLE: 'Power up your analysis with the right membership for you company.',
    SILVER_PARTNER_CARD: {
      TITLE: 'Silver Partner',
      DESCRIPTION: 'For businesses starting in the digital marketplace world.',
      BIG_TEXT: 'Basic',
      BUTTON_LABEL: 'Current plan',
    },
    GOLD_PARTNER_CARD: {
      TITLE: 'Gold Partner',
      DESCRIPTION:
        'For businesses with advanced data intelligence teams that want to invest in targeted audiences.',
      BIG_TEXT: 'Premium',
      BUTTON_LABEL: 'Contact us',
    },
    METRICS_TABLE: {
      HEADER: {
        METRICS: 'Metrics',
        SILVER: 'Silver',
        GOLD: 'Gold',
      },
      ROWS: {
        UNITS_DELIVERED: 'Units Delivered',
        ORDERS_DELIVERED: 'Orders Delivered',
        BUYERS: 'Pembeli',
        UNIT_DELIVERED_BY_PRODUCT: 'Unit Delivered by Product',
        UNITS_DELIVERED_BY_CITY_AND_STATE: 'Units Delivered by City and State',
        UNITS_SOLD_BY_DISTRIBUTION_CENTER: 'Units Sold by Distribution Center',
        TOP_5_PRODUCTS: 'Top 5 Products',
        MONTHLY_EVOLUTION_OF_ORDERS: 'Monthly evolution of Orders',
        REVENUE: 'Pendapatan',
        AVERAGE_REVENUE_PER_ORDER: 'Average Revenue per Order',
        AVERAGE_REVENUE_PER_BUYER: 'Average Revenue per Buyer',
        REVENUE_BY_REGION: 'Revenue by Region',
        MONTHLY_SALES_REVENUE: 'Monthly Sales Revenue',
        CURRENT_MONTH_VS_LAST_MONTH: 'Current Month vs Last Month',
        ORDERS_VS_REVENUE_STATE: 'Orders vs Revenue/State',
        ORDERS_VS_REVENUE_SEGMENT: 'Orders vs Revenue/Segment',
        ORDERS_VS_REVENUE_PRODUCT: 'Orders vs Revenue/Product',
        REVENUE_BY_PRODUCT: 'Revenue by Product',
        REVENUE_BY_SEGMENT: 'Revenue by Segment',
        REVENUE_BY_CHANNEL: 'Revenue by Channel',
      },
    },
    CSV_DOWNLOAD_TABLE: {
      HEADER: {
        CSV_DOWNLOAD: 'CSV Download',
        SILVER: 'Silver',
        GOLD: 'Gold',
      },
      ROWS: {
        DATE: 'Date',
        GROSS_VALUE: 'Gross Value',
        REGION_STATE_AND_CITY: 'Region (State and City)',
        DISTRIBUTION_CENTER: 'Distribution Center',
        SEGMENT: 'Segment',
        SUB_SEGMENT: 'Sub-Segment',
        CHANNEL: 'Saluran',
      },
    },
  },
  REVENUE_SILVER_PARTNER: {
    TITLE: 'Business insights taken to the',
    TITLE_NEXT_LEVEL: 'next level',
    DESCRIPTION:
      'With our premium insights you can discover powerful analysis that will unleash all the potential of your business.',
    BUTTON: 'Become a Gold Partner',
  },
  // NEED COPYDECK
  BADGES: {
    EXCLUSIVE_FOR_GOLD_PARTNER: 'Exclusive for Gold Partners',
  },
  // NEED COPYDECK
  TIER_IDENTIFICATION: {
    GOLD: 'Gold Partner',
    SILVER: 'Silver Partner',
  },
  CONTACT_US: {
    TOAST_SUCCESS: 'Request successfully sent! Our team will contact you shortly.',
    TOAST_ERROR: 'Something went wrong. Please try again.',
  },
  COUNTRIES: {
    AR: 'Argentina',
    BO: 'Bolivia',
    BR: 'Brasil',
    CL: 'Chili',
    CO: 'Kolumbia',
    DO: 'Republik Dominika',
    EC: 'Ekuador',
    ID: 'Indonesia',
    MX: 'Meksiko',
    PA: 'Panama',
    PE: 'Peru',
    PH: 'Filipina',
    PY: 'Paraguay',
    ZA: 'Afrika Selatan',
    GLOBAL_OVERVIEW: 'Ikhtisar global',
  },
  COUNTRY_SELECT: {
    PLACEHOLDER: 'Pilih negara',
  },
  SERVICE_MODELS: {
    '1P': '1P',
    '3P-3L-SS': '3P Pro',
    '3P-3L-SF': 'DTaaS',
    '3P': 'DTaaS',
  },
  SERVICE_MODEL_SELECT: {
    PLACEHOLDER: 'Pilih model bisnis',
  },
  VENDOR_SELECT: {
    PLACEHOLDER: 'Pilih vendor',
    LOADING: 'Memuat...',
  },

  EMBED_REPORT: {
    LOADING_EMBED_REPORT: {
      TITLE: 'Mengambil data',
      BODY: 'Langkah 1 dari 3',
    },

    LOADING_POWERBI: {
      TITLE: 'Memuat laporan',
      BODY: 'Langkah 2 dari 3',
    },

    LOADING_DATA: {
      TITLE: 'Hampir selesai',
      BODY: 'Langkah 3 dari 3',
    },
  },

  EXTERNAL_REPORTS_PAGE: {
    TITLE: 'Laporan & dokumentasi tambahan',
    DESCRIPTION: 'Akses laporan tambahan dari produk BEES lain.',
  },

  KPI_DOCUMENTATION_PAGE: {
    TITLE: 'Memahami KPI kita',
    SUBTITLE:
      'Laman ini menjelaskan pengertian KPI kita (indikator kinerja utama) dan cara menghitung KPI. Untuk mencari KPI tertentu, gunakan bilah pencarian atau navigasi melalui tab di bawah.',
    COLUMNS: {
      NAME: 'Nama KPI',
      DESCRIPTION: 'Deskripsi',
      METHODOLOGY: 'Metodologi perhitungan',
    },
    EMPTY_STATE: 'Oops, sorry! Tidak ada hasil yang ditemukan.',
    SEARCH_FIELD: 'Cari berdasarkan KPI atau kata kunci',
    SEARCH_RESULTS: {
      EMPTY_STATE_TITLE: 'Tidak ada hasil yang ditemukan.',
      EMPTY_STATE_TEXT: 'Periksa ejaan Anda atau coba gunakan istilah pencarian lain.',
      COUNT_RESULT_MESSAGE: ' hasil untuk ',
      UNIT_RESULT_MESSAGE: '1 hasil untuk ',
    },
  },

  REPORT_ADMIN_PAGE: {
    DATA_ANALYTICS_TITLE: 'Data & Analytics Admin',
    THEME_TITLE: 'Theme',
    THEME_SUBTITLE: 'These themes will be used to create the UX menu navigation options',
    DTAAS: 'DTaas',
    SOMETHING_WRONG: 'Something went wrong. Please try again.',
    NO_DATA: 'Sorry, no results found.',

    REPORT_DETAILS: {
      HEADER: {
        TITLE: 'Title',
        TRACKING_ID: 'Tracking ID',
        TAB_ORDER: 'Tab order',
        WORKSPACE_ID: 'Workspace ID',
        REPORT_ID: 'Report ID',
      },
      RULES: {
        TITLE: 'Rules',
        ADD_NEW: 'Add new rule',
        USER_TYPE: 'User type',
        BUSINESS_MODEL: 'Business model',
        ACTIONS: 'Actions',
        VENDOR: 'Vendor',
        ACTIVE: 'Active',
      },
    },
  },

  REPORT_VENDOR_ADMIN_PAGE: {
    VENDOR_MANAGEMENT: {
      HEADER: {
        TITLE: 'Vendor management',
        SUBTITLE: 'DTaaS and SaaS Vendors',
        ADD_NEW_BUTTON: 'Add new',
      },
      TABLE_ITEMS: {
        VENDOR_NAME: 'Name',
        VENDOR_ID: 'VendorID',
        ACTIONS: 'Actions',
      },
    },
    VENDOR_MANAGEMENT_DETAILS: {
      HEADER: {
        TITLE: 'Vendor management',
        SUBTITLE: 'DTaaS and SaaS Vendors',
        ADD_NEW_BUTTON: 'Add new',
      },
      TABLE_ITEMS: {
        DTAAS_REPORTS: 'DTaaS Reports',
        SALES_MANAGER_REPORTS: 'Sales Manager Reports',
        VENDOR_NAME: 'Name',
        VENDOR_ID: 'VendorID',
        ACTIONS: 'Actions',
      },
    },
  },

  REPORT_DETAILS_PAGE: {
    THEME_NAME: 'Theme name',
    STRUCTURE: 'Structure',
    TRACKING_ID: 'Tracking ID',
    ORDER: 'Order',
    REPORTS: 'Reports',
    REPORT: 'Report',
    WORKSPACE_ID: 'Workspace ID',
    REPORT_ID: 'Report ID',
    RLS_RULE: 'RLS rule',
    ADD_NEW: 'Add new',
  },

  REPORT_NEW_RULE_MODAL: {
    ADD_NEW_RULE: 'Add new rule',
    USER_TYPE: 'User type',
    SELECT_USER_TYPE: 'Select user type',
    BUSINESS_MODEL: 'Business model',
    BUSINESS_PLACEHOLDER: 'Type business model',
    CANCEL: 'Cancel',
    ADD: 'Add',
  },

  ACTIVATION_PAGE: {
    CLUSTER_SELECT: {
      LABEL: 'Select a cluster',
      GLOBAL_US: 'Global US',
      GLOBAL_EU: 'Global EU',
      SELECTED_CLUSTER: 'Selected cluster',
    },
    THEME_ADD: 'Add new theme',
    REPORT_ADD: 'Add new report',
    ADD_NEW_THEME_MODAL: {
      MODAL_TITLE: 'Add new theme',
      MODAL_EDIT_TITLE: 'Edit theme',
      NAME: 'Name',
      NAME_US: 'Enter a US name',
      NAME_PT: 'Enter a PT name',
      NAME_ES: 'Enter a ES name',
      NAME_ID: 'Enter a ID name',
      NAME_HINT_US: 'en-US',
      NAME_HINT_PT: 'pt-BR',
      NAME_HINT_ES: 'es-419',
      NAME_HINT_ID: 'id-ID',
      ICON: 'Icon',
      ICONS_PLACEHOLDER: 'Select an icon',
      PREVIEW_ICON: 'Preview',
      STRUCTURE: 'Structure',
      STRUCTURE_TAB: 'Tab',
      STRUCTURE_TOGGLE: 'Toggle',
      SLUG: 'Slug',
      SLUG_PLACEHOLDER: 'Enter a slug',
      TRACKING_ID: 'Tracking ID',
      TRACKING_ID_PLACEHOLDER: 'Enter a tracking ID',
      ORDER: 'Order',
      ORDER_PLACEHOLDER: 'Enter an order',
      CANCEL: 'Cancel',
      CONFIRM: 'Add theme',
      ERRORS: {
        REQUIRED: 'This field is required.',
      },
    },
    ADD_NEW_REPORT_MODAL: {
      MODAL_TITLE: 'Add new report',
      MODAL_EDIT_TITLE: 'Edit report informations',
      TITLE: 'Title',
      TITLE_US: 'Enter a US title',
      TITLE_PT: 'Enter a PT title',
      TITLE_ES: 'Enter a ES title',
      TITLE_HINT_US: 'en-US',
      TITLE_HINT_PT: 'pt-BR',
      TITLE_HINT_ES: 'es-419',
      TRACKING_ID: 'Tracking ID',
      TRACKING_ID_PLACEHOLDER: 'Enter a tracking ID',
      WORKSPACE_ID: 'Workspace ID',
      WORKSPACE_PLACEHOLDER: 'Enter a Workspace ID',
      REPORT_ID: 'Report ID',
      REPORT_ID_PLACEHOLDER: 'Enter a Report ID',
      TAB_ORDER: 'Tab order',
      TAB_ORDER_PLACEHOLDER: 'Enter an Tab order',
      USERTYPE: 'User type',
      USERTYPE_PLACEHOLDER: 'Select user type',
      BUSINESS_MODEL: 'Business model',
      BUSINESS_MODEL_PLACEHOLDER: 'Enter a Business model',
      CANCEL: 'Cancel',
      CONFIRM: 'Add',
      EDIT: 'Edit',
      ERRORS: {
        REQUIRED: 'This field is required.',
      },
      IS_MANUFACTURER: 'Manufacturer',
    },
  },
  VENDOR_MANAGEMENT_DETAILS_PAGE: {
    PARTNER_AND_MANAGER_TABLE_TITLE: 'DTaaS Reports',
    SALES_MANAGER_TABLE_TITLE: 'Sales Manager Reports',
    TABLE_PARTNER_AND_MANAGER: {
      THEME: 'Theme',
      REPORT: 'Report',
      WORKSPACE_ID: 'Workspace Id',
      REPORT_ID: 'Report Id',
      PARTNER: 'Partner',
      MANAGER: 'Manager',
      SALES_MANAGER: 'Sales Manager',
    },
    TABLE_SALES_MANAGER: {
      THEME: 'Theme',
      REPORT: 'Report',
      WORKSPACE_ID: 'Workspace Id',
      REPORT_ID: 'Report Id',
      PARTNER: 'Partner',
      MANAGER: 'Manager',
      SALES_MANAGER: 'Sales Manager',
    },
  },
  THEMES_LIST: {
    TITLE: 'Themes List',
  },
  VENDORS_LIST: {
    TITLE: 'Vendors List',
  },

  WORKSPACES: {
    TITLE: 'Workspaces',
    ADD_NEW: 'Add new',
    PAGE_TITLE: 'Data & Analytics Admin',
    CARD: {
      ACCESS_RULES: 'Access rules',
      USER: 'User',
      USERS: 'Users',
    },
  },
};

export default id;
