export enum UserType {
  MANAGER = 'Manager',
  GOLD_PARTNER = 'Gold Partner',
  SILVER_PARTNER = 'Silver Partner',
  GLOBAL_PARTNER = 'Global Partner',
  SALES_MANAGER = 'Sales Manager',
  UNAUTHORIZED = '',
  LOADING = 'Loading',
}

export const CODE_200 = 200;
export const CODE_204 = 204;
export const CODE_202 = 202;
export const SUCCESS_CODE = [CODE_200, CODE_202, CODE_204];

export const MFE_NAME = '/reports';

export const FEATURE_TOGGLE_V2_PROJECT_NAME = 'bees-hub-reports-mfe';

export const GOLD_PARTNER_SCOPE = 'TIER.t1csvexport.read';

export const MANAGER_1P_SCOPE = 'PartnerDashboard.InsightsManager1P.Read';
export const MANAGER_3PPRO_SCOPE = 'PartnerDashboard.InsightsManager3PPRO.Read';
export const MANAGER_DTAAS_SCOPE = 'PartnerDashboard.InsightsManagerDTAAS.Read';

export const MANAGER_SCOPES = [MANAGER_1P_SCOPE, MANAGER_3PPRO_SCOPE, MANAGER_DTAAS_SCOPE];

export const PARTNER_SCOPE = 'PartnerDashboard.DB.Read';

export const GLOBAL_PARTNER_SCOPES = [
  PARTNER_SCOPE,
  'PartnerDashboard.InsightsGlobalManagerViewer.Read',
];

export const EU_CLUSTER_COUNTRIES = ['PH', 'ID'];
export const GLOBAL_OVERVIEW = 'GLOBAL_OVERVIEW';

export const SALES_MANAGER_SCOPE = 'PartnerDashboard.SalesManagerViewer.Read';

const FEATURE_ACTIVATION_READ = 'PartnerDashboard.FeatureActivation.Read';

export const FEATURE_ACTIVATION_WRITE = ['PartnerDashboard.FeatureActivation.Write'];

export const FEATURE_SETUP_MANAGER = [
  FEATURE_ACTIVATION_READ,
  'PartnerDashboard.FeatureActivation.Write',
  'PartnerDashboard.FeatureActivation.Update',
  'PartnerDashboard.FeatureActivation.Delete',
];

export const FEATURE_ACTIVATION_MANAGER = [
  FEATURE_ACTIVATION_READ,
  'PartnerDashboard.FeatureActivation.Update',
];

export const WORKSPACES_SCOPES = [FEATURE_ACTIVATION_READ];
