import { Heading, Tooltip } from '@hexa-ui/components';
import React from 'react';
import Styled from './Circle.style';
import { CircleProps } from './Circle.types';

function Circle({
  flag = '',
  name,
  additionalCount = 0,
  style: customStyle,
}: CircleProps): React.ReactElement {
  const getInitials = (name: string) =>
    name
      .split(' ')
      .slice(0, 2)
      .map((word) => word[0])
      .join('');

  const sanitizedFlag = encodeURI(flag);
  const styles = {
    background: additionalCount ? 'white' : flag || 'yellow',
    backgroundImage: flag ? `url(${sanitizedFlag})` : 'none',
    ...customStyle,
  };

  const content = (
    <Styled.Wrapper css={styles} data-testid="circle">
      <Heading size="H5">
        {additionalCount ? `+${additionalCount}` : !flag && getInitials(name)}
      </Heading>
    </Styled.Wrapper>
  );

  if (additionalCount > 0) return content;

  return (
    <Tooltip placement="bottom" text={name}>
      {content}
    </Tooltip>
  );
}

export default Circle;
