import { useQuery, UseQueryResult } from '@tanstack/react-query';
import EmbedReport from 'Domain/EmbedReport';
import { useCountry } from 'Hooks/useCountry/useCountry';
import getEmbedReportService from './getEmbedReportService';

interface UseEmbedReportParams {
  vendorId: string;
  lang: string;
  reportCode: string;
  globalOverview?: boolean;
  country?: string;
}

const useEmbedReport = ({
  vendorId,
  lang,
  reportCode,
  globalOverview,
  country,
}: UseEmbedReportParams): UseQueryResult<EmbedReport> => {
  const defaultCountry = useCountry();

  return useQuery({
    queryKey: ['embedReport', vendorId, lang, reportCode, country],
    queryFn: () =>
      getEmbedReportService({
        vendorId,
        lang,
        reportCode,
        globalOverview,
        country: country || defaultCountry,
      }),
    refetchOnWindowFocus: false,

    cacheTime: 0,
  });
};

export default useEmbedReport;
