import { Error500, IconButton, Table } from '@hexa-ui/components';
import { Edit2, Trash2 } from '@hexa-ui/icons';
import Loading from 'Components/Loading/Loading';
import MFERoutes from 'Config/MFERoutes';
import { useClusterContext } from 'Context/ClusterContext/ClusterContext';
import { useCountry } from 'Hooks/useCountry/useCountry';
import DeleteVendorModal from 'Pages/VendorRegistrationPage/components/DeleteVendorModal/DeleteVendorModal';
import NewVendorModal from 'Pages/VendorRegistrationPage/components/NewVendorModal/NewVendorModal';
import useVendorsActivation from 'Services/GetVendorsActivation/useVendorsActivation';
import { watchCustomEvent } from 'Utils/customEvents/customEventWatchDispatch';
import { customEventsCommonConsts } from 'Utils/customEvents/customEventsCommonConsts';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { TableContainer } from './VendorTable.style';

export function VendorTable({ activation }: { activation?: boolean }): React.JSX.Element {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(Boolean);
  const [isDelete, setIsDelete] = useState(Boolean);

  const [selectedVendor, setSelectedVendor] = useState({
    vendorId: '',
    name: '',
    isManufacturer: false,
  });
  const { cluster } = useClusterContext();
  const defaultCountry = useCountry();
  const history = useHistory();
  const { data, isLoading, refetch } = useVendorsActivation({
    country: defaultCountry,
    cluster,
  });

  useEffect(() => {
    watchCustomEvent(customEventsCommonConsts.REFETCH_GET_ALL_VENDORS, refetch);
  }, [data, refetch]);

  const isVendorManagementPage = history.location.pathname === MFERoutes.REPORTS_VENDOR_MANAGEMENT;

  /* istanbul ignore next */
  const handleCloseModal = () => setIsOpen(false);
  const handleDeleteModal = () => setIsDelete(false);

  // eslint-disable-next-line max-params
  const handleRowClick = (vendorId: string, vendorName: string, isManufacturer: boolean) => {
    history.push({
      pathname: `${MFERoutes.REPORTS_VENDOR_MANAGEMENT}/${vendorId}`,
      state: { vendorName, isManufacturer },
    });
  };

  const onRow = (row: { id: string; name: string; isManufacturer: boolean }) => {
    if (isVendorManagementPage) {
      return {
        onClick: () => handleRowClick(row.id, row.name, row.isManufacturer),
      };
    }
    return {};
  };

  // eslint-disable-next-line max-params
  const handleEdit = (vendorId: string, vendorName: string, isManufacturer: boolean) => {
    setSelectedVendor({ vendorId, isManufacturer, name: vendorName });
    setIsOpen(true);
  };

  // eslint-disable-next-line max-params
  const handleDelete = (vendorId: string, vendorName: string, isManufacturer: boolean) => {
    setSelectedVendor({ vendorId, isManufacturer, name: vendorName });
    setIsDelete(true);
  };

  const cellEdit = (cellInfo: any) =>
    activation && (
      <IconButton
        icon={Edit2}
        variant="secondary"
        size="small"
        onClick={() =>
          handleEdit(
            cellInfo?.row?.original?.id,
            cellInfo?.row?.original?.name,
            cellInfo?.row?.original?.isManufacturer
          )
        }
      />
    );

  const cellDelete = (cellInfo: any) =>
    activation && (
      <IconButton
        icon={Trash2}
        onClick={() =>
          handleDelete(
            cellInfo?.row?.original?.id,
            cellInfo?.row?.original?.name,
            cellInfo?.row?.original?.isManufacturer
          )
        }
        variant="secondary"
        size="small"
      />
    );

  const cellShowManufacture = (cellInfo: any) =>
    cellInfo.row.original.isManufacturer === true
      ? formatMessage({ id: 'REPORT_ADMIN_PAGE.REPORT_DETAILS.TABLE.MANUFACTURER' })
      : formatMessage({ id: 'REPORT_ADMIN_PAGE.REPORT_DETAILS.TABLE.SELLER' });

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      disableSortBy: true,
      style: { width: '35%' },
    },
    {
      Header: 'Vendor Id',
      accessor: 'id',
      disableSortBy: true,
      style: { width: '35%' },
    },
    {
      Header: '',
      accessor: 'isManufacturer',
      disableSortBy: true,
      style: { width: '10%' },
      Cell: cellShowManufacture,
    },
    {
      accessor: 'edit',
      disableSortBy: true,
      style: { width: '4%' },
      Cell: cellEdit,
    },
    {
      accessor: 'delete',
      disableSortBy: true,
      style: { width: '4%' },
      Cell: cellDelete,
    },
  ];

  return (
    <TableContainer data-testid="vendor-table">
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <Table
            columns={columns}
            data={data || []}
            onRow={onRow}
            search
            emptyMessage={
              <div style={{ width: '420px' }}>
                <Error500 headerText={formatMessage({ id: 'REPORT_ADMIN_PAGE.SOMETHING_WRONG' })} />
              </div>
            }
          />
          <NewVendorModal
            isOpen={isOpen}
            onClose={handleCloseModal}
            isLoading={false}
            editingVendor={selectedVendor}
          />
          <DeleteVendorModal
            isOpen={isDelete}
            onClose={handleDeleteModal}
            isLoading={false}
            selectedVendorId={selectedVendor.vendorId}
          />
        </>
      )}
    </TableContainer>
  );
}
