import { styled } from '@hexa-ui/theme';

export const TableContainer = styled('div', {
  paddingBottom: '2rem',
});

export const TableStyles = styled('div', {
  boxShadow: '$2',
  borderRadius: '$4',

  'td, th': {
    padding: '$4 $8',
    lineHeight: '20px',
    textAlign: 'left',
  },

  p: {
    fontSize: '14px',
    lineHeight: '20px',
  },

  'tr:last-child': {
    borderBottom: 'none',
  },

  'table tr td:first-child p': {
    fontWeight: 600,
  },

  'tr:hover': { backgroundColor: 'rgba(242, 242, 242, 0.5)' },

  th: {
    background: '#F2F2F2',
  },

  table: {
    borderRadius: '$4',
    overflow: 'hidden',
  },

  variants: {
    empty: {
      true: {
        'table tbody tr td div': {
          height: 'unset',
        },
        'table tr td:first-child p': {
          fontWeight: 'normal',
        },
      },
    },
  },
});

export const StyledIconContainer = styled('div', {
  display: 'flex',
  marginRight: '$1',
  justifyContent: 'flex-end',
});
