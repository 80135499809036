import { Heading, Paragraph } from '@hexa-ui/components';
import { CSS } from '@stitches/react/types/css-util';
import KPIDocumentationPageIcon from 'Assets/Icons/KPIDocumentationPageIcon';
import {
  Container,
  HeaderContainer,
  IconContainer,
  TitleContainer,
} from 'Components/KPIDocumentationPageHeader/KPIDocumentationPageHeader.style';
import React from 'react';
import { useIntl } from 'react-intl';

function KPIDocumentationPageHeader({ css }: { css: CSS }): React.ReactElement {
  const { formatMessage } = useIntl();

  return (
    <Container css={css} data-testid="kpi-documentation-header">
      <HeaderContainer>
        <IconContainer>
          <KPIDocumentationPageIcon />
        </IconContainer>

        <TitleContainer>
          <Heading size="H1" css={{ marginBottom: '$2' }}>
            {formatMessage({ id: 'KPI_DOCUMENTATION_PAGE.TITLE' })}
          </Heading>
          <Paragraph>{formatMessage({ id: 'KPI_DOCUMENTATION_PAGE.SUBTITLE' })}</Paragraph>
        </TitleContainer>
      </HeaderContainer>
    </Container>
  );
}

export default KPIDocumentationPageHeader;
