import { Select } from '@hexa-ui/components';
import useSupportedServiceModels from 'Hooks/useSupportedServiceModels/useSupportedServiceModels';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import GetStartedSelect from 'Components/GetStartedSelect/GetStartedSelect';

interface ServiceModelSelectProps {
  onChange: (value: string) => void;
  selectedValue: string;
}

function ServiceModelSelect({
  onChange,
  selectedValue,
}: ServiceModelSelectProps): React.ReactElement {
  const { formatMessage } = useIntl();

  const serviceModels = useSupportedServiceModels();

  const options = useMemo(
    () =>
      serviceModels
        .map((value) => ({ value, label: formatMessage({ id: `SERVICE_MODELS.${value}` }) }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [formatMessage, serviceModels]
  );

  return (
    <GetStartedSelect
      onChange={onChange}
      placeholder={formatMessage({ id: 'SERVICE_MODEL_SELECT.PLACEHOLDER' })}
      value={selectedValue}
      dataTestId="service-model-select"
    >
      {options.map(({ value, label }) => (
        <Select.Option data-testid={`service-model-select-${value}`} key={value} value={value}>
          {label}
        </Select.Option>
      ))}
    </GetStartedSelect>
  );
}

export default ServiceModelSelect;
