import { Card as _Card } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const ReportGroupContainer = styled(_Card, {
  minWidth: '480px',
  height: '180px',
  padding: '$4',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '$neutral20',
    transition: 'background-color 0.3s ease',
  },
});

export const PersonasWrapper = styled('div', {
  display: 'flex',
  gap: '$2',
  alignItems: 'center',
  flexWrap: 'wrap',
});
