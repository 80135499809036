import { Paragraph } from '@hexa-ui/components';
import KPIDocumentationSearchField from 'Components/KPIDocumentationSearchField/KPIDocumentationSearchField';
import KPIDocumentationTable from 'Components/KPIDocumentationTable/KPIDocumentationTable';
import KPIDocumentationTabs from 'Components/KPIDocumentationTabs/KPIDocumentationTabs';
import { Kpi } from 'Domain/KPI';
import MenuItem from 'Domain/MenuItem';
import useAppEvents from 'Hooks/segment/useAppEvents/useAppEvents';
import { useQueryParam } from 'Hooks/useQueryParam/useQueryParam';
import kpiMap from 'Pages/KPIDocumentationPage/kpiMap/kpiMap';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { SearchFieldContainer } from './KPIDocumentationSearchResults.style';

interface KPIDocumentationSearchResultsProps {
  menuItems: MenuItem[];
  selectedMenuItem: MenuItem;
}
function KPIDocumentationSearchResults({
  menuItems,
  selectedMenuItem,
}: KPIDocumentationSearchResultsProps): React.ReactElement {
  const searchQuery = useQueryParam('search');
  const { locale, defaultLocale, formatMessage } = useIntl();
  const { triggerSearchInteraction } = useAppEvents();

  const { results, resultCount } = useMemo(() => {
    const result = { results: [] as [string, Kpi[]][], resultCount: 0 };

    if (searchQuery != null) {
      for (const kpis of Object.entries(kpiMap[selectedMenuItem.slug])) {
        const filteredKPIs = kpis[1]
          .getKPIS(locale, defaultLocale)
          .filter((kpi) =>
            [kpi.name, kpi.description, kpi.methodology].some((text) =>
              text.toLowerCase().includes(searchQuery.toLowerCase())
            )
          )
          .sort((kpiA, kpiB) => kpiA.name.localeCompare(kpiB.name));

        const filteredCategory = kpis[1].getCategory(locale, defaultLocale);
        if (filteredKPIs.length > 0) {
          result.results.push([filteredCategory, filteredKPIs]);
        }

        result.resultCount += filteredKPIs.length;
      }
    }

    return result;
  }, [defaultLocale, locale, searchQuery]);

  useEffect(() => {
    if (searchQuery) triggerSearchInteraction(searchQuery, resultCount);
  }, [searchQuery, resultCount]);

  return (
    <div data-testid="kpi-search-results">
      <KPIDocumentationTabs menuItems={menuItems} selectedMenuItem={selectedMenuItem} />
      <SearchFieldContainer>
        <KPIDocumentationSearchField key={selectedMenuItem.slug} />
      </SearchFieldContainer>
      {resultCount > 0 && (
        <Paragraph
          data-testid="kpi-search-results-count-message"
          css={{ marginBottom: '$4', strong: { fontWeight: '500' } }}
        >
          {resultCount === 1
            ? formatMessage({
                id: 'KPI_DOCUMENTATION_PAGE.SEARCH_RESULTS.UNIT_RESULT_MESSAGE',
              })
            : `${resultCount} ${formatMessage({
                id: 'KPI_DOCUMENTATION_PAGE.SEARCH_RESULTS.COUNT_RESULT_MESSAGE',
              })}`}
          <strong> &quot;{searchQuery}&quot; </strong>
        </Paragraph>
      )}

      {resultCount < 1 && (
        <>
          <KPIDocumentationTable
            isSearchable
            kpis={[]}
            emptyStateTitle={formatMessage({
              id: 'KPI_DOCUMENTATION_PAGE.SEARCH_RESULTS.EMPTY_STATE_TITLE',
            })}
            emptyStateText={formatMessage({
              id: 'KPI_DOCUMENTATION_PAGE.SEARCH_RESULTS.EMPTY_STATE_TEXT',
            })}
          />
        </>
      )}

      {results &&
        results.map(([categoryTitle, kpis]) => {
          return (
            <div key={categoryTitle}>
              <KPIDocumentationTable kpis={kpis} categoryTitle={categoryTitle} isSearchable />
            </div>
          );
        })}
    </div>
  );
}

export default KPIDocumentationSearchResults;
