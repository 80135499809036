import { Select } from '@hexa-ui/components';
import { Permission } from 'Components/AddNewReportModal/AddNewReportModal.types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { SelectContainer, SelectDropdown } from './BusinessModelSelect.style';

export default function BusinessModelSelect(): React.ReactElement {
  const { control } = useFormContext<Permission>();
  const { formatMessage } = useIntl();

  const options = ['1P', '3P', '3P-3L-SS', '3P-3L-SF'];

  return (
    <Controller
      name="businessModel"
      control={control}
      rules={{
        required: true,
      }}
      render={({ field: { onChange, value } }) => (
        <SelectContainer>
          <SelectDropdown>
            <Select.Root
              data-testid="select-business-model"
              label={formatMessage({ id: 'ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.BUSINESS_MODEL' })}
              placeholder={formatMessage({
                id: 'ACTIVATION_PAGE.ADD_NEW_REPORT_MODAL.BUSINESS_MODEL_PLACEHOLDER',
              })}
              onChange={onChange}
              value={value}
            >
              {options.map((option) => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select.Root>
          </SelectDropdown>
        </SelectContainer>
      )}
    />
  );
}
