import useSelectedTab from 'Hooks/useSelectedTab/useSelectedTab';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import React from 'react';
import { PowerBIReportEvent } from './usePowerBIReport.types';

interface ReportEventState {
  firstRender: boolean;
  time: {
    init: number;
    dataLoaded: number;
    interaction: number;
    renderingStarted: number;
  };
}

const initialEventRef: ReportEventState = {
  firstRender: false,
  time: {
    init: 0,
    dataLoaded: 0,
    interaction: 0,
    renderingStarted: 0,
  },
};

const usePowerBIReportEvents = (): {
  trackReportEvent: (eventName: PowerBIReportEvent) => void;
} => {
  const { selectedVendor } = useSelectedVendor();
  const { reportCode } = useSelectedTab();
  const eventsRef = React.useRef<ReportEventState>({ ...initialEventRef });

  const trackReportEvent = (eventName: PowerBIReportEvent) => {
    if (eventName === PowerBIReportEvent.INIT) {
      eventsRef.current.firstRender = false;
      eventsRef.current.time = { ...initialEventRef.time, init: Date.now() };
      return;
    }

    if (eventName === PowerBIReportEvent.INTERACTION) {
      eventsRef.current.time.interaction = Date.now();
      return;
    }

    const eventInfo = {
      name: eventName,
      data: {
        report: reportCode,
        company: selectedVendor?.data?.name || '',
        time: 0,
      },
    };

    if (eventName === PowerBIReportEvent.DATA_LOADED) {
      eventsRef.current.time.dataLoaded = Date.now();
      eventInfo.data.time = eventsRef.current.time.dataLoaded - eventsRef.current.time.init;
    }

    if (eventName === PowerBIReportEvent.RENDERING_STARTED) {
      eventsRef.current.time.renderingStarted = Date.now();
      eventInfo.data.time =
        eventsRef.current.time.renderingStarted - eventsRef.current.time.dataLoaded;
    }

    if (eventName === PowerBIReportEvent.REPORT_RENDERED) {
      if (!eventsRef.current.firstRender) {
        eventsRef.current.firstRender = true;
        eventInfo.data.time = Date.now() - eventsRef.current.time.renderingStarted;
      } else {
        eventInfo.name = PowerBIReportEvent.INTERACTION_RENDERED;
        if (eventsRef.current.time.interaction === 0) return;

        eventInfo.data.time = Date.now() - eventsRef.current.time.interaction;
        eventsRef.current.time.interaction = 0;
      }
    }

    window.newrelic?.addPageAction(eventInfo.name, eventInfo.data);
  };

  return { trackReportEvent };
};

export default usePowerBIReportEvents;
