import { Heading, Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const HeadingTitle = styled(Heading, {
  paddingTop: '$4',
  paddingBottom: '$4',
});

export const TitleContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const ReportDetailsParagraph = styled(Paragraph, {
  fontWeight: '$medium',
  fontSize: '$4',
  paddingBottom: '$4',

  span: {
    fontWeight: '$semibold',
  },
});

export const StyledErrorWrapper = styled('div', {
  paddingTop: '180px',
});
