/* eslint-disable react/jsx-no-useless-fragment */
import { Divider, Error403, Heading } from '@hexa-ui/components';
import { FEATURE_ACTIVATION_MANAGER, FEATURE_SETUP_MANAGER } from 'Assets/Constants';
import { BreadCrumb } from 'Components/BreadCrumb/BreadCrumb';
import ClusterDropdown from 'Components/ClusterDropdown/ClusterDropdown';
import DefaultContainerView from 'Components/DefaultContainer/DefaultContainerView';
import Loading from 'Components/Loading/Loading';
import PageContainer from 'Components/PageContainer/PageContainer';
import Sidebar from 'Components/Sidebar/Sidebar';
import { useClusterContext } from 'Context/ClusterContext/ClusterContext';
import { useCountry } from 'Hooks/useCountry/useCountry';
import useUserScopes from 'Hooks/useUserScopes/useUserScopes';
import useReportsByVendors from 'Services/GetReportsByVendors/useReportsByVendors';
import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import { hasAdminPermission } from '../../utils/hasAdminPermission/hasAdminPermission';
import {
  HeadingTitle,
  StyledErrorWrapper,
  TextWrapper,
  TitleContainer,
  VendorManagementDetailsContainer,
} from './VendorManagementDetails.style';
import { TablePartnerAndManager } from './components/TablePartnerAndManager/TablePartnerAndManager';
import { TableSalesManager } from './components/TableSalesManager/TableSalesManager';

export function VendorManagementDetails(): React.JSX.Element {
  const { formatMessage } = useIntl();
  const userScopes = useUserScopes();
  const defaultCountry = useCountry();
  const { cluster } = useClusterContext();

  const { vendorId: vendorIdFromURL } = useParams<{
    vendorId?: string;
  }>();

  const location = useLocation();

  const { isManufacturer } = location.state || {};

  const { isLoading } = useReportsByVendors({
    cluster,
    isManufacturer,
    vendorId: vendorIdFromURL,
    country: defaultCountry,
  });
  const hasSetupPermission = hasAdminPermission(userScopes, FEATURE_SETUP_MANAGER);
  const hasActivationPermission = hasAdminPermission(userScopes, FEATURE_ACTIVATION_MANAGER);

  return (
    <>
      {hasSetupPermission || hasActivationPermission ? (
        <PageContainer maxWidth="xl">
          <Sidebar />
          <TitleContainer>
            <HeadingTitle size="H1">
              {formatMessage({ id: 'REPORT_ADMIN_PAGE.DATA_ANALYTICS_TITLE' })}
            </HeadingTitle>
            <ClusterDropdown
              label={formatMessage({ id: 'ACTIVATION_PAGE.CLUSTER_SELECT.SELECTED_CLUSTER' })}
              disabled
            />
          </TitleContainer>
          <BreadCrumb isReportsAdminPage={false} />
          <DefaultContainerView
            dataTest="VendorManagementDetailsPage"
            css={{
              minHeight: '80vh',
              justifyContent: 'start !important',
              padding: '$8',
            }}
          >
            <TitleContainer>
              <TextWrapper>
                <Heading size="H2">
                  {formatMessage({
                    id: 'REPORT_VENDOR_ADMIN_PAGE.VENDOR_MANAGEMENT_DETAILS.HEADER.TITLE',
                  })}
                </Heading>
              </TextWrapper>
            </TitleContainer>
            {isLoading && (
              <VendorManagementDetailsContainer>
                <Loading />
              </VendorManagementDetailsContainer>
            )}
            {!isLoading && (
              <VendorManagementDetailsContainer>
                <TablePartnerAndManager />
                <Divider css={{ margin: '$12' }} />
                <TableSalesManager />
              </VendorManagementDetailsContainer>
            )}
          </DefaultContainerView>
        </PageContainer>
      ) : (
        <StyledErrorWrapper>
          <Error403
            header={formatMessage({ id: 'UNAUTHORIZED.ERROR_TITLE' })}
            description={formatMessage({ id: 'UNAUTHORIZED.ERROR_DESCRIPTION' })}
            hasButton={false}
          />
        </StyledErrorWrapper>
      )}
    </>
  );
}
