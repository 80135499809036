/* eslint-disable arrow-body-style */
/* eslint-disable react/no-unstable-nested-components */
import { Error500, IconButton, LoadingDots, Table, Toggle } from '@hexa-ui/components';
import { Trash2 } from '@hexa-ui/icons';
import { useIsFetching } from '@tanstack/react-query';
import { FEATURE_SETUP_MANAGER } from 'Assets/Constants';
import { useClusterContext } from 'Context/ClusterContext/ClusterContext';
import { useCountry } from 'Hooks/useCountry/useCountry';
import useUserScopes from 'Hooks/useUserScopes/useUserScopes';
import { ToggleStates } from 'Pages/VendorManagementDetailsPage/VendorManagementDetails.types';
import { updateVendorsReportById } from 'Services/UpdateVendorsReportById/UpdateVendorsReportById';
import { dispatchCustomEvent, watchCustomEvent } from 'Utils/customEvents/customEventWatchDispatch';
import { customEventsCommonConsts } from 'Utils/customEvents/customEventsCommonConsts';
import { hasAdminPermission } from 'Utils/hasAdminPermission/hasAdminPermission';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import DeleteRuleModal from '../../DeleteRuleModal/DeleteRuleModal';
import { TableExpandlableStyles } from './ReportRulesTable.style';
import { ReportRulesTableProps, ToggleCellProps, VendorProps } from './ReportRulesTable.types';

export function ReportRulesTable({
  permissionsData,
  refetch,
}: ReportRulesTableProps): React.JSX.Element {
  const { formatMessage } = useIntl();
  const isFetching = !!useIsFetching();
  const loadingDots = () => !!isFetching && <LoadingDots data-testId="loading-dots" />;
  const { cluster } = useClusterContext();
  const country = useCountry();
  const toastService = useToast();
  const userScopes = useUserScopes();
  const [isDelete, setIsDelete] = useState(Boolean);
  const hasSetupPermission = hasAdminPermission(userScopes, FEATURE_SETUP_MANAGER);

  /* istanbul ignore next */
  const handleDeleteModal = () => setIsDelete(false);

  const [selectedRule, setSelectedRule] = useState({
    permissionId: '',
    userType: '',
    businessModel: '',
  });

  // eslint-disable-next-line max-params
  const handleDelete = (permissionId: string, userType: string, businessModel: string) => {
    setSelectedRule({ permissionId, userType, businessModel });
    setIsDelete(true);
  };

  useEffect(() => {
    watchCustomEvent(customEventsCommonConsts.REFETCH_GET_RULES, refetch);
  }, [permissionsData?.permissions, refetch]);

  useEffect(() => {
    watchCustomEvent(customEventsCommonConsts.REFETCH_GET_REPORTS, refetch);
  }, [permissionsData?.permissions, refetch]);

  const [toggleStates, setToggleStates] = useState<ToggleStates>({});
  const [updatedData, setUpdatedData] = useState<any>(permissionsData);
  const [isDisabled, setIsDisabled] = React.useState<boolean>(false);

  function toggleCell({ value, row }: ToggleCellProps) {
    setUpdatedData(permissionsData);
    const toggleIdentifier = `${row.original.userType}-${row.original.id}-${row.original.businessModel}`;
    const toggleState = toggleStates[toggleIdentifier] ?? value;

    const onChange = (isChecked: boolean) => {
      setIsDisabled(true);
      setToggleStates((prevState: { [key: string]: boolean }) => ({
        ...prevState,
        [toggleIdentifier]: isChecked,
      }));

      const originalPermission = updatedData?.permissions?.map(
        (permissionOriginal: {
          userType: string;
          vendor: string;
          businessModel: string;
          countries: string[];
          enabled: boolean;
          permissionId: string;
          tier: string | null;
        }) => ({
          userType: permissionOriginal.userType,
          vendors: permissionOriginal.vendor,
          businessModel: permissionOriginal.businessModel,
          countries: permissionOriginal.countries,
          enabled: permissionOriginal.enabled,
          permissionId: permissionOriginal.permissionId,
          tier: null,
        })
      );

      /* istanbul ignore next */
      const permissionsArray = originalPermission
        .filter(
          (filterPermissionClicked: { userType: string; vendors: any; businessModel: string }) => {
            return (
              filterPermissionClicked.userType === row.original.userType.toUpperCase() &&
              filterPermissionClicked.businessModel === row.original.businessModel
            );
          }
        )
        .map(
          (perm: {
            userType: string;
            vendors: any;
            originalVendor?: any;
            businessModel: string;
          }) => {
            const filteredVendor = perm?.vendors
              ?.filter((v: { enabled: boolean }) => v.enabled)
              .map((vendor: { id: string; name: string }) => ({
                id: vendor.id,
                name: vendor.name,
              }));

            if (
              perm.userType === row.original.userType.toUpperCase() &&
              perm.businessModel === row.original.businessModel
            ) {
              const insertVendor = () => {
                return [...filteredVendor, { name: row?.original?.name, id: row?.original?.id }];
              };

              const removeVendor = () => {
                return filteredVendor.filter((v: { id: string }) => v.id !== row?.original?.id);
              };

              return {
                ...perm,
                vendors: isChecked ? insertVendor() : removeVendor(),
                enabled: true,
              };
            }

            return {
              ...perm,
              vendors: filteredVendor,
            };
          }
        );

      const payload = {
        rule: permissionsArray[0],
      };

      setUpdatedData(payload);

      const timeOut = () =>
        setTimeout(() => {
          /* istanbul ignore next */
          setIsDisabled(false);
        }, 1500);

      updateVendorsReportById(payload as any, payload.rule.permissionId, country, cluster)
        .then(() => {
          dispatchCustomEvent(customEventsCommonConsts.REFETCH_GET_REPORTS);

          toastService.notify({
            type: TypeToast.SUCCESS,
            message: formatMessage({ id: 'VENDORS_UPDATE.SUCCESS' }),
          });
          timeOut();
        })
        .catch(() => {
          toastService.notify({
            type: TypeToast.ERROR,
            message: formatMessage({ id: 'VENDORS_UPDATE.FAIL' }),
          });
          timeOut();
        });
    };

    return <Toggle disabled={isDisabled} checked={toggleState} onCheckedChange={onChange} />;
  }

  const vendorMap = useMemo(() => {
    const map = new Map();
    permissionsData?.vendor?.forEach((data) => map.set(data.id, data));

    return map;
  }, [permissionsData]);

  const vendorArray = [...vendorMap.values()];

  const filteredVendors = vendorArray.filter((value) =>
    value.isManufacturer === null
      ? permissionsData?.isManufacturer === false
      : value.isManufacturer === permissionsData?.isManufacturer
  );

  const cellShowManufacture = (cellInfo: any) => {
    const vendor = vendorMap.get(cellInfo.row.original.id);

    /* istanbul ignore next */
    if (!vendor) return null;

    return vendor?.isManufacturer
      ? formatMessage({ id: 'REPORT_ADMIN_PAGE.REPORT_DETAILS.TABLE.MANUFACTURER' })
      : formatMessage({ id: 'REPORT_ADMIN_PAGE.REPORT_DETAILS.TABLE.SELLER' });
  };

  const columnsExpandableContent = [
    {
      Header: 'Vendor',
      accessor: 'name',
      disableSortBy: true,
      style: { width: '30%' },
    },
    {
      Header: 'Vendor Id',
      accessor: 'id',
      disableSortBy: true,
      style: { width: '45%' },
    },
    {
      Header: '',
      accessor: 'isManufacturer',
      disableSortBy: true,
      style: { width: '20%' },
      Cell: cellShowManufacture,
    },
    {
      Header: 'Active',
      accessor: 'enabled',
      disableSortBy: true,
      Cell: toggleCell,
      style: { width: '5%' },
    },
  ];

  function findCommonElements(arr1: VendorProps[], arr2: any[]) {
    return arr1.filter((item1) => arr2.some((item2) => item1.id === item2.id));
  }

  const renderExpandableContent = (vendors: VendorProps[]) => (
    <TableExpandlableStyles data-testid="expandable-table">
      <Table
        columns={columnsExpandableContent}
        data={findCommonElements(vendors, filteredVendors)}
        pagination={{
          pageSize: 10,
        }}
        emptyMessage={
          <div style={{ width: '420px' }}>
            <Error500 headerText={formatMessage({ id: 'REPORT_ADMIN_PAGE.SOMETHING_WRONG' })} />
          </div>
        }
      />
    </TableExpandlableStyles>
  );

  const tableData = permissionsData?.permissions || [];

  const cellTrashIcon = (cellInfo: any) => {
    return (
      hasSetupPermission && (
        <IconButton
          icon={Trash2}
          variant="secondary"
          size="small"
          data-testid="delete-rule"
          onClick={() =>
            handleDelete(
              cellInfo?.row?.original?.permissionId,
              cellInfo?.row?.original?.userType,
              cellInfo?.row?.original?.businessModel
            )
          }
        />
      )
    );
  };

  const columns = [
    {
      Header: 'User Type',
      accessor: 'userType',
      disableSortBy: true,
      style: { width: '40%' },
    },
    {
      Header: 'Business Model',
      accessor: 'businessModel',
      disableSortBy: true,
      style: { width: '55%' },
    },
    {
      Header: '',
      accessor: 'delete',
      disableSortBy: true,
      Cell: cellTrashIcon,
      style: { width: '5%' },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        data={tableData}
        emptyMessage={
          <div style={{ width: '420px' }}>
            <Error500
              headerText={
                !tableData.length
                  ? formatMessage({ id: 'REPORT_ADMIN_PAGE.NO_DATA' })
                  : formatMessage({ id: 'REPORT_ADMIN_PAGE.SOMETHING_WRONG' })
              }
            />
          </div>
        }
        expandable={{
          render: (data) => renderExpandableContent(data?.vendor),
          rowHasExpandFeature: () => true,
        }}
        pagination={false}
        toolbarExtra={loadingDots}
      />
      <DeleteRuleModal
        isOpen={isDelete}
        onClose={handleDeleteModal}
        isLoading={false}
        id={selectedRule.permissionId}
      />
    </>
  );
}
