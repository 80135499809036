import { styled } from '@hexa-ui/theme';

export const SelectUserTypeContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  textAlign: 'left',
});

export const SelectUserTypeDropdown = styled('div', {
  width: '100%',
});
