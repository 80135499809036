import { Paragraph, Toggle } from '@hexa-ui/components';
import CustomInput from 'Components/CustomInput/CustomInput';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormContainerItemNewVendor } from './NewVendorModalForm.style';

export default function NewVendorModalForm(manufacturer: {
  manufacturer: boolean | undefined;
}): React.ReactElement {
  const { formatMessage } = useIntl();
  const { setValue } = useFormContext();
  const { manufacturer: isInitialManufacturerState } = manufacturer;
  const [isManufacturer, setIsManufacturer] = useState(isInitialManufacturerState || false);

  const onChanged = () => {
    setIsManufacturer((isManufacturer) => !isManufacturer);
  };

  useEffect(() => {
    setValue('isManufacturer', isManufacturer);
  }, [isManufacturer]);

  return (
    <FormContainerItemNewVendor>
      <CustomInput
        name="name"
        label={formatMessage({ id: 'VENDOR_REGISTRATION_PAGE.MODAL.NAME_LABEL' })}
        placeholderId={formatMessage({ id: 'VENDOR_REGISTRATION_PAGE.MODAL.NAME_PLACEHOLDER' })}
        errorId={formatMessage({ id: 'VENDOR_REGISTRATION_PAGE.MODAL.ERRORS.REQUIRED' })}
      />
      <CustomInput
        name="vendorId"
        label={formatMessage({ id: 'VENDOR_REGISTRATION_PAGE.MODAL.VENDOR_ID_LABEL' })}
        placeholderId={formatMessage({
          id: 'VENDOR_REGISTRATION_PAGE.MODAL.VENDOR_ID_PLACEHOLDER',
        })}
        errorId={formatMessage({ id: 'VENDOR_REGISTRATION_PAGE.MODAL.ERRORS.REQUIRED' })}
      />
      <div>
        <Paragraph size="small" weight="semibold" style={{ paddingBottom: '2px' }}>
          {formatMessage({ id: 'VENDOR_REGISTRATION_PAGE.MODAL.MANUFACTURER_LABEL' })}
        </Paragraph>
        <Toggle name="isManufacturer" onCheckedChange={onChanged} checked={isManufacturer} />
      </div>
    </FormContainerItemNewVendor>
  );
}
