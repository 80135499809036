import { Tooltip } from '@hexa-ui/components';
import { unionPersonas } from 'Components/ReportGroupCard/ReportGroupCard.types';
import React from 'react';
import { AvatarContainer } from './Avatar.style';

interface IAvatarProps {
  Icon: any;
  type: unionPersonas;
}

export function Avatar({ Icon, type }: IAvatarProps): React.JSX.Element {
  const originalType = type;
  const editedType = originalType?.replace('_', ' ');

  return (
    <Tooltip placement="bottom" text={editedType}>
      <AvatarContainer type={type} data-testid="avatar">
        <Icon />
      </AvatarContainer>
    </Tooltip>
  );
}
