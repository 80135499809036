import APIEndpoint from 'Api/APIEndpoint';
import Api from 'Api/Api';
import getAPIBaseURL from 'Api/getAPIBaseURL';
import AddNewVendor from 'Domain/AddNewVendor';

export const updateVendor = async (
  id: string,
  data: AddNewVendor,
  country: string,
  cluster: string
  // eslint-disable-next-line max-params
): Promise<{ id: string }> => {
  const baseURL = getAPIBaseURL(APIEndpoint.ADD_NEW_VENDOR, country, cluster);

  const response = await Api.put({
    url: `/activation/vendors/${id}`,
    body: data,
    baseURL,
  });

  return response;
};
