import { Paragraph } from '@hexa-ui/components';
import { GRAY_COLOR } from 'Components/WorkspaceCard/WorkspaceCard';
import React from 'react';
import { useIntl } from 'react-intl';
import Styled from './AccessRules.style';
import { AccessRulesProps } from './AccessRules.types';
import Circle from './Components/Circle';

const MAX_CIRCLES = 5;
const OVERLAP_MARGIN = -6;

function AccessRules({ accessRules }: AccessRulesProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const circles = accessRules.slice(0, MAX_CIRCLES).map((item, index) => (
    <Circle
      key={`${item.name}-${item.flag}`}
      flag={item.flag}
      name={item.name}
      style={{
        marginLeft: index === 0 ? 0 : OVERLAP_MARGIN,
        marginRight: OVERLAP_MARGIN,
        zIndex: index + 1,
      }}
    />
  ));

  const additionalCount = accessRules.length > MAX_CIRCLES ? accessRules.length - MAX_CIRCLES : 0;

  return (
    <Styled.Container>
      <Paragraph size="xsmall" css={{ color: GRAY_COLOR }}>
        {formatMessage({ id: 'WORKSPACES.CARD.ACCESS_RULES' })}
      </Paragraph>
      <Styled.CircleContainer>
        {circles}
        {additionalCount > 0 && (
          <Circle
            flag=""
            name=""
            additionalCount={additionalCount}
            style={{
              marginLeft: OVERLAP_MARGIN,
              backgroundColor: 'white',
              color: 'black',
              zIndex: MAX_CIRCLES + 1,
            }}
          />
        )}
      </Styled.CircleContainer>
    </Styled.Container>
  );
}

export default AccessRules;
