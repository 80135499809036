import { Grid } from '@hexa-ui/components';
import React, { ReactNode } from 'react';
import { CSS } from '@stitches/react/types/css-util';
import { gridContainer, gridItem } from 'Components/PageContainer/PageContainer.style';

export default function PageContainer({
  children,
  maxWidth = 'lg',
  sidebar = true,
  css: extraCss,
  dataTestId = 'page-container',
}: {
  children: ReactNode;
  maxWidth?: 'lg' | 'xl';
  sidebar?: boolean;
  css?: CSS;
  dataTestId?: string;
}): React.ReactElement {
  return (
    <Grid.Container
      data-testid={dataTestId}
      sidebar={sidebar}
      className={gridContainer({ sidebar })}
      type="fluid"
    >
      <Grid.Item
        className={gridItem({ maxWidth, sidebar, css: extraCss })}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        {children}
      </Grid.Item>
    </Grid.Container>
  );
}
