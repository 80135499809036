import { styled } from '@hexa-ui/theme';

const Wrapper = styled('div', {
  width: '48px',
  height: '48px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  border: '1px solid rgba(0, 0, 0, 0.05)',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
});

const Styled = {
  Wrapper,
};

export default Styled;
