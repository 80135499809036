import { useQuery, UseQueryResult } from '@tanstack/react-query';
import getReportsById from './getReportsById';

export const useReportsById = (
  reportById: string,
  selectedCountry: string,
  selectedCluster: string,
  isManufacturer: boolean
  // eslint-disable-next-line max-params
): UseQueryResult<any> => {
  const result = useQuery({
    queryKey: ['reports', reportById, selectedCountry],
    queryFn: () => getReportsById(reportById, selectedCountry, selectedCluster, isManufacturer),
    enabled: !!reportById,
  });

  return result;
};
