import Api from 'Api/Api';
import APIEndpoint from 'Api/APIEndpoint';
import getAPIBaseURL from 'Api/getAPIBaseURL';

// eslint-disable-next-line max-params
const getReportsById = async (
  id: string,
  country: string,
  cluster: string,
  isManufacturer: boolean
): Promise<any> => {
  const baseURL = getAPIBaseURL(APIEndpoint.GET_REPORTS_BY_ID, country, cluster);

  const response = await Api.get({
    baseURL,
    url: `/activation/reports/${id}`,
    headers: {
      country,
    },
    params: {
      isManufacturer,
    },
  });

  return response.data;
};

export default getReportsById;
