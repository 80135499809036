import { styled } from '@hexa-ui/theme';

export const TitleContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '80%',
});

export const TextWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '16px',
  textAlign: 'left',
});

export const Info = styled('div', {
  fontFamily: 'Work Sans',
  fontSize: '1rem',
});

export const Prop = styled('div', {
  fontFamily: 'Work Sans',
  fontSize: '1rem',
  fontWeight: 'bold',
});
