import { EnvContext } from 'Components/EnvProvider';
import useUserSupportedCountries from 'Hooks/useUserSupportedCountries/useUserSupportedCountries';
import { useContext } from 'react';

export default function useSupportedCountries(): string[] {
  const userSupportedCountries = useUserSupportedCountries();

  const {
    supportedCountries: envApplicationSupportedCountries,
    supportedCountriesEurope: envSupportedCountriesEurope,
  } = useContext(EnvContext);
  if (envApplicationSupportedCountries == null) {
    return userSupportedCountries ?? [];
  }

  const europeSupportedCountries = envSupportedCountriesEurope
    ? envSupportedCountriesEurope.split(',')
    : '';

  const applicationSupportedCountries = envApplicationSupportedCountries
    .split(',')
    .concat(europeSupportedCountries);

  return (
    userSupportedCountries?.filter((country) => applicationSupportedCountries.includes(country)) ??
    []
  );
}
