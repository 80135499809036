import { Tooltip } from '@hexa-ui/components';
import React from 'react';
import Styled from './CustomTooltip.style';
import { CustomTooltipProps } from './CustomTooltip.types';

function CustomTooltip({
  showTooltip,
  text,
  children,
  placement,
}: CustomTooltipProps): React.ReactElement {
  if (showTooltip) {
    return (
      <Styled.Container>
        <Tooltip placement={placement} text={text} data-testid="tooltip">
          {children}
        </Tooltip>
      </Styled.Container>
    );
  }

  return children;
}

export default CustomTooltip;
